import { IonItem, IonLabel, IonToggle } from "@ionic/react";
import { userService } from "../../lib/userService";

export function ToggleNotificationSetting({
  label,
  settings,
  setSettings,
  settingId,
}: any) {
  return (
    <IonItem>
      <IonLabel>{label}</IonLabel>
      <IonToggle
        slot="end"
        checked={settings[settingId]}
        onClick={() => {
          settings[settingId] = !settings[settingId] ? 1 : 0;

          setSettings({ ...settings });

          userService.setNotificationSetting(settingId, settings[settingId]);
        }}
      />
    </IonItem>
  );
}
