import { systemStore } from "../store/systemStore";
import { makeRequest } from "./api";
import { rerender } from "./rerender";

export const systemService = {
  async getRoles() {
    const result = (await makeRequest("getRoles")).data;

    systemStore.roles = result;

    rerender();
  },

  setReady() {
    systemStore.ready = true;
  },

  setDelayedLoading() {
    systemStore.delayedLoading = true;
    rerender();
  },
};
