import { IonCardSubtitle, IonCardTitle, IonChip } from "@ionic/react";
import { personSharp } from "ionicons/icons";
import { IconText } from "./common/IconText";

export function DisplaySpeakerEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;

  if (prominence === "primary" || prominence === "hero") {
    let churchString = null;
    if (entityData.churchName) {
      churchString = `at ${entityData.churchName}`;
    }

    return (
      <IconText icon={personSharp}>
        <IonCardTitle>{entityData.name}</IonCardTitle>
        <IonCardSubtitle>Speaker {churchString}</IonCardSubtitle>
      </IconText>
    );
  }

  if (prominence === "secondary") {
    return (
      <IonChip
        style={{
          background: "var(--speaker)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {entityData.name}
      </IonChip>
    );
  }

  return null;
}
