import { getYouTubeThumbnailURL } from "../lib/getYouTubeThumbnailURL";

interface Props {
  link: string;
  alt: string;
  style?: any;
}

export function YouTubeImage({ link, alt, style = {} }: Props) {
  const imageUrl = getYouTubeThumbnailURL(link);

  return (
    <div style={style}>
      <div style={{ overflow: "hidden", ...style, position: "relative" }}>
        <div
          style={{
            position: "absolute",
            background: `url(${imageUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",

            // marginTop: "-9.5%",
            // marginBottom: "-11%",
          }}
        />
      </div>
    </div>
  );
}
