import { EditThemeModal } from "../../components/modals/EditThemeModal";
import BrowseEntityType from "../BrowseEntityType";

const Themes: React.FC = () => {
  return (
    <BrowseEntityType
      title="Themes"
      entityType="theme"
      EditModalComponent={EditThemeModal}
    />
  );
};

export default Themes;
