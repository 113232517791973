import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { IssuesReport } from "./IssuesReport";

const Reports: React.FC = () => {
  const [report, setReport] = useState("issues");

  let ReportComponent = null;

  switch (report) {
    case "issues":
      ReportComponent = IssuesReport;
      break;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Reports</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonButton onClick={() => setReport("issues")}>Issues</IonButton>
        {ReportComponent && <ReportComponent />}
      </IonContent>
    </IonPage>
  );
};

export default Reports;
