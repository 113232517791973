import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
} from "@ionic/react";
import { useState } from "react";
import { createEmptyEntityContainer } from "../../lib/createEmptyEntityContainer";
import { entityService } from "../../lib/entityService";
import { getEntities } from "../../lib/getEntities";
import { modalService } from "../../lib/modalService";
import { entities } from "../../store/entities";
import { EditEntitySection } from "../admin/EditEntitySection";
import { AdminQuestionEntity } from "../admin/AdminQuestionEntity";
import { AdminThemeEntity } from "../admin/AdminThemeEntity";
import { EditEntityModal } from "./EditEntityModal";
import { EditQuestionModal } from "./EditQuestionModal";
import { EditThemeModal } from "./EditThemeModal";
import { BaseEditModalProps } from "./util";
import { AdminSpeakerEntity } from "../admin/AdminSpeakerEntity";
import { EditSpeakerModal } from "./EditSpeakerModal";

type Props = {} & BaseEditModalProps;

const entityType = "sermon";

export function EditSermonModal(props: Props) {
  const [entityContainer, setEntityContainer] = useState<any>(
    entities.get(props.entityId) || createEmptyEntityContainer(entityType)
  );

  const speakers = getEntities(entityContainer.associatedEntities, "speaker");
  const themes = getEntities(entityContainer.associatedEntities, "theme");
  const questions = getEntities(entityContainer.associatedEntities, "question");

  return (
    <EditEntityModal
      {...props}
      entityType={"Sermon"}
      targetEntityContainer={entityContainer}
      onSelect={(entityContainer: any) => {
        props.onSelect(entityContainer);
        modalService.closeModal(props.id);
      }}
      onSearch={(searchString: string) => {
        return entityService.findEntitiesOfType(searchString, entityType);
      }}
      isValid={() => {
        return !!entityContainer.entity.entityData.name;
      }}
      onStartNew={(initialValue: string) => {
        entityContainer.entity.entityName = initialValue;
        entityContainer.entity.entityData.name = initialValue;
      }}
      setEntityContainer={setEntityContainer}
    >
      {({ setChanged }: any) => {
        return (
          <>
            <IonLabel>Sermon Name</IonLabel>
            <IonInput
              value={entityContainer.entity.entityData.name}
              onIonChange={(event) => {
                const newContainer = JSON.parse(
                  JSON.stringify(entityContainer)
                );

                newContainer.entity.entityName = event.target.value;
                newContainer.entity.entityData.name = event.target.value;

                setEntityContainer(newContainer);
                setChanged(true);
              }}
            />

            <EditEntitySection
              id="speakers"
              title="Speakers"
              entityIds={speakers}
              EntityComponent={AdminSpeakerEntity}
              EditModalComponent={EditSpeakerModal}
              onChange={setChanged}
              parent={entityContainer}
            />

            <IonCard>
              <IonCardContent>
                <IonLabel>Link</IonLabel>
                <IonInput
                  value={entityContainer.entity.entityData.link}
                  onIonChange={(event) => {
                    const newContainer = JSON.parse(
                      JSON.stringify(entityContainer)
                    );

                    newContainer.entity.entityData.link = event.target.value;

                    setEntityContainer(newContainer);
                    setChanged(true);
                  }}
                />
              </IonCardContent>
            </IonCard>

            <IonCard>
              <IonCardContent>
                <IonLabel>Description</IonLabel>
                <IonTextarea
                  value={entityContainer.entity.entityData.description}
                  onIonChange={(event) => {
                    const newContainer = JSON.parse(
                      JSON.stringify(entityContainer)
                    );

                    newContainer.entity.entityData.description =
                      event.target.value;

                    setEntityContainer(newContainer);
                    setChanged(true);
                  }}
                />
              </IonCardContent>
            </IonCard>

            <EditEntitySection
              id="themes"
              title="Themes"
              entityIds={themes}
              EntityComponent={AdminThemeEntity}
              EditModalComponent={EditThemeModal}
              onChange={setChanged}
              parent={entityContainer}
            />

            <EditEntitySection
              id="questions"
              title="Questions"
              entityIds={questions}
              EntityComponent={AdminQuestionEntity}
              EditModalComponent={EditQuestionModal}
              onChange={setChanged}
              parent={entityContainer}
            />

            <IonCard>
              <IonCardContent>
                <IonItem style={{ height: "2.5em" }}>
                  <IonCheckbox
                    slot="start"
                    checked={entityContainer.entity.entityData.newRelease}
                    onIonChange={() => {
                      entityContainer.entity.entityData.newRelease =
                        !entityContainer.entity.entityData.newRelease;
                      setEntityContainer({ ...entityContainer });
                    }}
                  />
                  <IonLabel>New Release</IonLabel>
                </IonItem>
                This only applies to the notification that is sent from this
                song being added. Once the song has been newly added, this
                toggle has no effect.
              </IonCardContent>
            </IonCard>
          </>
        );
      }}
    </EditEntityModal>
  );
}
