import { entityService } from "../lib/entityService";
import { rerender } from "../lib/rerender";

export const searchStore: {
  entityIds: string[];
  searching: boolean;
  loading: boolean;
  searchString: string;
  page: number;
  infiniteDisabled: boolean;
} = {
  entityIds: [],
  searching: false,
  loading: false,
  searchString: "",
  page: 0,
  infiniteDisabled: false,
};

export function startSearching() {
  searchStore.searching = true;
  rerender();
}

export function endSearching() {
  searchStore.searching = false;
  rerender();
}

let searchTimeout: any = null;

/**
 * Start a search with a debounce
 *
 * @param searchString
 * @param newPage
 * @param event
 */
export function setSearchString(
  searchString: string,
  newPage: number,
  event?: any
) {
  searchStore.searchString = searchString;

  let preSearchEntityIds = searchStore.entityIds;

  if (newPage === 0) {
    preSearchEntityIds = [];
    searchStore.entityIds = [];
    rerender();
  }

  // Always clear the timeout before starting a new one
  clearTimeout(searchTimeout);

  searchTimeout = setTimeout(async () => {
    searchStore.page = newPage;

    const newEntityIds = await entityService.search(
      searchStore.searchString,
      searchStore.page
    );

    if (newEntityIds.lengh < 10) {
      searchStore.infiniteDisabled = true;
    }

    searchStore.entityIds = [...preSearchEntityIds, ...newEntityIds];

    if (newPage === 0) {
      searchStore.loading = false;
    }

    if (event) {
      event.target.complete();
    }

    rerender();
  }, 400);
}

(window as any).searchStore = searchStore;
