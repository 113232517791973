let rerenderFunction: null | ((value: any) => void) = null;

export function setRenderFunction(fn: any) {
  rerenderFunction = fn;
}

export function rerender() {
  if (rerenderFunction) {
    rerenderFunction({});
    rerenderFunction = null;
  }
}
