import { IonIcon } from "@ionic/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { entityTypeConfigs } from "../lib/entityTypeConfigs";
import { entities } from "../store/entities";
import { EntityWrapper } from "./entities/display/EntityWrapper";

// TODO: break into it's own component
export default function useOnScreen(
  ref: any,
  callback: () => boolean,
  identifier: string
) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [done, setDone] = useState(false);

  // Is this a memory leak?
  const observer = useMemo(() => {
    return new IntersectionObserver(async ([entry]) => {
      if (done) {
        return;
      }

      if (entry.isIntersecting) {
        const more = await callback();

        if (!more) {
          // console.log("End of infinite horizontal scroll");
          observer.disconnect();
          setDone(true);
        }
      }

      setIntersecting(entry.isIntersecting);
    });
  }, [callback, done]);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);

      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, observer]);

  return isIntersecting;
}

export function EntityGrouping({ title, entityIds, onInfiniteScroll }: any) {
  const ref = useRef<any>();
  const callback = useCallback(onInfiniteScroll, [onInfiniteScroll]);
  useOnScreen(ref, callback, title);

  if (entityIds.length === 0) {
    return null;
  }

  let leftBorderColor = "";
  let icon = null;
  let direction = "column";
  let paddingBottom = "0em";

  switch (title) {
    case "Artists":
      icon = entityTypeConfigs.artist.iconFilled;
      leftBorderColor = "var(--artists)";
      break;
    case "Genres":
      icon = entityTypeConfigs.genre.iconFilled;
      leftBorderColor = "var(--genres)";
      break;
    case "Questions":
      icon = entityTypeConfigs.question.iconFilled;
      leftBorderColor = "var(--questions)";
      break;
    case "Songs":
      icon = entityTypeConfigs.song.iconFilled;
      leftBorderColor = "var(--songs)";
      paddingBottom = "1em";
      direction = "row";
      break;
    case "Sermons":
      icon = entityTypeConfigs.sermon.iconFilled;
      leftBorderColor = "var(--sermons)";
      paddingBottom = "1em";
      direction = "row";
      break;
    case "Speakers":
      icon = entityTypeConfigs.speaker.iconFilled;
      leftBorderColor = "var(--artists)";
      break;
    case "Themes":
      icon = entityTypeConfigs.theme.iconFilled;
      leftBorderColor = "var(--themes)";
      break;
    case "Verses":
      icon = entityTypeConfigs.verse.iconFilled;
      leftBorderColor = "var(--verses)";
      direction = "row";
      break;
  }

  return (
    <div
      style={{
        borderLeft: `0.25em solid ${leftBorderColor}`,
        paddingLeft: "1em",
        marginBottom: "2em",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
          fontWeight: "bold",
          marginBottom: "1em",
        }}
      >
        <IonIcon icon={icon} />
        {title}
      </div>

      {direction === "row" && (
        <div style={{ overflowX: "auto" }}>
          <div style={{ display: "flex", paddingBottom }}>
            {entityIds.map((otherEntityId: string) => {
              const entityContainer = entities.get(otherEntityId);

              return (
                <EntityWrapper
                  key={entityContainer.entity.entityId}
                  entityContainer={entityContainer}
                  prominence={"secondary"}
                />
              );
            })}

            {!!onInfiniteScroll && <div ref={ref}></div>}
          </div>
        </div>
      )}

      {direction !== "row" &&
        entityIds.map((otherEntityId: string) => {
          const entityContainer = entities.get(otherEntityId);

          return (
            <EntityWrapper
              key={entityContainer.entity.entityId}
              entityContainer={entityContainer}
              prominence={"secondary"}
            />
          );
        })}
    </div>
  );
}
