import { IonCardTitle, IonChip } from "@ionic/react";
import { entityTypeConfigs } from "../../../lib/entityTypeConfigs";
import { IconText } from "./common/IconText";

export function DisplayGenreEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;

  if (prominence === "primary" || prominence === "hero") {
    return (
      <IconText icon={entityTypeConfigs.genre.icon}>
        <IonCardTitle>{entityData.name}</IonCardTitle>
      </IconText>
    );
  }

  if (prominence === "secondary") {
    return (
      <IonChip
        style={{
          background: "var(--genre)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {entityData.name}
      </IonChip>
    );
  }

  return null;
}
