import { EditSermonModal } from "../../components/modals/EditSermonModal";
import BrowseEntityType from "../BrowseEntityType";

const Sermons: React.FC = () => {
  return (
    <BrowseEntityType
      title="Sermons"
      entityType="sermon"
      EditModalComponent={EditSermonModal}
    />
  );
};

export default Sermons;
