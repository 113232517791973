type User = {
  loggedIn: boolean;
  userId: string;
  email: string;
  userData: {
    roles: string[];
    settings: any;
    emailVerified: boolean;
    publicData: {
      name: string;
    };
  };
};

export const userStore: {
  user: User;
} = {
  user: {
    loggedIn: false,
    userId: "",
    email: "",
    userData: {
      roles: [],
      settings: {},
      emailVerified: false,
      publicData: { name: "" },
    },
  },
};

(window as any).userStore = userStore;
