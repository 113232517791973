import {
  IonAlert,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import { useState } from "react";
import { useParams } from "react-router";
import { subscribableEntityTypes } from "shared/lib/Entity";
import { Content } from "../components/Content";
import { EntityWrapper } from "../components/entities/display/EntityWrapper";
import { EntityGrouping } from "../components/EntityGrouping";
import { Loading } from "../components/Loading";
import { EditArtistModal } from "../components/modals/EditArtistModal";
import { EditGenreModal } from "../components/modals/EditGenreModal";
import { EditQuestionModal } from "../components/modals/EditQuestionModal";
import { EditSermonModal } from "../components/modals/EditSermonModal";
import { EditSongModal } from "../components/modals/EditSongModal";
import { EditSpeakerModal } from "../components/modals/EditSpeakerModal";
import { EditThemeModal } from "../components/modals/EditThemeModal";
import { SubscribeButton } from "../components/SubscribeButton";
import { adminService } from "../lib/adminService";
import { entityService } from "../lib/entityService";
import { getEntities } from "../lib/getEntities";
import { modalService } from "../lib/modalService";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";

const Entity: React.FC = () => {
  const router = useIonRouter();
  const { entityId } = useParams<{ entityId: string }>();
  const [entityContainer, setEntityContainer] = useState<any>(null);
  const [present] = useIonActionSheet();
  const [deleting, setDeleting] = useState(false);

  async function getEntity() {
    const { container } = await entityService.getEntity(entityId, {
      useCache: true,
    });

    setEntityContainer(container);
  }

  // This ensures that the next time this view is visited there
  // will not be an entityContainer already populated that does
  // not match the one for the view
  useIonViewWillLeave(() => {
    setEntityContainer(null);
  });

  const [loading] = useWillEnterWithLoading(
    () => {
      getEntity();
    },
    400,
    [entityId]
  );

  let EditModalComponent: any = null;
  let canSubscribe = false;
  let headerBackground = "var(--light)";
  let headerColor = "";
  const entityGroupContainers: any[] = [];

  if (entityContainer) {
    const verses = getEntities(entityContainer.associatedEntities, "verse");
    const themes = getEntities(entityContainer.associatedEntities, "theme");
    const questions = getEntities(
      entityContainer.associatedEntities,
      "question"
    );
    const songs = getEntities(entityContainer.associatedEntities, "song");
    const sermons = getEntities(entityContainer.associatedEntities, "sermon");
    const genres = getEntities(entityContainer.associatedEntities, "genre");
    const artists = getEntities(entityContainer.associatedEntities, "artist");
    const speakers = getEntities(entityContainer.associatedEntities, "speaker");

    canSubscribe = subscribableEntityTypes.includes(
      entityContainer.entity.entityType
    );

    const groupings = {
      genres: <EntityGrouping key="Genres" title="Genres" entityIds={genres} />,
      verses: <EntityGrouping key="Verses" title="Verses" entityIds={verses} />,
      themes: <EntityGrouping key="Themes" title="Themes" entityIds={themes} />,
      artists: (
        <EntityGrouping key="Artists" title="Artists" entityIds={artists} />
      ),
      speakers: (
        <EntityGrouping key="Speakers" title="Speakers" entityIds={speakers} />
      ),
      questions: (
        <EntityGrouping
          key="Questions"
          title="Questions"
          entityIds={questions}
        />
      ),
      sermons: (
        <EntityGrouping key="Sermons" title="Sermons" entityIds={sermons} />
      ),
      songs: <EntityGrouping key="Songs" title="Songs" entityIds={songs} />,
    };

    let groupingOrder: (keyof typeof groupings)[] = [];

    switch (entityContainer.entity.entityType) {
      case "theme":
        EditModalComponent = EditThemeModal;
        break;
      case "question":
        EditModalComponent = EditQuestionModal;
        groupingOrder = ["themes"];
        break;
      case "song":
        EditModalComponent = EditSongModal;
        groupingOrder = ["verses", "artists", "genres"];
        headerBackground = "var(--darkest)";
        headerColor = "white";
        break;
      case "sermon":
        EditModalComponent = EditSermonModal;
        groupingOrder = ["speakers", "themes", "verses"];
        headerBackground = "var(--darkest)";
        headerColor = "white";
        break;
      case "genre":
        EditModalComponent = EditGenreModal;
        groupingOrder = ["songs"];
        break;
      case "artist":
        EditModalComponent = EditArtistModal;
        groupingOrder = ["songs"];
        break;
      case "speaker":
        EditModalComponent = EditSpeakerModal;
        groupingOrder = ["sermons"];
        break;
    }

    groupingOrder.forEach((group) => {
      entityGroupContainers.push(groupings[group]);
      delete groupings[group];
    });

    Object.values(groupings).forEach((element) => {
      entityGroupContainers.push(element);
    });
  }

  // For some reason if there isn't an element there, then
  // some extra space is rendered (can replace the div with a null to see)
  let entityMenu = null;
  if (
    entityContainer &&
    (canSubscribe || userService.hasRoles(["delete-entities"]))
  ) {
    entityMenu = (
      <IonButtons slot="end">
        <IonButton id={`menu-button-${entityContainer.entity.entityId}`}>
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
        <IonPopover
          trigger={`menu-button-${entityContainer.entity.entityId}`}
          dismissOnSelect={true}
        >
          {userService.hasRoles(["delete-entities"]) && (
            <>
              <IonButton
                color="danger"
                onClick={() => {
                  present({
                    header: "Delete Entity",
                    subHeader: entityContainer.entity.entityName,
                    buttons: [
                      {
                        text: "Delete",
                        role: "destructive",
                        data: {
                          action: "delete",
                        },
                      },
                      {
                        text: "Cancel",
                        role: "cancel",
                        data: {
                          action: "cancel",
                        },
                      },
                    ],
                    onWillDismiss: async ({ detail }) => {
                      if (detail.data.action === "delete") {
                        setDeleting(true);
                        await adminService.deleteEntity(entityId);
                        setDeleting(false);

                        if (router.canGoBack()) {
                          router.goBack();
                        } else {
                          router.push("/");
                        }
                      }
                    },
                  });
                }}
              >
                Delete
              </IonButton>
              <IonAlert
                isOpen={deleting}
                // onDidDismiss={() => {}}
                header="Deleting"
                message={`Deleting "${entityContainer.entity.entityName}"...`}
                // buttons={["OK"]}
              ></IonAlert>
            </>
          )}
          {userService.hasRoles(["contributor"]) && EditModalComponent && (
            <IonButton
              color={"primary"}
              onClick={async () => {
                await entityService.getEntity(entityId, {
                  includePending: true,
                });

                if (EditModalComponent) {
                  modalService.openModalByComponent(EditModalComponent, {
                    entityId,
                    options: { edit: true, select: false },
                    externalOnClose: async () => {
                      getEntity();
                    },
                  });
                }
              }}
            >
              Edit
            </IonButton>
          )}
        </IonPopover>
      </IonButtons>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          className="toolbar-shade"
          style={{ "--background": headerBackground, "--color": headerColor }}
        >
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {entityContainer && (
            <IonTitle className="toolbar-center">
              {entityContainer.entity.entityName}
            </IonTitle>
          )}
          {entityMenu}
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading} ContentComponent={Content}>
        <Content>
          {entityContainer && (
            <div style={{ marginBottom: "2em" }}>
              <EntityWrapper
                entityContainer={entityContainer}
                prominence={"hero"}
              />
              <div style={{ textAlign: "right", paddingRight: "0.5em" }}>
                {entityContainer && canSubscribe && (
                  <SubscribeButton entityId={entityId} />
                )}
              </div>
            </div>
          )}

          {entityGroupContainers}
        </Content>
      </Loading>
    </IonPage>
  );
};

export default Entity;
