import {
  book,
  bookOutline,
  headset,
  headsetOutline,
  helpCircle,
  helpCircleOutline,
  mic,
  micOutline,
  musicalNotes,
  musicalNotesOutline,
  person,
  pricetag,
  pricetagOutline,
} from "ionicons/icons";
import { EntityType } from "shared/lib/Entity";

type EntityTypeConfig = {
  name: string;
  pluralName: string;
  icon: any;
  iconFilled: any;
};

export const entityTypeConfigs: {
  [key in EntityType]: EntityTypeConfig;
} = {
  artist: {
    name: "Artist",
    pluralName: "Artists",
    icon: person,
    iconFilled: person,
  },
  genre: {
    name: "Genre",
    pluralName: "Genres",
    icon: headsetOutline,
    iconFilled: headset,
  },
  theme: {
    name: "Theme",
    pluralName: "Themes",
    icon: pricetagOutline,
    iconFilled: pricetag,
  },
  question: {
    name: "Question",
    pluralName: "Questions",
    icon: helpCircleOutline,
    iconFilled: helpCircle,
  },
  song: {
    name: "Song",
    pluralName: "Songs",
    icon: musicalNotesOutline,
    iconFilled: musicalNotes,
  },
  sermon: {
    name: "Sermon",
    pluralName: "Sermons",
    icon: micOutline,
    iconFilled: mic,
  },
  speaker: {
    name: "Speaker",
    pluralName: "Speakers",
    icon: person,
    iconFilled: person,
  },
  verse: {
    name: "Verse",
    pluralName: "verses",
    icon: bookOutline,
    iconFilled: book,
  },
};
