import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import {
  bookmark,
  bookmarkOutline,
  personCircle,
  shareOutline,
} from "ionicons/icons";
import { useState } from "react";
import { getEntities } from "../../../../lib/getEntities";
import { shareService } from "../../../../lib/shareService";
import { userService } from "../../../../lib/userService";
import { entities } from "../../../../store/entities";
import { systemStore } from "../../../../store/systemStore";
import { SubscribeButton } from "../../../SubscribeButton";
import { YouTubeImage } from "../../../YoutubeImage";
import { YouTubeEmbed } from "./YouTubeEmbed";

export function VideoMediaHero({ entityContainer, featuredEntityType }: any) {
  const [loadedEmbed, setLoadedEmbed] = useState(false);
  const router = useIonRouter();

  const { entity } = entityContainer;
  const { entityData } = entity;

  const featuredEntities = getEntities(
    entityContainer.associatedEntities,
    featuredEntityType
  );

  return (
    <div style={{ background: "var(--darkest)", color: "white" }}>
      <div
        onClick={(event) => {
          event.stopPropagation();
          setLoadedEmbed(true);
        }}
      >
        {!loadedEmbed && (
          <div style={{ textAlign: "center" }}>
            <YouTubeImage
              link={entityData.link}
              alt={entity.entityName}
              style={{ height: `${window.innerHeight / 2}px` }}
            />
          </div>
        )}
        {loadedEmbed && (
          <YouTubeEmbed
            link={entityData.link}
            height={window.innerHeight / 2}
          />
        )}
      </div>
      <div
        style={{ textAlign: "right", marginBottom: "1em", padding: "0em 2em" }}
      >
        {systemStore.canShare && (
          <IonButton
            onClick={async () => {
              shareService.shareEntity(entityContainer);
            }}
          >
            <IonIcon icon={shareOutline} slot="start" /> Share
          </IonButton>
        )}
        <IonButton
          fill={entityContainer.bookmarked ? "clear" : "solid"}
          onClick={(event) => {
            event.stopPropagation();

            if (!userService.isLoggedIn()) {
              userService.promptRegister(router);
              return;
            }

            if (entityContainer.bookmarked) {
              userService.unbookmarkEntity(entityContainer.entity.entityId);
            } else {
              userService.bookmarkEntity(entityContainer.entity.entityId);
            }
          }}
        >
          {entityContainer.bookmarked ? (
            <>
              Saved <IonIcon icon={bookmark} style={{ marginLeft: "0.5em" }} />
            </>
          ) : (
            <>
              Save{" "}
              <IonIcon icon={bookmarkOutline} style={{ marginLeft: "0.5em" }} />
            </>
          )}
        </IonButton>
      </div>
      <div style={{ padding: "2em", paddingTop: "0em" }}>
        <div
          style={{ fontSize: "1.5em", fontWeight: 500, marginBottom: "1em" }}
        >
          {entity.entityName}
        </div>
        {featuredEntities.map((otherEntityId) => {
          const otherContainer = entities.get(otherEntityId);

          return (
            <div
              key={otherEntityId}
              style={{
                display: "flex",
                gap: "1em",
                alignItems: "center",
                marginBottom: "1em",
              }}
              onClick={(event) => {
                event.stopPropagation();
                router.push(`/entity/${otherContainer.entity.entityId}`);
              }}
            >
              <div>
                <IonIcon icon={personCircle} style={{ fontSize: "2em" }} />
              </div>
              <div style={{ flexGrow: 1 }}>
                {otherContainer.entity.entityName}
              </div>
              <SubscribeButton entityId={otherContainer.entity.entityId} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
