import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import React, { useState } from "react";
import { TextFieldTypes } from "@ionic/core/dist/types/interface";

type ValidValue = string;

interface Props {
  type?: TextFieldTypes;
  label: string;
  value: ValidValue;
  min?: number;
  max?: number;
  helperText?: string;
  asItem?: boolean;
  setValue?: (value: ValidValue) => void;
  onIonChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
}

export const LabeledInput = React.forwardRef(
  (
    {
      type,
      label,
      value,
      min = 0,
      max,
      helperText,
      asItem = true,
      setValue,
      onIonChange,
      onKeyDown,
    }: Props,
    ref: any
  ) => {
    const [isTouched, setIsTouched] = useState(false);

    const content = (
      <>
        <IonLabel position="floating">{label}</IonLabel>
        <IonInput
          ref={ref}
          type={type}
          value={value}
          min={min}
          max={max}
          onIonChange={(event) => {
            if (onIonChange) {
              onIonChange(event);
            }

            if (setValue) {
              setValue(event.target.value as string);
            }
          }}
          onIonBlur={() => {
            setIsTouched(true);
          }}
          onKeyDown={onKeyDown}
        />
        <IonNote slot="helper">{helperText}</IonNote>
      </>
    );

    if (!asItem) {
      return content;
    }

    return (
      <IonItem className={`${isTouched ? "ion-touched" : ""}`}>
        {content}
      </IonItem>
    );
  }
);
