import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { RegisterForm } from "./RegisterForm";

const Register: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Register</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <RegisterForm
          onSuccess={() => {
            // OLD: Routing will happen after successful email validation
            // NEW: route to home
            window.location.href = "/";
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default Register;
