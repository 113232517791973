import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { Loading } from "../components/Loading";
// import { NotificationDevicesModal } from "../components/modals/NotificationDevicesModal";
import { SubscribeButton } from "../components/SubscribeButton";
// import { modalService } from "../lib/modalService";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";

const tabs = ["Artists", "Speakers", "Verses"];

export const Subscriptions: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<any>([]);
  // const [verseBookmarks, setVerseBookmarks] = useState<any>([]);
  const [tab, setTab] = useState("Artists");
  const router = useIonRouter();

  async function getSubscriptions() {
    const subscriptions = await userService.getSubscriptions();
    // const bookmarks = await userService.getBookmarks();

    setSubscriptions(subscriptions);
    // setVerseBookmarks(bookmarks.verseBookmarks);
  }

  const [loading] = useWillEnterWithLoading(async () => {
    getSubscriptions();
  }, 400);

  const filteredSubscriptions = subscriptions.filter((subscription: any) => {
    const { entity } = subscription.container;

    if (entity.entityType === "artist" && tab === "Artists") {
      return true;
    }

    if (entity.entityType === "speaker" && tab === "Speakers") {
      return true;
    }

    if (entity.entityType === "verse" && tab === "Verses") {
      return true;
    }

    return false;
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Subscriptions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading}>
        <IonContent fullscreen>
          <IonSegment value={tab}>
            {tabs.map((tab) => {
              return (
                <IonSegmentButton
                  key={tab}
                  value={tab}
                  onClick={() => setTab(tab)}
                >
                  <IonLabel>{tab}</IonLabel>
                </IonSegmentButton>
              );
            })}
          </IonSegment>
          {/* <div style={{ textAlign: "right" }}>
            <IonButton
              fill="clear"
              onClick={() => {
                modalService.openModalByComponent(NotificationDevicesModal);
              }}
            >
              Devices
            </IonButton>
          </div> */}
          <IonList style={{ padding: "1em" }}>
            {filteredSubscriptions.map((subscription: any) => {
              const { container } = subscription;
              const { entity } = container;

              return (
                <IonItem
                  button
                  key={subscription.container.entity.entityId}
                  type="button"
                  onClick={() => {
                    router.push(`/entity/${entity.entityId}`);
                  }}
                >
                  {entity.entityName}

                  <SubscribeButton
                    slot="end"
                    container={container}
                    iconOnly={true}
                    onChange={(value: boolean) => {
                      container.subscribed = value;
                      setSubscriptions([...subscriptions]);
                    }}
                  />
                </IonItem>
              );
            })}

            {/* {tab === "Verses" && (
              <VerseBookmarks
                verseBookmarks={verseBookmarks}
                setVerseBookmarks={setVerseBookmarks}
                router={router}
              />
            )} */}
          </IonList>
        </IonContent>
      </Loading>
    </IonPage>
  );
};

// function VerseBookmarks({ verseBookmarks, setVerseBookmarks, router }: any) {
//   return verseBookmarks.map((bookmark: any) => {
//     return (
//       <IonItem
//         button
//         key={bookmark.specialId}
//         type="button"
//         onClick={() => {
//           router.push(`/verse/${bookmark.specialId}`);
//         }}
//       >
//         {bookmark.name}
//         <IonButton
//           slot="end"
//           fill="clear"
//           onClick={async (event) => {
//             event.stopPropagation();

//             if (bookmark.bookmarked) {
//               await userService.unbookmarkVerse(bookmark.specialId);
//               bookmark.bookmarked = false;
//               setVerseBookmarks([...verseBookmarks]);
//             } else {
//               await userService.bookmarkVerse(bookmark.specialId);
//               // Don't refetch subscriptions
//               // Update the state of the subscription to allow resubscribing, in case the unsubscription was an accident
//               bookmark.bookmarked = true;
//               setVerseBookmarks([...verseBookmarks]);
//             }
//           }}
//         >
//           <IonIcon
//             icon={bookmark.bookmarked ? bookmarkFilled : bookmarkOutline}
//           />
//         </IonButton>
//       </IonItem>
//     );
//   });
// }
