import { IonButton, IonIcon } from "@ionic/react";
import "./calendar.css";
import { chevronBack, chevronForward } from "ionicons/icons";
import { useEffect, useState } from "react";

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface Props {
  renderCell: (context: {
    month: number;
    year: number;
    monthDate: number;
  }) => any;
  onMonthChange: (context: { month: number; year: number }) => void;
}

export function Calendar({ renderCell, onMonthChange }: Props) {
  const [date] = useState(new Date());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());

  useEffect(() => {
    onMonthChange({ month, year });
  }, [month, year, onMonthChange]);

  function changeMonth(dir: number) {
    let newMonth = month + dir;
    let newYear = year;

    if (newMonth === 12) {
      newMonth = 0;
      newYear += 1;
    }

    if (newMonth === -1) {
      newMonth = 11;
      newYear -= 1;
    }

    setMonth(newMonth);
    setYear(newYear);
    onMonthChange({ month: newMonth, year: newYear });
  }

  const today = new Date();
  const monthDate = new Date(year, month, 1);

  const currentMonth = monthDate.getMonth();
  const cells: any[] = [];

  monthDate.setDate(1);

  const firstDayOfWeek = monthDate.getDay();

  monthDate.setDate(-(firstDayOfWeek - 1));

  if (currentMonth === 0) {
    while (monthDate.getMonth() === 11) {
      cells.push([false, new Date(monthDate)]);
      monthDate.setDate(monthDate.getDate() + 1);
    }
  } else {
    while (monthDate.getMonth() === currentMonth - 1) {
      cells.push([false, new Date(monthDate)]);
      monthDate.setDate(monthDate.getDate() + 1);
    }
  }

  while (monthDate.getMonth() === currentMonth) {
    cells.push([true, new Date(monthDate)]);
    monthDate.setDate(monthDate.getDate() + 1);
  }

  while (
    monthDate.getMonth() === currentMonth + 1 &&
    monthDate.getDay() !== 0
  ) {
    cells.push([false, new Date(monthDate)]);
    monthDate.setDate(monthDate.getDate() + 1);
  }

  return (
    <div className="calendar">
      <div className="month-year">
        <IonButton
          className="previous"
          onClick={() => changeMonth(-1)}
          fill="clear"
        >
          <IonIcon slot="icon-only" icon={chevronBack} />
        </IonButton>
        <div style={{ flexGrow: 1 }}>
          {monthNames[month]} / {year}
        </div>
        <IonButton
          className="previous"
          onClick={() => changeMonth(+1)}
          fill="clear"
        >
          <IonIcon slot="icon-only" icon={chevronForward} />
        </IonButton>
      </div>
      <div className="calendar-body">
        {dayNames.map((dayName) => {
          return (
            <div key={dayName} className="day-name">
              {dayName}
            </div>
          );
        })}
        {cells.map((date) => {
          const [isInMonth, monthDate] = date;
          const isCurrentDate =
            month === today.getMonth() &&
            year === today.getFullYear() &&
            monthDate === today.getDate();

          const cellMonth = monthDate.getMonth();
          const cellYear = monthDate.getFullYear();
          const cellMonthDate = monthDate.getDate();

          return (
            <div
              key={`${isInMonth}${monthDate}`}
              className={`calendar-cell ${
                isInMonth ? "in-month" : "out-month"
              } ${isCurrentDate && "current"}`}
            >
              <div className="number">{monthDate.getDate()}</div>
              {renderCell({
                month: cellMonth,
                year: cellYear,
                monthDate: cellMonthDate,
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
