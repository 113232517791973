import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

export const TermsOfService: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Terms Of Service</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="terms-of-service" style={{ padding: "2em" }}>
          <p className="c8">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Effective Date</span>
            <span className="c0">: December 21 2022 </span>
          </p>
          <p className="c2">
            <span className="c3">Last Updated</span>
            <span className="c0">: December 21 2022 &nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">AGREEMENT TO TERMS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">
              The Terms of Use Agreement (&ldquo;Agreement&rdquo;), created on
              the effective date and last amended on date above, is made between
              you (&ldquo;user,&rdquo; &ldquo;you&rdquo; or &ldquo;your&rdquo;),
              and:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">WEBSITE OWNER</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Website URL</span>
            <span className="c0">: www.biblemedia.app </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Company Name</span>
            <span className="c0">: Bible Media LLC &nbsp;</span>
          </p>
          <p className="c2">
            <span className="c7 c0">E-Mail</span>
            <span className="c0">: support@biblemedia.app </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Phone</span>
            <span className="c0">: &nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              The owner mentioned above includes its affiliated companies,
              websites, applications, software, and tools (the
              &ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or
              &ldquo;our&rdquo;), and, with this Agreement, outlines the rights
              and obligations that you and all users share, as well as any other
              media form, media channel, mobile website or mobile application
              related or connected thereto (collectively, the
              &ldquo;website&rdquo;), for the purpose of making use of any of
              the content, software, or other tools on the website
              (&ldquo;services&rdquo;).
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">PAYMENTS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              All or a portion of the services offered by the Company on the
              website are paid in accordance with the terms below:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Forms of Payment</span>
            <span className="c0">
              . We accept payment through the methods offered at the time of
              purchase or when a balance is due. The provider and method of
              payment are determined by your location, device, and purchased
              item. We reserve the right, at any time, to reject payment for any
              reason.
            </span>
          </p>
          <p className="c2">
            <span className="c3">b.) Currency</span>
            <span className="c0">
              . Payments will be accepted on the website in the currency based
              on your location and in accordance with local laws.
            </span>
          </p>
          <p className="c2">
            <span className="c3">c.) Refund Policy</span>
            <span className="c0">
              . Except when required by law, payments made by a user are not
              refundable by the company. Refund requests are administered on a
              case-by-case basis and, if granted, do so at the sole discretion
              of the company.
            </span>
          </p>
          <p className="c2">
            <span className="c3">d.) In-App Purchases</span>
            <span className="c0">
              . If the website&rsquo;s services are offered on an Android, iOS,
              or other mobile application (&ldquo;mobile app&rdquo;), this
              Agreement also applies to payments made on the mobile app. In
              addition, payments made on the mobile app must also be in
              accordance with the terms of the mobile app platform or
              &ldquo;store.&quot;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">SUBSCRIPTIONS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any of the services offered by us are only available with a
              paid subscription, you will be billed in advance and on a
              recurring and periodic basis (such as daily, weekly, monthly,
              annually, or other agreed upon timeframes) at the end of each
              period. With each successful payment, the subscription will
              automatically renew under the same conditions unless canceled.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Cancellation</span>
            <span className="c0">
              . You are entitled to cancel a subscription at any time and in
              accordance with the terms of this Agreement. Cancellation does not
              entitle you to a refund for any prior payments made.
            </span>
          </p>
          <p className="c2 c11">
            <span className="c1 c0"></span>
          </p>
          <p className="c2">
            <span className="c3">b.) Fee Changes</span>
            <span className="c0">
              . If any changes occur to the subscription amount, you will be
              given notice of at least one (1) billing cycle prior to the change
              taking effect. Notice will be given by the e-mail on your
              registered account or profile.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">c.) Free Trials</span>
            <span className="c0">
              . Any free trial, sample, or other abridged versions of our
              services that are offered shall be considered a one (1) time use
              based on the registering of a user&rsquo;s e-mail. It is strictly
              prohibited for a user to create and register more than one (1)
              e-mail solely to access another free trial on the website.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">ACCESS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Your access to and use of the website and the services is
              conditional upon your acceptance of and compliance with this
              Agreement, which applies to all the website&rsquo;s visitors. If
              for any reason, you do not agree with any of the terms of this
              Agreement, you may not access the website or its services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Additionally, your access to and use of the services is also
              conditional upon your acceptance of and compliance with our
              privacy policy which describes our policies and procedures on the
              collection, use, and disclosure of your personal information which
              is initiated by and commences when you use the website. The
              privacy policy discloses details and discloses your privacy rights
              and protections under applicable laws. It is advised to read our
              privacy policy prior to accessing the website or its services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Minors (under the age of 18)</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any user is a minor in the jurisdiction where they reside, the
              minor must obtain permission from their parent or guardian to use
              the website. If a minor accesses the website, it is assumed that
              their parent or guardian has read and agrees to this Agreement and
              has given permission to the minor to use the website.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">b.) Children (under the age of 13)</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any user is a child under the age of thirteen (13) years and
              from the United States, it is assumed that they have obtained
              permission and verifiable parental consent to use the website.
              Furthermore, this Agreement allows the protections mentioned under
              the Children&rsquo;s Online Privacy Protection, specifically, 15
              U.S. Code &sect; 6502.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We do not claim that the website&#39;s content is appropriate or
              suitable for you or any visitor. No information, data, or
              information provided on the website is intended for distribution
              or use in any location where such usage is prohibited or is
              contrary to any law or regulation which would subject the Company
              to the legal liability of any type. Any such access or use of the
              website is to be utilized on your own initiative, and you are
              solely responsible for any legal compliance.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Any supplemental amendments or documents posted on the website
              following the effective date of this Agreement are to be
              considered expressly incorporated into this Agreement.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">PROHIBITED ACTIVITIES</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              As a user of our services, whether on the website or mobile app,
              it is prohibited to engage in the following activities to:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Systematically retrieve data or other content from the website or
              services to create or compile, directly or indirectly, a
              collection, compilation, database, or directory without our
              written permission;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Trick, defraud, or mislead other users or us, especially in any
              attempt to learn sensitive account information such as user
              passwords;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Circumvent, disable, or otherwise interfere with security-related
              features of the website or services, including those that restrict
              the copying of content or protected marks;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Disparage, tarnish, or otherwise harm the company, website,
              mobile app, or any other platforms where the services are offered;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use any information obtained from the website or the service to
              harass, abuse, or harm another person or group of people;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Make improper use of our support services, specifically, our
              customer service representatives, or make false reports of abuse
              or misconduct;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use the website or services in a manner that is inconsistent with
              its intended use or against any applicable laws;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Engage in spamming, linking, or referring to other websites for
              commercial or other purposes;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Upload or transmit, or the attempt of such act, viruses, trojan
              horses, or other damaging or improper material, including the
              spamming or continuous posting of repetitive text, that has the
              potential to interfere, modify, impair, disrupt, alter, or
              interfere with another user&rsquo;s experience with the website or
              its features, functions, operations, or maintenance;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Attempting any unauthorized automated use of the website, such as
              using scripts to send comments and messages, or using mining tools
              with the intention of gathering, injecting, or extracting data;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Deleting copyrights, trademarks, disclaimers, or any other marks
              from the website or its content;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Impersonating another user or person by use of a username,
              e-mail, personal name, or in any other manner;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Upload or transmit, or the attempt of such act, any material that
              acts as a passive or active information collection or transmission
              mechanism, including without limitation, clear graphics
              interchange formats (&ldquo;gifs&rdquo;), 1x1 pixels, web bugs,
              cookies, or other similar devices, also known as
              &ldquo;spyware,&rdquo; &ldquo;passive collection mechanisms&rdquo;
              or &ldquo;pcms;&rdquo;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Interfering with, disrupting, or creating an undue burden on the
              website, services, networks, and other connections;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Harassing, annoying, intimidating, or threatening any of the
              other users, employees, agents, contractors, or any other
              individual affiliated with the company;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Disabling or attempting to disable restrictions implemented by
              the website that prohibits access to certain areas;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Copying or adapting the software of the website, including but
              not limited to Flash, PHP, HTML, JavaScript, or other code;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Deciphering, decompiling, disassembling, or reverse engineering
              any of the software on the website;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Except as may be the result of standard search engine or internet
              browser usage, to use, launch, develop, or distribute any
              automated system, including without limitation, any crawlable
              spider, robot or bots, cheat utility, scraper, or offline reader
              that accessed the website or services, or using or launching any
              unauthorized script or other software;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Using a buying or purchasing agent to make purchases on the
              website;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Making any unauthorized use of the website or the services, such
              as collecting usernames, e-mail addresses, or personal names of
              users by electronic or other means to send unsolicited e-mails or
              create user accounts by automated means or under false pretenses;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Using the website or services as part of any effort to compete
              with us or otherwise using the website, services, marks, content,
              data, or any part thereof for any revenue-generating endeavor,
              commercial purpose, or for personal benefit;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Using the website or services to advertise or offer to sell goods
              or other services;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Selling your user profile or account on the website.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">GUIDELINES FOR REVIEWS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We may provide you, either on the website or by a 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party, the right to leave a review or rating of the services
              provided. Said review or rating requires that you:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Should have firsthand experience with the person/entity being
              reviewed;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not contain any offensive, abusive, racist, profanity-laden
              language;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not reference discriminatory language or references related to
              religion, race, gender, national origin, age, marital status,
              sexual orientation, or disability;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not include references to illegal activity;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not post negative reviews as part of a scheme working with a
              competitor of ours;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not make any suggestions or conclusions as to the legality of
              our services, products, or conduct;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not post any false or misleading comments about your
              experience with the website or our company; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Do not organize a campaign encouraging others to post reviews,
              whether positive or negative.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              In our sole discretion, we may decide to accept, reject, or remove
              reviews. Our responsibility is to screen reviews to ensure that
              any postings regarding the website and its services are accurate
              and verifiable. Any reviews made by you or other users of the
              website do not represent our opinions or statements of the
              services or of our affiliates or partners. We do not assume
              liability, claims, or losses resulting from any review made on the
              website. By posting a review, you hereby grant us a perpetual,
              nonexclusive, worldwide, commercial, royalty-free, and assignable
              license (and sublicense ability) to reproduce, modify, translate,
              transmit by any means, display, perform, and/or distribute all
              content relating to such reviews made.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">MOBILE APPLICATION LICENSE</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If a proprietary mobile application is offered in connection to
              the services offered on the website (&ldquo;mobile app&rdquo;),
              the following will apply:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Use License</span>
            <span className="c0">
              . We grant you a revocable, nonexclusive, non-transferable, and
              limited license to install and use the mobile app to access the
              website and its services. Under such license, you agree that it is
              prohibited to conduct the following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Except as permitted by applicable law, to decompile, reverse
              engineer, disassemble, attempt to derive source code, or decrypt
              any part of the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Make any modification, adaptation, improvement, enhancement,
              translation, or derivative work from the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Violate any applicable laws or regulations in connection with
              your use or access to the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Remove, alter, or obscure any proprietary notice (including any
              notice of copyright or trademark) posted by us or the licensors of
              the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use the mobile app for any revenue-generating endeavor,
              commercial enterprise, or other purposes for which it is not
              designed or intended;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Allow the mobile app to be available over a network or other
              environment that permits access or use by multiple devices or
              users at the same time;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use the mobile app for creating a product, service, or software
              that is, directly or indirectly, competitive with or in any way a
              substitute for the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use the mobile app to send automated queries to any other website
              or application for unsolicited use, commercial or non-commercial;
              and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use any proprietary information provided by or through the use of
              the mobile app for the design, development, licensure, or
              distribution of any other applications, accessories, or other
              devices for use with the mobile app.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">b.) Apple and Android Devices</span>
            <span className="c0">
              . When using the mobile app on an Apple or Android device
              (&ldquo;mobile platform&rdquo;), the following will apply:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The license granted to you when using the mobile app is limited
              to a non-transferable license on a device that utilizes either of
              the mobile platforms, as applicable, in accordance with the usage
              rules set forth in the applicable mobile platform&rsquo;s terms of
              service and any other applicable documents;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -It is known that we are responsible for providing any maintenance
              and support services with respect to the mobile app in regard to
              updates made by the mobile platform and in its day-to-day use;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Any refund requests made by you from purchases made on the mobile
              app must be requested on the mobile platform in accordance with
              its refund policy. We cannot, on the website, assist in any refund
              request for payments made on the mobile app;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -If the jurisdiction or governing law of the company or mobile app
              is located in the United States, you verify that:
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -You are not located in a country that is subject to a U.S.
              government embargo, or that has been designated by the U.S.
              government as a &ldquo;terrorist supporting&rdquo; country; or
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -You are not listed on any U.S. government list of prohibited or
              restricted parties;
            </span>
          </p>
          <p className="c2">
            <span className="c0">-You must comply with applicable 3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party agreements that are used in combination with your
              wireless plan, network connection, or any other data service or
              device agreement; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -You acknowledge that the mobile platforms used in connection with
              the mobile app are 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party beneficiaries in regard to this Agreement and that
              each mobile platform will have the right to enforce its terms and
              conditions regarding your access and activities when using the
              mobile app. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">COPYRIGHT POLICY</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Intellectual Property Infringement</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              It is our duty to respect the intellectual property rights of
              others. Therefore, it is our policy to respond to any claim that
              infringes on any trademark, copyright, or other intellectual
              property protected under law.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you are an owner of any protected intellectual property that
              you feel is being used without your authorization, you must submit
              notice to us by any of the contact details mentioned herein and
              include a detailed description of the alleged infringement.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any request is made, and it is determined that you are not the
              owner of the intellectual property or do not have the authority to
              act on the owner&rsquo;s behalf, you may be held accountable for
              damages which may also include costs related to attorneys&rsquo;
              fees for such misrepresentation.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">
              b.) DMCA Notice and DMCA Procedure for Copyright Infringement
              Claims
            </span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              You may submit a notification pursuant to the Digital Millennium
              Copyright Act (DMCA) by providing our company with the following
              information in writing (see 17 U.S.C 512(c)(3) for further
              detail):
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -An electronic or physical signature of the copyright owner or a
              person authorized to act on behalf of the owner of the copyrighted
              work;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -A description of the copyrighted work that you claim has been
              infringed, including the URL(s) where the copyrighted work exists
              or a copy of the copyrighted work;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Your contact details including a personal name, address, phone
              number, and an e-mail address;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -A statement that the copyright infringement is not authorized and
              that the request to remove the copyrighted work is in good faith;
              and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -A statement by you, with language that includes &ldquo;under
              penalty of perjury,&rdquo; that the information included in the
              infringement removal is accurate.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Upon receipt of a copyright infringement notification, we will
              take whatever steps are required to remove the copyrighted content
              from the website or the services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">INTELLECTUAL PROPERTY</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Except as otherwise indicated, all source coding, databases,
              functionalities, software, graphic designs, and media of any kind
              (e.g. audio, video, text, photos, etc.), content, trademarks,
              service marks, logos, and copyrights are considered to be
              intellectual and proprietary information (&ldquo;intellectual
              property&rdquo;). Such intellectual information is under our
              ownership and protected by local, state, national, and
              international laws and will be defended.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              No intellectual property is permitted to be copied, reproduced,
              aggregated, republished, uploaded, posted, publicly displayed,
              encoded, translated, transmitted, distributed, sold, licensed, or
              otherwise exploited for commercial purposes without our express
              prior written permission.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">USER OBLIGATIONS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              You, as a user of the website or any of its services, agree to the
              following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Any information used for registration purposes, if required, must
              be submitted in an accurate and completed manner;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -If any information should change regarding your account, you
              agree to change it in a timely fashion;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -You have the legal capacity to understand, agree with, and comply
              with this Agreement;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -That you are not considered a minor in the jurisdiction where you
              reside or are accessing the website or its services;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -That you will not access the website or its services through the
              use of bots, scripts, or any other use than the traditional manner
              as is intended; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -That you will use the website and its services in an authorized
              and legal manner in accordance with this Agreement.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              In regard to any of the information submitted by you, if it should
              be deemed inaccurate, out of date, or incomplete, we reserve the
              right to terminate your access to the website or account and any
              future intended use.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">USER ACCOUNTS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If our website allows the creation of a user account of any type,
              you agree to be responsible for safeguarding its information
              including account data, connected e-mails, passwords, and any
              other personal information located therein. If you are made aware
              of any breach of unauthorized use of the account, to notify us as
              soon as possible. Furthermore, you agree not to disclose any
              password created with any 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party other than secured services used to assist with saving
              passwords on your behalf.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If the creation of a username is allowed when making an account,
              such username must be appropriate for public viewing and not
              violate any trademark, copyright, or other protected names or
              marks. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">THIRD (3RD) PARTY WEBSITE AND CONTENT</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Our website or services may contain links to 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party websites or services that are not owned or controlled
              by us. Therefore, we assume no responsibility for the content,
              privacy policies, terms of use, practices, services, experiences,
              activities, or any other acts by 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;parties. You acknowledge that if you are to be re-directed
              or forwarded to such 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party website, you hold us harmless and free of liability
              for any action that should occur on such websites, which may or
              may not include damages, losses, or any other claims. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">SITE MANAGEMENT</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              To ensure the best experience for all users of our website and
              services, we reserve the right, in our sole discretion, to do the
              following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -To monitor our website, services, and any other content for
              violations by users of this Agreement;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -To take appropriate actions against our users, including legal
              action, for those who may have violated this Agreement or have
              attempted to defraud or cause harm to other users;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -To refuse, restrict, limit, disable, or remove any and all files
              and Content which, due to excessive size limits or other
              properties, are burdensome to our systems or other users; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -To otherwise manage our website and services in such a way as to
              protect our rights and property and to encourage the optimal
              running of said websites and services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">PRIVACY POLICY</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Your access to and use of our website or services is conditional
              upon your acceptance of our privacy policy. Our privacy policy
              describes our rules and procedures on the collection, use, and
              disclosure of your personal information and details your privacy
              rights and how the law protects you and such data. It is
              recommended for all users to read to know their rights. Our
              privacy policy can be found in the footer area of the website.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We maintain the right to store certain data that you have
              transmitted by the use of the website or any of our services. You
              are solely responsible for the data you transmit and how it
              relates to any activity you have undertaken when using the website
              and any of its services. Therefore, you agree that we have no
              liability to you for any loss, breach, or corruption of any data
              and hereby waive any right of action against us that may or may
              not arise from such loss, breach, or corruption. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">TERMINATION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We may terminate or suspend your account for any reason and at our
              sole discretion. If your account is suspended or terminated, we
              may or may not provide prior notice. Upon termination, your access
              to the website and/or services will cease immediately.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you wish to terminate your relationship with us, such
              termination can be made by simply discontinuing your use of the
              website and its services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">GOVERNING LAW</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              The laws governing the company&rsquo;s jurisdiction mentioned
              herein shall govern this Agreement, including your use and access
              to the website and services. Your use of this website, services,
              and any mobile app may be subject to other local, state, national,
              and international laws.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">DISPUTE RESOLUTION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you should raise any dispute about the website, its content, or
              any of the services offered, it is required first to attempt to
              resolve the dispute formally by contacting us. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">a.) Mediation</span>
            <span className="c0">
              . If a dispute cannot be agreed upon by the parties, it shall be
              moved to mediation for a period of 30 days with at least 10 hours
              to be committed by each party in accordance with the procedures of
              the United States Arbitration &amp; Mediation. All costs related
              to said mediation shall be shared equally by both parties.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">b.) Arbitration</span>
            <span className="c0">
              . If the dispute cannot be agreed upon during the mediation
              period, then the dispute will be submitted to binding arbitration
              in the jurisdiction of governing law.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We maintain the right to bring proceedings regarding the substance
              of any dispute in the courts of the country where you or we
              reside.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">&rdquo;AS-IS&rdquo; DISCLAIMER</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              It is recognized to you, as a user of the website and any services
              offered, that they are provided on an &ldquo;as-is,&rdquo;
              &ldquo;where is,&rdquo; and &ldquo;as available&rdquo; basis,
              including faults and defects without warranty.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              To the maximum extent permitted under applicable law, the company,
              on its own behalf and those of its affiliates, licensors, and
              service providers, expressly disclaim all warranties, whether
              express, implied, statutory, or otherwise, with respect to the
              said website and any services offered, including all implied
              warranties of merchantability, fitness for a particular purpose,
              title and non-infringement, and warranties that may arise out of
              the course of dealing, course of performance, usage or trade
              practice. Without limitation to the foregoing, we provide no
              warranty or undertaking, and make no representation of any kind,
              that the content or any services provided will meet your
              requirements, achieve any intended results, be compatible or work
              with any other software, applications, systems, devices, or
              services, including operating without interruption, or meet any
              performance or reliability standards or be error and bug-free from
              any defects that can or will be corrected.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Without limiting the foregoing, neither we nor any of our
              providers make any representation or warranty of any kind, express
              or limited, in regard to the following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The operation or availability of the website or any services, or
              the information content, and materials or products included
              herein;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The website or any services being uninterrupted or bug-free;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The accuracy, reliability, or currency of any information or
              content provided through the website or services; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The website or any services, servers, content, or e-mails sent on
              behalf of our company is free of viruses, scripts, trojan horses,
              worms, malware, timebombs, or any other harmful code.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Some jurisdictions do not allow the exclusion of certain types of
              warranties or limitations on the applicable statutory rights of a
              consumer. Therefore, some or all of the above exclusions and
              limitations may not apply to you. The exclusions and limitations
              outlined in this section will be applied to the greatest extent
              under applicable law.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">INDEMNIFICATION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              You agree to defend, indemnify, and hold us harmless, including
              any of our subsidiaries, agents, or affiliates and our respective
              officers, agents, partners, and employees, from and against any
              loss, damage, liability, claim, or demand, including reasonable
              attorneys&rsquo; fees and expenses, made by any 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party due to or arising out of the following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">-Our content; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Use of the website or any of our services;{" "}
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Not able to use the website or any of our services;
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Any breach of this Agreement; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Any beach of representations and warranties set forth in this
              Agreement;
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Any violation of the rights of a 3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party, including but not limited to intellectual property
              rights; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Any overt harmful act toward any other user of the website or its
              services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Notwithstanding the foregoing, we reserve the right, at your
              expense, to assume the exclusive defense and control of any matter
              for which you are required to indemnify us, and you agree to
              cooperate, at your expense, with our defense of such claims. We
              agree to use reasonable efforts to notify you of any such claim,
              action, or proceeding which is subject to this indemnification
              upon becoming aware of it.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">NOTICES</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Except as explicitly stated otherwise, any notices sent to us must
              be sent to support@biblemedia.app. Any notices sent to you
              regarding any communication that must be sent in accordance with
              this Agreement will be sent to the e-mail registered to any
              account created on the website.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If notice is required to be sent via standard mail for legal or
              other purposes, the mailing address in Section 1 of this Agreement
              should be used.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">ELECTRONIC MEANS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              When accessing the website or any of its services, sending
              e-mails, online forms, esignatures, or any type or kind of
              electronic records or communication, you consent that all
              agreements, notices, disclosures, and other communications we
              provide to you in such manner satisfies any legal requirement that
              such communication should be in writing. You hereby agree that the
              use of such electronic means will be regarded as sufficient and be
              viewed as the same as its physical counterpart. Furthermore, you
              hereby waive any rights or requirements under any statutes,
              regulations, rules, ordinances, or other laws in any jurisdiction
              which require an original signature or delivery or retention of
              non-electronic records.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">CALIFORNIA USERS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in any
              of the methods below:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">Mail</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">1625 North Market Blvd, Suite N 112, </span>
          </p>
          <p className="c2">
            <span className="c0">Sacramento, California </span>
          </p>
          <p className="c2">
            <span className="c0">95834 </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">Telephone</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">(800) 952-5210 </span>
          </p>
          <p className="c2">
            <span className="c0">(916) 445-1254 </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">
              UNITED STATES FEDERAL GOVERNMENT END-USER PROVISIONS
            </span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you are a user acting on behalf of the U.S. federal government,
              our website and its services are treated as a &ldquo;commercial
              item&rdquo; as defined under 48 C.F.R. &sect; 2.101.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">EUROPEAN UNION (EU) USERS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you are a European Union (EU) resident, consumer, or user, it
              is recognized that you are entitled to specific protections on how
              your personal information is collected. We, in our privacy policy,
              attempt to be in accordance with such rules and regulations.
              &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c3">&nbsp;</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">MISCELLANEOUS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              This Agreement and any policies or operating rules posted by us,
              on the website, or through any services or in respect to such
              constitute the entire Agreement and understanding between you, as
              a user, and us, as a company. Our failure to exercise or enforce
              any right or provision of this Agreement will not operate as a
              waiver of such right or provision. This Agreement operates to the
              fullest extent permissible by law in accordance with the
              jurisdiction where we are located and to the protections that you,
              as a user, are entitled to in your jurisdiction. We reserve the
              right to assign any or all of our liabilities, services, and
              obligations to another party at any time. We shall not be
              responsible or liable for any loss, damage, delay, or failure to
              act caused by an event beyond our reasonable control.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If any provision, section, clause, or part of this Agreement is
              determined to be unlawful, void, or unenforceable, that said
              portion of this Agreement is determined to be severable and does
              not affect the validity and enforceability of any remaining
              language. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              It is understood that this Agreement does not create a joint
              venture, partnership, employment, or agency relationship between
              you and us, the website, or any of its services. You agree that
              this Agreement will not be construed against us by virtue of
              having drafted and published on the website for your review.
              Therefore, you agree to waive any and all defenses that may have
              been assumed under this Agreement and the lack of signing by any
              party hereto. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If this Agreement has been translated, you agree that its original
              English text shall prevail in the case of a dispute.
            </span>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};
