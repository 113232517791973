import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { EntityGrouping } from "../components/EntityGrouping";
import { Loading } from "../components/Loading";
import { getEntities } from "../lib/getEntities";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";
import { userService } from "../lib/userService";
import { searchStore, startSearching } from "../store/searchStore";
import { SearchBar } from "../components/search/SearchBar";
import { SearchResults } from "../components/search/SearchResults";
import { search } from "ionicons/icons";
import { Content } from "../components/Content";

export const Discover: React.FC = () => {
  const [entityIds, setEntityIds] = useState<any>([]);
  const [entityPages, setEntityPages] = useState<any>({});
  const [loadingMore, setLoadingMore] = useState(false);

  async function getDiscoverData() {
    const result = await userService.getDiscoverData("all", 0);
    setEntityIds(result.associatedEntities);
  }

  // Loading handler
  const [loading] = useWillEnterWithLoading(async () => {
    getDiscoverData();
  }, 400);

  async function loadMore(entityType: string) {
    if (loadingMore) {
      // This was an extra call, and we don't know whether
      // there are more or not, so return true
      return true;
    }

    setLoadingMore(true);

    if (!entityPages[entityType]) {
      entityPages[entityType] = 0;
    }

    entityPages[entityType] += 1;

    setEntityPages({ ...entityPages });

    const newDiscoverData = await userService.getDiscoverData(
      entityType,
      entityPages[entityType]
    );

    const newEntityIds: any[] = newDiscoverData.associatedEntities;

    if (newEntityIds.length === 0) {
      setLoadingMore(false);
      return false;
    }

    setEntityIds([...entityIds, ...newEntityIds]);

    setLoadingMore(false);

    return true;
  }

  let discoverDisplay = null;

  if (entityIds.length > 0) {
    const verses = getEntities(entityIds, "verse", true);
    // const themes = getEntities(discoverData.associatedEntities, "theme", true);
    const questions = getEntities(entityIds, "question", true);
    const songs = getEntities(entityIds, "song", true);
    const sermons = getEntities(entityIds, "sermon", true);
    // const genres = getEntities(discoverData.associatedEntities, "genre", true);

    discoverDisplay = (
      <>
        {/* <EntityGrouping title="Genres" entityIds={genres} /> */}
        <EntityGrouping
          title="Verses"
          entityIds={verses}
          onInfiniteScroll={async () => {
            return await loadMore("verse");
          }}
        />
        {/* <EntityGrouping title="Themes" entityIds={themes} /> */}
        <EntityGrouping title="Questions" entityIds={questions} />
        <EntityGrouping
          title="Sermons"
          entityIds={sermons}
          onInfiniteScroll={async () => {
            return await loadMore("sermon");
          }}
        />
        <EntityGrouping
          title="Songs"
          entityIds={songs}
          onInfiniteScroll={async () => {
            return await loadMore("song");
          }}
        />
      </>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ "--background": "var(--light)" }}>
          {!searchStore.searching && (
            <>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle className="toolbar-center">Discover</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={startSearching}>
                  <IonIcon slot="icon-only" icon={search} />
                </IonButton>
              </IonButtons>
            </>
          )}
          {searchStore.searching && <SearchBar />}
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading} ContentComponent={Content}>
        <Content fullscreen>
          <div style={{ paddingTop: "2em" }}>
            {!searchStore.searching && discoverDisplay}
            {searchStore.searching && <SearchResults />}
          </div>
        </Content>
      </Loading>
    </IonPage>
  );
};
