import { IonCardTitle } from "@ionic/react";
import { entityTypeConfigs } from "../../../lib/entityTypeConfigs";
import { IconText } from "./common/IconText";

export function DisplayQuestionEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;

  if (prominence === "primary" || prominence === "hero") {
    return (
      <IconText icon={entityTypeConfigs.question.icon}>
        <IonCardTitle>{entityData.question}</IonCardTitle>
      </IconText>
    );
  }

  if (prominence === "secondary") {
    return (
      <IconText icon={entityTypeConfigs.question.icon}>
        <IonCardTitle>{entityData.question}</IonCardTitle>
      </IconText>
    );
  }

  return null;
}
