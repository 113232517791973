import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { Card } from "../components/Card";
import { rerender } from "../lib/rerender";
import { presentToast } from "../lib/toast";
import { userService } from "../lib/userService";
import { userStore } from "../store/userStore";

export const VerifyEmail: React.FC = () => {
  const [error, setError] = useState("");

  const verifyEmail = useCallback(async function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const email = urlParams.get("email");

    if (email && token) {
      const result = await userService.verifyEmail(email, token);

      if (result.error) {
        setError(result.error);
      } else {
        presentToast({
          message: `Email verified!`,
          duration: 2000,
          position: "bottom",
          color: "success",
        });

        const { user } = userStore;
        user.userData.emailVerified = true;

        window.location.href = "/intro/3";

        rerender();
      }
    }
  }, []);

  useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Verify Email</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Card>
          <div>Verifying your email</div>

          {!!error && (
            <div>
              <IonLabel color="danger">{error}</IonLabel>
            </div>
          )}
        </Card>
      </IonContent>
    </IonPage>
  );
};
