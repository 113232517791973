import { IonSpinner } from "@ionic/react";

export function Loading({
  children,
  loading,
  ContentComponent = ({ children }: any) => children,
  hideShade = false,
}: any) {
  if (loading) {
    return (
      <ContentComponent hideShade={hideShade} fullscreen>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IonSpinner name="dots" style={{ marginTop: "2em" }} />
        </div>
      </ContentComponent>
    );
  } else {
    return children;
  }
}
