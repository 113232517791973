import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { LoginForm } from "./LoginForm";

const Login: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <LoginForm
          onSuccess={() => {
            window.location.href = "/home";
          }}
        />
        <IonButton
          fill="clear"
          style={{ marginLeft: "1em" }}
          onClick={async () => {
            router.push("/register");
          }}
        >
          Register Now
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Login;
