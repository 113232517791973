import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { ToggleNotificationSetting } from "../components/settings/ToggleNotificationSetting";
import { userService } from "../lib/userService";

export const SettingsNotifications: React.FC = () => {
  const [settings, setSettings] = useState<any>();

  useEffect(() => {
    (async () => {
      const settings = await userService.getNotificationSettings();
      setSettings(settings);
    })();
  }, []);

  if (!settings) {
    return null;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle className="toolbar-center">Notification Settings</IonTitle>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList style={{ padding: "1em" }}>
          {userService.isLoggedIn() && (
            <>
              <ToggleNotificationSetting
                label="Verse of the day"
                settingId="verseOfTheDay"
                setSettings={setSettings}
                settings={settings}
              />

              <ToggleNotificationSetting
                label="New release (song/sermon)"
                settingId="contentCreatorContent"
                setSettings={setSettings}
                settings={settings}
              />

              <ToggleNotificationSetting
                label="New content (on Bible verse)"
                settingId="verseContent"
                setSettings={setSettings}
                settings={settings}
              />
            </>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
