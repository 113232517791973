import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonChip,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { Card } from "../../components/Card";
import { DeviceInfo } from "../../components/DeviceInfo";
import { LabeledInput } from "../../components/LabeledInput";
import { adminService } from "../../lib/adminService";
import { formatDate } from "../../lib/formatDate";
import { pushNotificationService } from "../../lib/pushNotificationsService";
import { presentToast } from "../../lib/toast";
import { systemStore } from "../../store/systemStore";

const EditUsers: React.FC = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState<any>(null);
  const [pushNotificationTokens, setPushNotificationTokens] = useState<any[]>(
    []
  );
  const [password, setPassword] = useState("");
  const [subscription, setSubscription] = useState<any>(null);
  const { roles } = systemStore;
  const [recentUsers, setRecentUsers] = useState<any[]>([]);

  useIonViewWillEnter(() => {
    (async function () {
      const result = await adminService.getRecentRegistrations();
      setRecentUsers(result);
    })();
  });

  async function loadUser(email: string, deviceId = "") {
    const result = await adminService.getUser(email, deviceId);
    setUser(result.user);
    setPushNotificationTokens(result.tokens);
    setPassword("");
    setSubscription(result.subscription);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Edit Users</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Card>
          <IonLabel>Email</IonLabel>
          <IonInput
            value={email}
            onIonChange={(event) => {
              setEmail(event.target.value as string);
            }}
          />
          <IonButton onClick={async () => loadUser(email)}>Find</IonButton>
        </Card>

        {!!user && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <LabeledInput
                  value={user.email}
                  label="email"
                  onIonChange={(event) => {
                    user.email = event.target.value;
                    setUser({ ...user });
                  }}
                />
              </IonCardTitle>
              DeviceId: {user.deviceId}
              <br />
              UserId: {user.userId}
            </IonCardHeader>
            <IonCardContent>
              <LabeledInput
                value={password}
                label="New Password"
                onIonChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              {user.userData.roles && (
                <IonList>
                  {roles.map((role) => {
                    return (
                      <IonItem key={role}>
                        <IonCheckbox
                          checked={user.userData.roles.includes(role)}
                          onClick={() => {
                            const index = user.userData.roles.indexOf(role);

                            if (index >= 0) {
                              user.userData.roles.splice(index, 1);
                            } else {
                              user.userData.roles.push(role);
                            }

                            setUser({
                              ...user,
                            });
                          }}
                        />{" "}
                        <IonLabel>{role}</IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              )}

              <IonItem>
                Email Verified
                <IonToggle
                  slot="end"
                  checked={user.userData.emailVerified}
                  onClick={() => {
                    user.userData.emailVerified = !user.userData.emailVerified;

                    setUser({ ...user });
                  }}
                />
              </IonItem>

              {pushNotificationTokens.map((tokenData) => {
                return (
                  <IonItem key={tokenData.token}>
                    <div>
                      {tokenData.token.substring(0, 30)}...
                      <div>
                        <DeviceInfo deviceInfo={tokenData.deviceInfo} />
                        {tokenData.valid === 0 && (
                          <IonChip
                            color="danger"
                            style={{
                              fontSize: "0.75em",
                              padding: "0em 0.5em",
                              height: "1.75em",
                            }}
                          >
                            Invalid
                          </IonChip>
                        )}
                      </div>
                    </div>
                    <IonButton
                      slot="end"
                      onClick={() => {
                        adminService.sendTestPushNotification(tokenData.token);
                      }}
                    >
                      Test
                    </IonButton>
                    <IonButton
                      slot="end"
                      color="danger"
                      onClick={async () => {
                        await adminService.deleteUserPushNotificationToken(
                          tokenData.token
                        );
                        await loadUser(user.email, user.deviceId);
                      }}
                    >
                      Delete
                    </IonButton>
                  </IonItem>
                );
              })}

              <IonItem>
                Add push notification token
                <IonButton
                  slot="end"
                  onClick={() => {
                    const token = prompt("Enter the token");

                    if (token) {
                      pushNotificationService.adminAddPushNotificationToken(
                        token,
                        user.userId
                      );
                    }
                  }}
                >
                  Add
                </IonButton>
              </IonItem>

              <IonLabel>Subscription Data</IonLabel>
              {!!subscription && <div>{JSON.stringify(subscription)}</div>}

              <IonButton
                onClick={async () => {
                  const result = await adminService.saveUser(
                    user,
                    password,
                    user.userData.emailVerified
                  );
                  setUser(result);

                  presentToast({
                    message: `Saved!`,
                    duration: 2000,
                    position: "bottom",
                    color: "success",
                  });
                }}
              >
                Save
              </IonButton>
            </IonCardContent>
          </IonCard>
        )}

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Recent Registrations</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {recentUsers.map((user: any) => {
                return (
                  <IonItem key={user.userId}>
                    <div>
                      <b>
                        {user.email || (
                          <>
                            {user.deviceId}{" "}
                            <IonChip
                              style={{
                                fontSize: "0.75em",
                                padding: "0em 0.5em",
                                height: "1.75em",
                              }}
                            >
                              Device Registered
                            </IonChip>
                          </>
                        )}
                      </b>
                      <br />
                      {formatDate(user.addDatetime)}
                    </div>
                    <IonButton
                      slot="end"
                      onClick={async () => loadUser(user.email, user.deviceId)}
                    >
                      Edit
                    </IonButton>
                  </IonItem>
                );
              })}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default EditUsers;
