import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { Content } from "../Content";
import { EntityWrapper } from "../entities/display/EntityWrapper";
import { Loading } from "../Loading";
import { formatDate } from "../../lib/formatDate";
import { entities } from "../../store/entities";
import { searchStore, setSearchString } from "../../store/searchStore";

export const SearchResults: React.FC = () => {
  return (
    <Content>
      <Loading loading={searchStore.loading} ContentComponent={Content}>
        {searchStore.entityIds.map((entityId) => {
          const entityContainer = entities.get(entityId);

          return (
            <div key={entityContainer.entity.entityId}>
              <EntityWrapper
                entityContainer={entityContainer}
                prominence={"primary"}
              />
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "1em",
                  marginTop: "-0.5em",
                  opacity: 0.5,
                  fontSize: "0.75em",
                }}
              >
                {formatDate(entityContainer.entity.addDatetime)}
              </div>
            </div>
          );
        })}
        {!!searchStore.searchString &&
          !searchStore.entityIds.length &&
          !searchStore.loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              Sorry we don't have any results for "{searchStore.searchString}"
            </div>
          )}
      </Loading>

      <IonInfiniteScroll
        onIonInfinite={(event) => {
          setSearchString(
            searchStore.searchString,
            searchStore.page + 1,
            event
          );
        }}
        threshold="100px"
        disabled={searchStore.infiniteDisabled}
      >
        <IonInfiniteScrollContent
          loadingSpinner="dots"
          loadingText="Loading more"
        />
      </IonInfiniteScroll>
    </Content>
  );
};
