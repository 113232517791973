import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { Card } from "../../../components/Card";
import { adminService } from "../../../lib/adminService";
import EntityChangeItem from "./EntityChangeItem";

const ApproveEntityChanges: React.FC = () => {
  const [changes, setChanges] = useState<any>([]);

  useIonViewWillEnter(() => {
    (async function () {
      const result = await adminService.getPendingEntityChanges();
      setChanges(result);
    })();
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Approve Changes</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Card>
          {changes.length === 0 && <span>No changes to approve</span>}
          {changes.length > 0 && (
            <>
              <IonList>
                {changes.map((change: any) => {
                  return (
                    <EntityChangeItem
                      key={change.id}
                      change={change}
                      onApprove={async () => {
                        await adminService.approveChange(change.id);

                        const result =
                          await adminService.getPendingEntityChanges();
                        setChanges(result);
                      }}
                      onDeny={async () => {
                        await adminService.denyChange(change.id);

                        const result =
                          await adminService.getPendingEntityChanges();
                        setChanges(result);
                      }}
                    />
                  );
                })}
              </IonList>
            </>
          )}
        </Card>
      </IonContent>
    </IonPage>
  );
};

export default ApproveEntityChanges;
