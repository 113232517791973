import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { Device } from "@capacitor/device";
import { useEffect, useState } from "react";

export const AdminDebug: React.FC = () => {
  const [deviceInfo, setDeviceInfo] = useState<any>();

  useEffect(() => {
    (async () => {
      const info = await Device.getInfo();
      const id = (await Device.getId()).uuid;

      setDeviceInfo({
        ...info,
        deviceId: id, // This will change if the app is uninstalled and reinstalled
      });
    })();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Feedback</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <pre>{JSON.stringify(deviceInfo, null, 2)}</pre>
      </IonContent>
    </IonPage>
  );
};
