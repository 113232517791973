import axios from "axios";
import { localSettingsStore } from "../store/localSettingsStore";
import { presentToast } from "./toast";

export function getApiUrl() {
  if (localStorage.apiUrl) {
    return localStorage.apiUrl;
  }

  return process.env.REACT_APP_API_URL;
}

console.log("API URL", getApiUrl());

const api = axios.create({
  baseURL: getApiUrl(),
});

export async function makeRequest(eventName: string, payload: any = {}) {
  // Always include the versionId, unless it is already specified
  payload.versionId = payload.versionId || localSettingsStore.bibleVersion;

  const response = await api.post(`/event/${eventName}`, payload);

  if (response.data.error) {
    presentToast({
      message: `Error: ${response.data.error}`,
      duration: 1500,
      position: "bottom",
      color: "danger",
    });
  }

  return response;
}
