import React from "react";

export type ModalInstanceProps = Required<
  | {
      id: string;
    }
  | { [key: string]: any }
>;

export type ModalInstance = {
  Component: React.FC<ModalInstanceProps>;
  props: ModalInstanceProps;
};

export const modalStore: {
  modals: ModalInstance[];
} = {
  modals: [],
};

(window as any).modalStore = modalStore;
