import { rerender } from "../../lib/rerender";

export function removeEntityOnClick(
  event: any,
  parent: any,
  entityContainer: any
) {
  event.stopPropagation();

  parent.associationDeletions.push(entityContainer.entity.entityId);

  rerender();
}
