import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  book,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { bookChapterCounts, books } from "../data/books";
import { chapterVerseCounts } from "../data/chapterVerseCounts";
import { localSettingsStore, setSetting } from "../store/localSettingsStore";
import { LabeledInput } from "./LabeledInput";

interface Props {
  mode: "chapter" | "verse";
  canPrevious?: boolean;
  canNext?: boolean;
  book: number;
  chapter: number;
  verse?: number;
  onBookChange?: (book: number) => void;
  onChapterChange?: (chapter: number) => void;
  onVerseChange?: (verse: number) => void;
  onVersionChange?: () => void;
}

export function BookChapterVerseSelector({
  mode,
  canNext = false,
  canPrevious = false,
  book,
  chapter,
  verse = 0,
  onBookChange = () => {},
  onChapterChange = () => {},
  onVerseChange = () => {},
  onVersionChange = () => {},
}: Props) {
  const [showBookModal, setShowBookModal] = useState(false);
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [showVerseModal, setShowVerseModal] = useState(false);
  const [showVersionModal, setShowVersionModal] = useState(false);

  return (
    <>
      <IonButtons>
        <div style={{ width: "3.25em" }}>
          {canPrevious && (
            <IonButton onClick={() => onChapterChange(chapter - 1)}>
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          )}
        </div>

        <IonButton
          size="small"
          fill="solid"
          onClick={() => {
            setShowVersionModal(true);
          }}
        >
          {localSettingsStore.bibleVersion.toUpperCase()}
        </IonButton>
        <IonButton
          style={{ textTransform: "none" }}
          size="small"
          fill="solid"
          onClick={() => setShowBookModal(true)}
        >
          {books[book]}
        </IonButton>
        <IonButton
          style={{ textTransform: "none" }}
          size="small"
          fill="solid"
          onClick={() => setShowChapterModal(true)}
        >
          {chapter + 1}
        </IonButton>
        {mode === "verse" && (
          <IonButton
            style={{ textTransform: "none" }}
            size="small"
            fill="solid"
            onClick={() => setShowVerseModal(true)}
          >
            {verse + 1}
          </IonButton>
        )}

        <div style={{ width: "2.5em" }}>
          {canNext && (
            <IonButton onClick={() => onChapterChange(chapter + 1)}>
              <IonIcon slot="icon-only" icon={chevronForwardOutline} />
            </IonButton>
          )}
        </div>
      </IonButtons>

      {showBookModal && (
        <BookModal
          onDismiss={() => setShowBookModal(false)}
          onSelectBookIndex={(index: number) => {
            const value = Number(index);

            // if (changing.current) {
            //   return;
            // }

            onBookChange(value);
          }}
        />
      )}

      {showChapterModal && (
        <ChapterModal
          book={book}
          onDismiss={() => setShowChapterModal(false)}
          onSelectChapterIndex={(index: number) => {
            // if (changing.current) {
            //   return;
            // }

            if (index < 0) {
              index = 0;
            }

            onChapterChange(index);
          }}
        />
      )}

      {showVerseModal && (
        <VerseModal
          book={book}
          chapter={chapter}
          onDismiss={() => setShowVerseModal(false)}
          onSelectVerseIndex={(index: number) => {
            // if (changing.current) {
            //   return;
            // }

            if (index < 0) {
              index = 0;
            }

            onVerseChange(index);
          }}
        />
      )}

      {showVersionModal && (
        <VersionModal
          onDismiss={() => setShowVersionModal(false)}
          onSelectVersion={() => {
            // if (changing.current) {
            //   return;
            // }

            onVersionChange();
          }}
        />
      )}
    </>
  );
}

function BookModal({ onDismiss, onSelectBookIndex }: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [bookName, setBookName] = useState("");
  const bookNameRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (bookNameRef?.current) {
        bookNameRef.current.setFocus();
      }
    }, 100);
  }, [bookNameRef]);

  return (
    <IonModal
      ref={modal}
      isOpen={true}
      initialBreakpoint={0.85}
      onDidDismiss={() => onDismiss()}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Select Book</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: "0em 1em", paddingBottom: "10em" }}>
          <LabeledInput
            ref={bookNameRef}
            value={bookName}
            label="Book Name"
            onIonChange={(event) => setBookName(event.target.value)}
          />
          {books.map((otherBookName, index) => {
            if (
              bookName.toLocaleLowerCase() &&
              !otherBookName.toLocaleLowerCase().includes(bookName)
            ) {
              return null;
            }

            return (
              <IonItem
                key={otherBookName}
                onClick={() => {
                  onSelectBookIndex(index);
                  modal.current?.dismiss();
                }}
              >
                {otherBookName}
              </IonItem>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
}

function ChapterModal({ book, onDismiss, onSelectChapterIndex }: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [chapterIndex, setChapterIndex] = useState("");
  const chapterRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (chapterRef?.current) {
        chapterRef.current.setFocus();
      }
    }, 100);
  }, [chapterRef]);

  return (
    <IonModal
      ref={modal}
      isOpen={true}
      initialBreakpoint={0.85}
      onDidDismiss={() => onDismiss()}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Select Chapter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: "0em 1em", paddingBottom: "10em" }}>
          <IonList>
            <IonItem>
              <LabeledInput
                ref={chapterRef}
                value={chapterIndex}
                label="Chapter"
                type="number"
                onIonChange={(event) => setChapterIndex(event.target.value)}
                asItem={false}
              />
              <IonButton
                slot="end"
                size="default"
                onClick={() => {
                  onSelectChapterIndex(Number(chapterIndex) - 1);
                  modal.current?.dismiss();
                }}
              >
                Go
              </IonButton>
            </IonItem>

            {Array.from({ length: bookChapterCounts[book] }, (v, i) => i).map(
              (_, chapterIndex) => {
                return (
                  <IonItem
                    key={chapterIndex}
                    onClick={() => {
                      onSelectChapterIndex(chapterIndex);
                      modal.current?.dismiss();
                    }}
                  >
                    {chapterIndex + 1}
                  </IonItem>
                );
              }
            )}
          </IonList>
        </div>
      </IonContent>
    </IonModal>
  );
}

function VerseModal({ book, chapter, onDismiss, onSelectVerseIndex }: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const [verseIndex, setVerseIndex] = useState("");
  const verseRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (verseRef?.current) {
        verseRef.current.setFocus();
      }
    }, 100);
  }, [verseRef]);

  const verseCount = chapterVerseCounts.get(`${book + 1}:${chapter + 1}`) || 0;

  return (
    <IonModal
      ref={modal}
      isOpen={true}
      initialBreakpoint={0.85}
      onDidDismiss={() => onDismiss()}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Select Verse</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: "0em 1em", paddingBottom: "10em" }}>
          <IonItem>
            <LabeledInput
              ref={verseRef}
              value={verseIndex}
              label="Verse"
              type="number"
              onIonChange={(event) => {
                let value = event.target.value;

                if (Number(value) > verseCount) {
                  value = `${verseCount}`;
                }

                setVerseIndex(value);
              }}
              asItem={false}
            />
            <IonButton
              slot="end"
              size="default"
              onClick={() => {
                onSelectVerseIndex(Number(verseIndex) - 1);
                modal.current?.dismiss();
              }}
            >
              Go
            </IonButton>
          </IonItem>

          {Array.from(
            {
              length: verseCount,
            },
            (v, i) => i
          ).map((_, verseIndex) => {
            return (
              <IonItem
                key={verseIndex}
                onClick={() => {
                  onSelectVerseIndex(verseIndex);
                  modal.current?.dismiss();
                }}
              >
                {verseIndex + 1}
              </IonItem>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
}

const versions = [
  { name: "American Standard Version", id: "asv" },
  { name: "King James Version", id: "kjv" },
  { name: "World English Bible", id: "web" },
];

function VersionModal({ onDismiss, onSelectVersion }: any) {
  const modal = useRef<HTMLIonModalElement>(null);
  const chapterRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (chapterRef?.current) {
        chapterRef.current.setFocus();
      }
    }, 100);
  }, [chapterRef]);

  return (
    <IonModal
      ref={modal}
      isOpen={true}
      initialBreakpoint={0.85}
      onDidDismiss={() => onDismiss()}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Select Version</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: "0em 1em", paddingBottom: "10em" }}>
          {versions.map((version) => {
            return (
              <IonItem
                key={version.id}
                type="button"
                onClick={() => {
                  setSetting("bibleVersion", version.id);
                  onSelectVersion(version.id);
                  modal.current?.dismiss();
                }}
              >
                {version.name} [{version.id.toUpperCase()}]
                {version.id === localSettingsStore.bibleVersion && (
                  <IonIcon
                    icon={book}
                    slot="end"
                    color="primary"
                    style={{ height: "0.95em" }}
                  />
                )}
              </IonItem>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
}
