import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { entityService } from "../../lib/entityService";
import { modalService } from "../../lib/modalService";
import { rerender } from "../../lib/rerender";
import { presentToast } from "../../lib/toast";
import { entities } from "../../store/entities";

export function EditEntitySection({
  id,
  title,
  type = "modal",
  entityType,
  parent,
  entityIds,
  EntityComponent,
  EditModalComponent,
  onChange,
}: any) {
  const [availableEntities, setAvailableEntities] = useState<any[]>([]);

  const getEntities = useCallback(
    async function () {
      if (type === "checkbox") {
        const entities = await entityService.getEntitiesByType(
          entityType,
          0,
          100
        );
        setAvailableEntities(entities);
      }
    },
    [type, entityType]
  );

  useEffect(() => {
    getEntities();
  }, [getEntities]);

  async function openModal(entityId = undefined) {
    if (entityId) {
      // This will update the entity cache, no need to update the parent
      await entityService.getEntity(entityId, {
        skipUpdates: [parent.entity.entityId],
        includePending: true,
      });
    }

    modalService.openModalByComponent(EditModalComponent, {
      entityId,
      options: { edit: true, select: true },
      onSelect: async (entityContainer: any) => {
        const { entityId } = entityContainer.entity;

        if (parent.associatedEntities.includes(entityId)) {
          parent.associationDeletions = parent.associationDeletions.filter(
            (otherEntityId: string) => {
              return otherEntityId !== entityId;
            }
          );

          return;
        }

        // Fetch the entity so that it is populated in the client
        await entityService.getEntity(entityId);

        parent.associationAdditions = parent.associationAdditions || [];
        parent.associationDeletions = parent.associationDeletions || [];

        parent.associatedEntities = [...parent.associatedEntities, entityId];
        parent.associationAdditions.push(entityId);

        onChange(true);

        rerender();

        presentToast({
          message: "Added!",
          duration: 1500,
          position: "bottom",
        });
      },
    });
  }

  return (
    <>
      <IonCard>
        <IonCardContent>
          <IonCardTitle>{title}</IonCardTitle>
          <div>
            {entityIds.map((entityId: any) => {
              const entityContainer = entities.get(entityId);

              const component = (
                <EntityComponent
                  entityContainer={entityContainer}
                  parent={parent}
                  canEdit={true}
                  onChange={onChange}
                  openModal={openModal}
                />
              );

              // TODO: pass the state indicator to the EntityComponent so it can decide what to so
              // Also allow the component how to render the delete button, so that something can be added back
              if (parent.associationDeletions.includes(entityId)) {
                return (
                  <span
                    key={entityContainer.entity.entityId}
                    style={{ opacity: 0.25 }}
                  >
                    {" "}
                    {component}
                  </span>
                );
              } else if (parent.associationAdditions.includes(entityId)) {
                return (
                  <span key={entityContainer.entity.entityId}>{component}</span>
                );
              } else {
                return (
                  <span key={entityContainer.entity.entityId}>{component}</span>
                );
              }
            })}
          </div>

          {type === "checkbox" && (
            <IonGrid>
              <IonRow>
                {availableEntities.map((entityId) => {
                  const { entity } = entities.get(entityId);

                  return (
                    <IonCol
                      key={entity.entityId}
                      size="12"
                      size-sm="6"
                      style={{ padding: "0em" }}
                    >
                      <IonItem style={{ height: "2.5em" }}>
                        <IonCheckbox
                          slot="start"
                          checked={entityIds.includes(entityId)}
                          onIonChange={() => {
                            parent.associationAdditions =
                              parent.associationAdditions || [];
                            parent.associationDeletions =
                              parent.associationDeletions || [];

                            parent.associationAdditions.push(entityId);

                            const index =
                              parent.associatedEntities.indexOf(entityId);

                            if (index >= 0) {
                              // Remove the entity
                              parent.associatedEntities.splice(index, 1);
                              parent.associationDeletions.push(entityId);

                              // Remove from additions
                              if (
                                parent.associationAdditions.includes(entityId)
                              ) {
                                const otherIndex =
                                  parent.associationAdditions.indexOf(entityId);
                                parent.associationAdditions.splice(otherIndex);
                              }
                            } else {
                              // Add the entity
                              parent.associatedEntities.push(entityId);
                              parent.associationDeletions.push(entityId);

                              // Remove from deletions
                              if (
                                parent.associationDeletions.includes(entityId)
                              ) {
                                const otherIndex =
                                  parent.associationDeletions.indexOf(entityId);
                                parent.associationDeletions.splice(otherIndex);
                              }
                            }
                            onChange(true);
                            rerender();
                          }}
                        ></IonCheckbox>
                        <IonLabel>{entity.entityName}</IonLabel>
                      </IonItem>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          )}

          {type === "modal" && (
            <IonButton
              onClick={() => {
                openModal();
              }}
            >
              Add {title}
            </IonButton>
          )}
        </IonCardContent>
      </IonCard>
    </>
  );
}
