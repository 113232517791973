import { IonButton, IonItem, IonLabel } from "@ionic/react";

export function APIBackendSetting() {
  function setUrl(url: string) {
    localStorage.apiUrl = url;
    window.location.reload();
  }

  return (
    <IonItem>
      <IonLabel>
        {localStorage.apiUrl || process.env.REACT_APP_API_URL}
      </IonLabel>
      <IonButton
        slot="end"
        onClick={() => setUrl("https://backend.biblemedia.app")}
      >
        Prod
      </IonButton>
      <IonButton
        slot="end"
        onClick={() => setUrl("https://backend.biblemedia.app:4001")}
      >
        Test
      </IonButton>
      <IonButton slot="end" onClick={() => setUrl("")}>
        Reset
      </IonButton>
    </IonItem>
  );
}
