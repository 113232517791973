import { EditSongModal } from "../../components/modals/EditSongModal";
import BrowseEntityType from "../BrowseEntityType";

const Songs: React.FC = () => {
  return (
    <BrowseEntityType
      title="Songs"
      entityType="song"
      EditModalComponent={EditSongModal}
    />
  );
};

export default Songs;
