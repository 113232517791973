import { makeRequest } from "./api";

export const adminService = {
  async getUser(email: string, deviceId: string) {
    const result = (
      await makeRequest("admin.getUser", {
        email,
        token: localStorage.token,
        deviceId,
      })
    ).data;

    return result;
  },

  async saveUser(user: any, password: string, emailVerified: boolean) {
    const result = (
      await makeRequest("admin.saveUser", {
        user,
        password,
        emailVerified,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async getPendingEntityChanges() {
    const result = (
      await makeRequest("admin.getPendingEntityChanges", {
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async approveChange(changeId: number) {
    const result = (
      await makeRequest("admin.approvePendingEntityChange", {
        changeId,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async denyChange(changeId: number) {
    const result = (
      await makeRequest("admin.denyPendingEntityChange", {
        changeId,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async getRecentRegistrations() {
    const result = (
      await makeRequest("admin.getRecentRegistrations", {
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async deleteEntity(entityId: string) {
    const result = (
      await makeRequest("admin.deleteEntity", {
        entityId,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async runReport(report: string) {
    const result = (
      await makeRequest("admin.runReport", {
        report,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async getMonthVerseOfTheDay(month: number, year: number) {
    const result = (
      await makeRequest("admin.getMonthVerseOfTheDay", {
        token: localStorage.token,
        month,
        year,
      })
    ).data;

    return result;
  },

  async setVerseOfTheDay(scheduledDate: Date, verse: string) {
    const result = (
      await makeRequest("admin.setVerseOfTheDay", {
        token: localStorage.token,
        scheduledDate,
        verse,
      })
    ).data;

    return result;
  },

  async clearVerseOfTheDay(scheduledDate: Date) {
    const result = (
      await makeRequest("admin.clearVerseOfTheDay", {
        token: localStorage.token,
        scheduledDate,
      })
    ).data;

    return result;
  },

  async getVerseOfTheDayLastUsed(scheduledDate: Date, verse: string) {
    const result = (
      await makeRequest("admin.getVerseOfTheDayLastUsed", {
        token: localStorage.token,
        scheduledDate,
        verse,
      })
    ).data;

    return result;
  },

  async getFeedback() {
    const result = (
      await makeRequest("admin.getFeedback", { token: localStorage.token })
    ).data;

    return result;
  },

  async sendTestPushNotification(pushNotificationToken: string) {
    const result = (
      await makeRequest("admin.sendTestPushNotification", {
        pushNotificationToken,
        token: localStorage.token,
      })
    ).data;

    return result;
  },

  async deleteUserPushNotificationToken(pushNotificationToken: string) {
    const result = (
      await makeRequest("admin.deleteUserPushNotificationToken", {
        pushNotificationToken,
        token: localStorage.token,
      })
    ).data;

    return result;
  },
};
