import { IonButton, IonItem, IonLabel } from "@ionic/react";

interface Props {
  label: string;
  onClick: () => void;
  buttonText: string;
  subText?: any;
  color?: string;
  fill?: "solid" | "clear" | "outline" | "default" | undefined;
}

export function ButtonSetting({
  label,
  onClick,
  buttonText,
  subText,
  color,
  fill = "solid",
}: Props) {
  return (
    <IonItem>
      <IonLabel>
        {label}
        <div
          style={{
            fontSize: "0.7em",
            marginTop: "0.25em",
            color: "rgba(0,0,0,0.6)",
            whiteSpace: "normal",
          }}
        >
          {subText}
        </div>
      </IonLabel>
      <IonButton slot="end" color={color} fill={fill} onClick={onClick}>
        {buttonText}
      </IonButton>
    </IonItem>
  );
}
