import {
  IonButton,
  IonButtons,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { EntityWrapper } from "../components/entities/display/EntityWrapper";
import { Loading } from "../components/Loading";
import { entityService } from "../lib/entityService";
import { formatDate } from "../lib/formatDate";
import { modalService } from "../lib/modalService";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";
import { entities } from "../store/entities";
import { Content } from "../components/Content";

interface Props {
  title: string;
  entityType?: string;
  EditModalComponent?: any;
  hideShade?: boolean;
}

const BrowseEntityType: React.FC<Props> = ({
  title,
  entityType,
  EditModalComponent,
  hideShade = false,
}) => {
  const [entityIds, setEntityIds] = useState<any[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  // Loading handler
  const [loading] = useWillEnterWithLoading(async () => {
    const newEntityIds = await entityService.getEntitiesByType(entityType, 0);
    setEntityIds(Array.from(new Set([...newEntityIds])));
  }, 400);

  async function loadMore(event: any) {
    if (loadingMore) {
      event.target.complete();
      return;
    }

    setLoadingMore(true);
    setPage(page + 1);

    const newEntityIds = await entityService.getEntitiesByType(
      entityType,
      page + 1
    );

    if (newEntityIds.length === 0) {
      setLoadingMore(false);
      setInfiniteDisabled(true);
      event.target.complete();
    }

    setEntityIds(Array.from(new Set([...entityIds, ...newEntityIds])));

    setLoadingMore(false);
    event.target.complete();
    // setInfiniteDisabled(true) // Used to stop more infinite scrolling
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={hideShade ? "" : "toolbar-shade"}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">{title}</IonTitle>
          <IonButtons slot="end">
            {userService.hasRoles(["contributor"]) && EditModalComponent && (
              <IonButton
                color={"primary"}
                onClick={async () => {
                  if (EditModalComponent) {
                    modalService.openModalByComponent(EditModalComponent, {
                      options: { edit: true, select: false },
                      onSelect: () => {},
                      externalOnClose: async () => {
                        const newEntityIds =
                          await entityService.getEntitiesByType(entityType, 0);
                        setEntityIds(Array.from(new Set([...newEntityIds])));
                      },
                    });
                  }
                }}
              >
                Add
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Loading
        loading={loading}
        ContentComponent={Content}
        hideShade={hideShade}
      >
        <Content hideShade={hideShade}>
          {entityIds.map((entityId) => {
            const entityContainer = entities.get(entityId);
            return (
              <div
                key={entityContainer.entity.entityId}
                style={{ marginTop: "1em" }}
              >
                <EntityWrapper
                  entityContainer={entityContainer}
                  prominence={"primary"}
                />
                <div
                  style={{
                    paddingLeft: "1em",
                    opacity: 0.5,
                    fontSize: "0.75em",
                  }}
                >
                  {formatDate(entityContainer.entity.addDatetime)}
                </div>
              </div>
            );
          })}

          <IonInfiniteScroll
            onIonInfinite={loadMore}
            threshold="100px"
            disabled={isInfiniteDisabled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="dots"
              loadingText="Loading more"
            />
          </IonInfiniteScroll>
        </Content>
      </Loading>
    </IonPage>
  );
};

export default BrowseEntityType;
