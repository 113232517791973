import { rerender } from "../lib/rerender";

const storedValues = JSON.parse(localStorage.localSettings || "{}");

export const localSettingsStore: {
  bibleVersion: string;
} = {
  bibleVersion: "web",
};

Object.assign(localSettingsStore, storedValues);

export function setSetting(name: keyof typeof localSettingsStore, value: any) {
  localSettingsStore[name] = value;
  localStorage.localSettings = JSON.stringify(localSettingsStore);
  rerender();
}

(window as any).localSettingsStore = localSettingsStore;
