import { IonButton, IonContent, IonPage } from "@ionic/react";

import { Swiper, SwiperSlide } from "swiper/react";
import { HashNavigation, Navigation, Pagination } from "swiper";
import { RegisterForm } from "./RegisterForm";
import { userStore } from "../../store/userStore";
import { useEffect, useState } from "react";
import { LoginForm } from "./LoginForm";

export const Intro1: React.FC = () => {
  const [accountAction, setAccountAction] = useState("register");
  const [bounce, setBounce] = useState(false);
  const { user } = userStore;
  const slides: any = [];

  useEffect(() => {
    setTimeout(() => {
      setBounce(true);
    }, 4000);
  }, []);

  slides.push(
    <SwiperSlide className="slider-slide-text-image">
      <div
        className={`swiper-slide-content ${bounce ? "bounce" : ""}`}
        onDoubleClick={() => {
          window.location.href = "/";
        }}
      >
        <div className="image-text">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5em",
              paddingTop: "0.5em",
            }}
          >
            Bible Media
          </div>
          <div>A Bible that sings worship music and preaches the Word</div>
        </div>
        <div className="image-wrapper">
          <div className="image">
            <img src="./assets/intro/home.jpg" alt="bible view" />
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  if (user.loggedIn) {
    slides.push(
      <SwiperSlide data-hash={`register`}>
        <div className="swiper-slide-content">
          <h3>You're logged in!</h3>
          <br />
          <br />
          <IonButton
            onClick={() => {
              window.location.href = "/intro/3";
            }}
          >
            Continue
          </IonButton>
        </div>
      </SwiperSlide>
    );
  } else {
    let actionForm: any = null;

    if (accountAction === "register") {
      actionForm = (
        <div>
          Create your account to get started
          <RegisterForm
            onSuccess={() => {
              // Replace the URL path and start the history over
              // window.location.href = "/intro/2";
              window.location.href = "/intro/3";
            }}
          />
        </div>
      );
    } else {
      actionForm = (
        <div>
          Login to your account
          <LoginForm
            onSuccess={() => {
              // Nothing to do here. The user will click continue
            }}
          />
        </div>
      );
    }

    slides.push(
      <SwiperSlide data-hash={`register`}>
        <div className="swiper-slide-content">
          {actionForm}
          {accountAction === "register" && (
            <IonButton fill="clear" onClick={() => setAccountAction("login")}>
              Login
            </IonButton>
          )}
          {accountAction === "login" && (
            <IonButton
              fill="clear"
              onClick={() => setAccountAction("register")}
            >
              Register
            </IonButton>
          )}
          <div>
            <IonButton
              fill="clear"
              onClick={() => (window.location.href = "/intro/3")}
            >
              Skip
            </IonButton>
          </div>
        </div>
      </SwiperSlide>
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <Swiper
          pagination={true}
          hashNavigation={{
            watchState: true,
          }}
          // navigation={true} // This shows left/right arrows, maybe only do on desktop
          modules={[Pagination, Navigation, HashNavigation]}
        >
          {slides}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
