import { rerender } from "../lib/rerender";

export const entities = new Map<string, any>();

export function updateEntityContainers(containersToUpdate: any[]) {
  containersToUpdate.forEach((container) => {
    if (!container.populated && entities.get(container.entity.entityId)) {
      // Don't overwite entities that already exist, when the update
      // is not populated as the update might be removing data
      return;
    }

    // These are used to track the changes for visual purposes
    container.associationAdditions = [];
    container.associationDeletions = [];

    entities.set(container.entity.entityId, container);
  });

  rerender();
}

(window as any).entities = entities;
