import { EditSpeakerModal } from "../../components/modals/EditSpeakerModal";
import BrowseEntityType from "../BrowseEntityType";

const Speakers: React.FC = () => {
  return (
    <BrowseEntityType
      title="Speakers"
      entityType="speaker"
      EditModalComponent={EditSpeakerModal}
    />
  );
};

export default Speakers;
