import { IonCardTitle, IonChip } from "@ionic/react";
import { pricetagOutline } from "ionicons/icons";
import { IconText } from "./common/IconText";

export function DisplayThemeEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;

  if (prominence === "primary" || prominence === "hero") {
    return (
      <IconText icon={pricetagOutline}>
        <IonCardTitle>{entityData.name}</IonCardTitle>
      </IconText>
    );
  }

  if (prominence === "secondary") {
    return (
      <IonChip
        style={{
          background: "var(--theme)",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {entityData.name}
      </IonChip>
    );
  }

  return null;
}
