import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { bookmark as bookmarkFilled, bookmarkOutline } from "ionicons/icons";
import { useState } from "react";
import { Loading } from "../components/Loading";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";

const tabs = ["Songs", "Sermons"];

const Saved: React.FC = () => {
  const [entityBookmarks, setEntityBookmarks] = useState<any>([]);
  // const [verseBookmarks, setVerseBookmarks] = useState<any>([]);
  const [tab, setTab] = useState("Songs");
  const router = useIonRouter();

  async function getBookmarks() {
    const bookmarks = await userService.getBookmarks();

    bookmarks.entityBookmarks.forEach((bookmark: any) => {
      bookmark.bookmarked = true;
    });

    setEntityBookmarks(bookmarks.entityBookmarks);
    // setVerseBookmarks(bookmarks.verseBookmarks);
  }

  const [loading] = useWillEnterWithLoading(async () => {
    getBookmarks();
  }, 400);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Saved</IonTitle>
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading}>
        <IonContent fullscreen>
          <IonSegment value={tab}>
            {tabs.map((tab) => {
              return (
                <IonSegmentButton
                  key={tab}
                  value={tab}
                  onClick={() => setTab(tab)}
                >
                  <IonLabel>{tab}</IonLabel>
                </IonSegmentButton>
              );
            })}
          </IonSegment>
          <IonList style={{ padding: "1em" }}>
            {tab === "Songs" && (
              <EntityBookmarks
                entityBookmarks={entityBookmarks.filter(
                  (bookmark: any) =>
                    bookmark.container?.entity.entityType === "song"
                )}
                setEntityBookmarks={setEntityBookmarks}
                router={router}
              />
            )}
            {tab === "Sermons" && (
              <EntityBookmarks
                entityBookmarks={entityBookmarks.filter(
                  (bookmark: any) =>
                    bookmark.container?.entity.entityType === "sermon"
                )}
                setEntityBookmarks={setEntityBookmarks}
                router={router}
              />
            )}
            {/* {tab === "Verses" && (
              <VerseBookmarks
                verseBookmarks={verseBookmarks}
                setVerseBookmarks={setVerseBookmarks}
                router={router}
              />
            )} */}
          </IonList>
        </IonContent>
      </Loading>
    </IonPage>
  );
};

function EntityBookmarks({ entityBookmarks, setEntityBookmarks, router }: any) {
  return entityBookmarks.map((bookmark: any) => {
    if (!bookmark.container) {
      return null;
    }

    const { entity } = bookmark.container;

    return (
      <IonItem
        button
        key={bookmark.container.entity.entityId}
        type="button"
        onClick={() => {
          router.push(`/entity/${entity.entityId}`);
        }}
      >
        {entity.entityName}
        <IonButton
          slot="end"
          fill="clear"
          onClick={async (event) => {
            event.stopPropagation();

            if (bookmark.bookmarked) {
              await userService.unbookmarkEntity(entity.entityId);
              bookmark.bookmarked = false;
              setEntityBookmarks([...entityBookmarks]);
            } else {
              await userService.bookmarkEntity(entity.entityId);
              // Don't refetch subscriptions
              // Update the state of the subscription to allow resubscribing, in case the unsubscription was an accident
              bookmark.bookmarked = true;
              setEntityBookmarks([...entityBookmarks]);
            }
          }}
        >
          <IonIcon
            icon={bookmark.bookmarked ? bookmarkFilled : bookmarkOutline}
          />
        </IonButton>
      </IonItem>
    );
  });
}

// function VerseBookmarks({ verseBookmarks, setVerseBookmarks, router }: any) {
//   return verseBookmarks.map((bookmark: any) => {
//     return (
//       <IonItem
//         button
//         key={bookmark.specialId}
//         type="button"
//         onClick={() => {
//           router.push(`/verse/${bookmark.specialId}`);
//         }}
//       >
//         {bookmark.name}
//         <IonButton
//           slot="end"
//           fill="clear"
//           onClick={async (event) => {
//             event.stopPropagation();

//             if (bookmark.bookmarked) {
//               await userService.unbookmarkVerse(bookmark.specialId);
//               bookmark.bookmarked = false;
//               setVerseBookmarks([...verseBookmarks]);
//             } else {
//               await userService.bookmarkVerse(bookmark.specialId);
//               // Don't refetch subscriptions
//               // Update the state of the subscription to allow resubscribing, in case the unsubscription was an accident
//               bookmark.bookmarked = true;
//               setVerseBookmarks([...verseBookmarks]);
//             }
//           }}
//         >
//           <IonIcon
//             icon={bookmark.bookmarked ? bookmarkFilled : bookmarkOutline}
//           />
//         </IonButton>
//       </IonItem>
//     );
//   });
// }

export default Saved;
