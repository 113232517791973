import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { entityService } from "../../lib/entityService";
import { modalService } from "../../lib/modalService";
import { entities } from "../../store/entities";
import { Card } from "../Card";
import { BaseEditModalProps } from "./util";

type Props = {
  entityType: string;
  children: any;
  entityData?: any;
  isValid?: () => boolean;
  onStartNew: (initialValue: string) => void;
  externalOnClose?: () => Promise<void>;
  setEntityContainer: (value: any) => void;
} & BaseEditModalProps;

export function EditEntityModal({
  children,
  entityType,
  onSelect,
  onSearch,
  onStartNew,
  options,
  targetEntityContainer,
  isValid,
  id,
  externalOnClose,
  setEntityContainer,
}: Props) {
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [changed, setChanged] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [adding, setAdding] = useState(!!targetEntityContainer.entity.entityId);
  const [saving, setSaving] = useState(false);

  // Allow the animation to occur
  async function onClose() {
    setTimeout(() => {
      modalService.closeModal(id);
    }, 1000);

    setIsOpen(false);

    if (externalOnClose) {
      await externalOnClose();
    }
  }

  async function onSave() {
    setSaving(true);
    const newEntityContainer = await entityService.saveEntity(
      targetEntityContainer
    );
    setSaving(false);

    setChanged(false);
    setEntityContainer(entities.get(newEntityContainer.entity.entityId));

    return newEntityContainer;
  }

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={async () => {
        onClose();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle className="toolbar-center">Edit {entityType}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={onClose} fill="solid">
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <div style={{ overflowY: "auto", paddingBottom: "10em" }}>
        {!adding && !targetEntityContainer.entity.entityId && (
          <Card>
            <IonLabel>Search Existing Entries</IonLabel>
            <IonInput
              value={searchString}
              onIonChange={async (event) => {
                const value = event.target.value as string;
                setSearchString(value);

                if (value.length > 0) {
                  const results = await onSearch(value);
                  setSearchResults(results);
                } else {
                  setSearchResults([]);
                }
              }}
              placeholder={`Search ${entityType} Name`}
            />
            <IonList style={{ maxHeight: "15em", overflowY: "auto" }}>
              {searchResults.map((entityContainer) => {
                return (
                  <IonItem
                    key={entityContainer.entity.entityId}
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      onSelect(entityContainer);
                    }}
                  >
                    <IonLabel>{entityContainer.entity.entityName}</IonLabel>
                  </IonItem>
                );
              })}
              {searchString.length > 0 && (
                <IonItem
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();

                    onStartNew(searchString);

                    setAdding(true);
                    setSearchResults([]);
                    setSearchString("");
                  }}
                >
                  Create new {entityType} "{searchString}"
                </IonItem>
              )}
            </IonList>
          </Card>
        )}

        {adding && (
          <>
            <Card>{children({ setChanged })}</Card>

            <IonCard
              style={{
                position: "fixed",
                zIndex: 3,
                bottom: "0em",
                left: "0em",
                right: "0em",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
              }}
            >
              <IonCardContent style={{ background: "white" }}>
                {targetEntityContainer.entity.entityId && (
                  <>
                    {options.edit && (
                      <>
                        {!saving && (
                          <IonButton
                            onClick={onSave}
                            color={changed ? "warning" : undefined}
                            style={{ width: "100%" }}
                          >
                            Save Changes
                          </IonButton>
                        )}{" "}
                        {saving && (
                          <IonButton disabled={true}>
                            <IonSpinner name="dots" />
                          </IonButton>
                        )}
                      </>
                    )}
                    {options.select && (
                      <IonButton
                        onClick={() => onSelect(targetEntityContainer)}
                      >
                        Select
                      </IonButton>
                    )}
                  </>
                )}

                {!targetEntityContainer.entity.entityId && (
                  <>
                    {options.edit && (
                      <>
                        <IonButton
                          onClick={async () => {
                            if (isValid) {
                              if (!isValid()) {
                                setFormInvalid(true);
                                return;
                              }
                            }

                            const newEntityData = await onSave();
                            onSelect(newEntityData);

                            if (externalOnClose) {
                              externalOnClose();
                            }
                          }}
                        >
                          Create
                        </IonButton>
                      </>
                    )}
                  </>
                )}

                {formInvalid && (
                  <div>
                    <IonBadge color="danger">
                      Must fill out all required fields
                    </IonBadge>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </>
        )}
      </div>
    </IonModal>
  );
}
