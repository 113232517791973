import {
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { Card } from "../components/Card";
import { EntityGrouping } from "../components/EntityGrouping";
import { Loading } from "../components/Loading";
import { entityService } from "../lib/entityService";
import { getEntities } from "../lib/getEntities";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";
import {
  bookmark,
  bookmarkOutline,
  chevronBack,
  chevronForward,
  shareOutline,
} from "ionicons/icons";
import { userService } from "../lib/userService";
import { useParams } from "react-router";
import { systemStore } from "../store/systemStore";
import { shareService } from "../lib/shareService";
import { BookChapterVerseSelector } from "../components/BookChapterVerseSelector";

interface Props {
  verseId?: string;
}

const Verse: React.FC<Props> = (props) => {
  const { verseId } = useParams<{ verseId: string }>();
  const verseIdParts = verseId?.split(":") || [];
  const bookNumber = Number(verseIdParts[0]);
  const chapterNumber = Number(verseIdParts[1]);
  const verseNumber = Number(verseIdParts[2]);
  const [verseData, setVerseData] = useState<any>(null);
  const [sharing, setSharing] = useState<any>(false);
  const router = useIonRouter();

  async function getVerseData(verseId: string) {
    const result = await entityService.getVerse(verseId);
    setVerseData(result);
  }

  // Loading handler
  const [loading] = useWillEnterWithLoading(
    async () => {
      if (verseId) {
        getVerseData(verseId);
      }
    },
    400,
    [verseId]
  );

  let verseDisplay = null;

  if (verseData) {
    const verses = getEntities(verseData.associatedEntities, "verse", true);
    const themes = getEntities(verseData.associatedEntities, "theme", true);
    const questions = getEntities(
      verseData.associatedEntities,
      "question",
      true
    );
    const songs = getEntities(verseData.associatedEntities, "song", true);
    const sermons = getEntities(verseData.associatedEntities, "sermon", true);
    const genres = getEntities(verseData.associatedEntities, "genre", true);

    verseDisplay = (
      <>
        <EntityGrouping title="Genres" entityIds={genres} />
        <EntityGrouping title="Verses" entityIds={verses} />
        <EntityGrouping title="Themes" entityIds={themes} />
        <EntityGrouping title="Questions" entityIds={questions} />
        <EntityGrouping title="Sermons" entityIds={sermons} />
        <EntityGrouping title="Songs" entityIds={songs} />
      </>
    );
  }

  function setNewPageId(book: number, chapter: number, verse: number) {
    const newVerseId = `${book}:${chapter}:${verse}`;

    // TODO: why is this necessary...
    if (newVerseId !== verseId) {
      router.push(`/verse/${newVerseId}`);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <div className="toolbar-center">
            <BookChapterVerseSelector
              mode="verse"
              // canPrevious={canPrevious}
              // canNext={canNext}
              book={bookNumber - 1}
              chapter={chapterNumber - 1}
              verse={verseNumber - 1}
              onBookChange={(newBookIndex) => {
                setNewPageId(newBookIndex + 1, 1, 1);
              }}
              onChapterChange={(newChapterIndex) => {
                setNewPageId(bookNumber, newChapterIndex + 1, 1);
              }}
              onVerseChange={(newVerseIndex) => {
                setNewPageId(bookNumber, chapterNumber, newVerseIndex + 1);
              }}
              onVersionChange={() => {
                getVerseData(`${bookNumber}:${chapterNumber}:${verseNumber}`);
              }}
            />
          </div>

          <IonButtons slot="end">
            {verseData && (
              <IonButton
                fill="clear"
                onClick={async () => {
                  if (!userService.isLoggedIn()) {
                    userService.promptRegister(router);
                    return;
                  }

                  let success = true;

                  if (verseData.bookmarked) {
                    await userService.unbookmarkVerse(verseData.specialId);
                  } else {
                    const result = await userService.bookmarkVerse(
                      verseData.specialId
                    );

                    if (result.error) {
                      success = false;
                    }
                  }

                  if (success) {
                    setVerseData({
                      ...verseData,
                      bookmarked: !verseData.bookmarked,
                    });
                  }
                }}
              >
                <IonIcon
                  slot="icon-only"
                  icon={verseData.bookmarked ? bookmark : bookmarkOutline}
                />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading}>
        <IonContent fullscreen>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {verseData?.previous && (
              <div
                onClick={() => {
                  router.push(`/verse/${verseData.previous[0]}`);
                }}
              >
                <IonIcon
                  icon={chevronBack}
                  style={{ fontSize: "2em", padding: "0.25em" }}
                />
              </div>
            )}
            {!verseData?.previous && (
              <div style={{ fontSize: "2em", padding: "0.25em" }}>&nbsp;</div>
            )}

            {verseData && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Card style={{ flexGrow: 1 }}>
                  <IonCardSubtitle>{verseData?.verseName}</IonCardSubtitle>
                  <h1 style={{ color: "var(--dark)" }}>{verseData.text}</h1>
                </Card>
                {systemStore.canShare && (
                  <IonButton
                    style={{ marginBottom: "1em" }}
                    onClick={async () => {
                      if (sharing) {
                        return;
                      }

                      setSharing(true);

                      try {
                        await shareService.shareVerse(verseData);
                      } catch (err) {
                        console.log("Share error", err);
                      }

                      setSharing(false);
                    }}
                  >
                    {!sharing && (
                      <>
                        <IonIcon icon={shareOutline} slot="start" /> Share
                      </>
                    )}
                    {sharing && (
                      <>
                        <IonSpinner name="dots" />
                      </>
                    )}
                  </IonButton>
                )}
              </div>
            )}

            {verseData?.next && (
              <div
                onClick={() => {
                  if (verseData.next) {
                    router.push(`/verse/${verseData.next[0]}`);
                  }
                }}
              >
                <IonIcon
                  icon={chevronForward}
                  style={{ fontSize: "2em", padding: "0.25em" }}
                />
              </div>
            )}
            {!verseData?.next && (
              <div style={{ fontSize: "2em", padding: "0.25em" }}>&nbsp;</div>
            )}
          </div>
          {verseDisplay}
        </IonContent>
      </Loading>
    </IonPage>
  );
};

export default Verse;
