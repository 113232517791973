import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { Card } from "../components/Card";
import { LabeledInput } from "../components/LabeledInput";
import { ValidatedInput } from "../components/ValidatedInput";
import { presentToast } from "../lib/toast";
import { userService } from "../lib/userService";

export const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [error, setError] = useState("");
  const router = useIonRouter();

  async function resetPassword() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const forgotPasswordToken = urlParams.get("token");
    const email = urlParams.get("email");

    if (email && forgotPasswordToken) {
      const result = await userService.resetPassword(
        email,
        forgotPasswordToken,
        password
      );

      if (result.error) {
        setError(result.error);
      } else {
        presentToast({
          message: `Password reset, you may now use it to login!`,
          duration: 2000,
          position: "bottom",
          color: "success",
        });

        router.push("/login");
      }
    }
  }

  const passwordsMatch = password === passwordAgain;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Reset Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Card>
          <LabeledInput
            type="password"
            label="Password"
            helperText="Enter your new password"
            value={password}
            setValue={setPassword}
          />

          <ValidatedInput
            type="password"
            label="Password again"
            value={passwordAgain}
            helperText="Enter your new password again"
            errorText="Passwords do not match"
            validate={(value) => password === value}
            validationMode="onChange"
            setValue={setPasswordAgain}
          />

          {passwordsMatch && (
            <IonButton
              onClick={async () => {
                resetPassword();
              }}
            >
              Reset
            </IonButton>
          )}

          {!!error && (
            <div>
              <IonLabel color="danger">{error}</IonLabel>
            </div>
          )}
        </Card>
      </IonContent>
    </IonPage>
  );
};
