import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import { EditEntitySection } from "../../components/admin/EditEntitySection";
import { AdminQuestionEntity } from "../../components/admin/AdminQuestionEntity";
import { AdminSermonEntity } from "../../components/admin/AdminSermonEntity";
import { AdminSongEntity } from "../../components/admin/AdminSongEntity";
import { AdminThemeEntity } from "../../components/admin/AdminThemeEntity";
import { EditQuestionModal } from "../../components/modals/EditQuestionModal";
import { EditSermonModal } from "../../components/modals/EditSermonModal";
import { EditSongModal } from "../../components/modals/EditSongModal";
import { EditThemeModal } from "../../components/modals/EditThemeModal";
import { verseRangeRegex } from "../../data/books";
import { entityService } from "../../lib/entityService";
import { getEntities } from "../../lib/getEntities";
import { presentToast } from "../../lib/toast";
import { entities } from "../../store/entities";
import { userStore } from "../../store/userStore";

const Workshop: React.FC = () => {
  // const { name } = useParams<{ name: string }>();
  // const [bookNumber, setBookNumber] = useState(1);
  const [bookName, setBookName] = useState({ name: "Genesis 1:1" });
  const bookInput = useRef<any>(null);
  const [verseText, setVerseText] = useState([]);
  const [verseName, setVerseName] = useState("");
  const [verseContainerEntityId, setVerseContainerEntityId] =
    useState<any>(null);
  const [changed, setChanged] = useState(false);

  const verseContainer = entities.get(verseContainerEntityId);

  let themes = [];
  let questions = [];
  let sermons = [];
  let songs = [];

  if (verseContainer) {
    themes = getEntities(verseContainer.associatedEntities, "theme");
    questions = getEntities(verseContainer.associatedEntities, "question");
    sermons = getEntities(verseContainer.associatedEntities, "sermon");
    songs = getEntities(verseContainer.associatedEntities, "song");
  }

  async function saveChanges() {
    // The entityName is set when the verse is loaded

    const result = await entityService.saveEntity(verseContainer);

    // On save if this is a new verse, it will have a new entityId
    // NOTE: A verse entity needs an entityId in order to work correctly,
    // even if it doesn't actually exist yet
    setVerseContainerEntityId(result.entity.entityId);
    setChanged(false);

    presentToast({
      message: `Saved!`,
      duration: 2000,
      position: "bottom",
      color: "success",
    });
  }

  async function findVerse() {
    const result = await entityService.adminGetVerse(displayedBookName);

    setVerseText(result.text);
    setVerseName(result.verseName);
    setVerseContainerEntityId(result.entityContainer.entity.entityId);
  }

  const entitySectionProps = {
    parent: verseContainer,
    onChange: () => {
      // Must change state so that after the save we don't keep old references
      setChanged(true);

      if (userStore.user.userData.settings.autoSaveEntityChanges) {
        saveChanges();
      } else {
        setChanged(true);
      }
    },
  };

  let saveButton = null;

  if (changed) {
    saveButton = (
      <IonCard
        style={{
          position: "fixed",
          bottom: "0em",
          left: "0em",
          right: "0em",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
      >
        <IonCardContent>
          <IonButton
            style={{ width: "100%" }}
            onClick={saveChanges}
            color="warning"
          >
            Save Changes
          </IonButton>
        </IonCardContent>
      </IonCard>
    );
  }

  let displayedBookName = bookName.name;

  const verseLocatorValid = bookName.name.match(verseRangeRegex);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Workshop</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonCard>
          <IonCardContent>
            <IonCardTitle>Find verse</IonCardTitle>
            <IonInput
              ref={bookInput}
              value={displayedBookName}
              onIonChange={(event) => {
                let name = event.target.value as string;

                setBookName({ name });
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  findVerse();
                }
              }}
            />
            {!verseLocatorValid && <div>Not a valid verse locator</div>}

            {verseLocatorValid && (
              <div style={{ textAlign: "right" }}>
                <IonButton
                  onClick={async () => {
                    findVerse();
                  }}
                >
                  Find
                </IonButton>
              </div>
            )}
          </IonCardContent>
        </IonCard>

        {verseContainer && (
          <>
            <IonCard>
              <IonCardContent>
                <IonCardTitle>{verseName}</IonCardTitle>
                <br />
                {verseText.slice(0, 3).map((text, index) => {
                  return (
                    <h1 key={index}>
                      {text}
                      <br />
                      <br />
                    </h1>
                  );
                })}
                {verseText.length > 6 && (
                  <div>
                    ... {verseText.length - 6} more ...
                    <br />
                    <br />
                  </div>
                )}
                {verseText.length > 3 &&
                  verseText
                    .slice(verseText.length - Math.min(3, verseText.length - 3))
                    .map((text, index) => {
                      return (
                        <h1 key={index}>
                          {text}
                          <br />
                          <br />
                        </h1>
                      );
                    })}
              </IonCardContent>
            </IonCard>

            {/* THEMES */}
            <EditEntitySection
              id="themes"
              title="Themes"
              entityIds={themes}
              EntityComponent={AdminThemeEntity}
              EditModalComponent={EditThemeModal}
              {...entitySectionProps}
            />

            {/* QUESTIONS */}
            <EditEntitySection
              id="questions"
              title="Questions"
              entityIds={questions}
              EntityComponent={AdminQuestionEntity}
              EditModalComponent={EditQuestionModal}
              {...entitySectionProps}
            />

            <EditEntitySection
              id="sermons"
              title="Sermons"
              entityIds={sermons}
              EntityComponent={AdminSermonEntity}
              EditModalComponent={EditSermonModal}
              {...entitySectionProps}
            />

            <EditEntitySection
              id="songs"
              title="Songs"
              entityIds={songs}
              EntityComponent={AdminSongEntity}
              EditModalComponent={EditSongModal}
              {...entitySectionProps}
            />
          </>
        )}

        {verseContainer && saveButton}
        <div style={{ height: "100px" }}></div>
      </IonContent>
    </IonPage>
  );
};

export default Workshop;
