import { AlertOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";

let presentToastFunction: null | ((value: any) => void) = null;
let presentAlertFunction: null | ((value: any) => void) = null;

export function setPresentToastFunction(fn: any) {
  presentToastFunction = fn;
}

export function setPresentAlertFunction(fn: any) {
  presentAlertFunction = fn;
}

export function presentToast(
  options: HookOverlayOptions & {
    message?: string;
    duration?: number;
    position?: string;
    color?: string;
  }
) {
  if (presentToastFunction) {
    presentToastFunction(options);
  }
}

export function presentAlert(options: AlertOptions & HookOverlayOptions) {
  if (presentAlertFunction) {
    presentAlertFunction(options);
  }
}
