import { IonButton, IonContent, IonPage, useIonRouter } from "@ionic/react";
import { userService } from "../../lib/userService";
import { systemStore } from "../../store/systemStore";

export const Intro3: React.FC = () => {
  const router = useIonRouter();
  const available = systemStore.pushNotificationsAvailable;
  const registered = systemStore.pushNotificationsRegistered;

  function finish() {
    // DON'T USE THIS METHOD
    // userService.endIntro();

    // Need to do a custom redirect so that the notification registration
    // process has time to complete
    localStorage.completedIntro = 1;
    localStorage.completedIntroTimestamp = Date.now();

    router.push("/");
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "20em" }}>
            <div>
              <h3>Congratulations! You're in!</h3>
            </div>

            {available && !registered && (
              <div style={{ marginTop: "2em" }}>
                <IonButton
                  onClick={async () => {
                    const forcePrompt = true;

                    userService.promptNotifications(() => {
                      finish();
                    }, forcePrompt);
                  }}
                >
                  Enable Notifications
                </IonButton>
                <br />
                By turning on notifications we will be able to send you the
                verse of the day every morning.
              </div>
            )}

            {registered && (
              <div style={{ marginTop: "2em" }}>Push notifications enabled</div>
            )}

            <div style={{ marginTop: "2em" }}>
              <IonButton
                fill={available && !registered ? "clear" : "solid"}
                onClick={() => finish()}
              >
                Take me to Bible Media
              </IonButton>
            </div>
            {/* <div
              style={{
                position: "absolute",
                bottom: "0em",
                height: "2em",
                left: "0em",
                right: "0em",
                textAlign: "center",
              }}
              onClick={async () => {
                setDeviceId((await Device.getId()).uuid);
              }}
            >
              {deviceId}
            </div> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
