import { IonButton, IonLabel, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { Card } from "../../components/Card";
import { presentToast } from "../../lib/toast";
import { userService } from "../../lib/userService";
import isEmail from "validator/lib/isEmail";
import { ValidatedInput } from "../../components/ValidatedInput";
import { LabeledInput } from "../../components/LabeledInput";

interface Props {
  onSuccess: () => void;
}

export const LoginForm: React.FC<Props> = ({ onSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const router = useIonRouter();

  return (
    <Card>
      <ValidatedInput
        type="email"
        label="Email"
        value={email}
        setValue={setEmail}
        validate={(value) => (value ? isEmail(value) : undefined)}
        helperText="Enter your email"
        errorText="Invalid email"
      />
      <LabeledInput
        type="password"
        label="Password"
        value={password}
        setValue={setPassword}
      />

      <div style={{ marginTop: "1em" }}>
        <IonButton
          style={{ width: "100%", maxWidth: "20em" }}
          onClick={async () => {
            const result = await userService.login(email, password);

            if (result.error) {
              setError(result.error);
            } else {
              presentToast({
                message: `Logged in!`,
                duration: 2000,
                position: "bottom",
                color: "success",
              });

              onSuccess();
            }
          }}
        >
          Login
        </IonButton>

        <IonButton
          fill="clear"
          style={{ width: "100%", maxWidth: "20em" }}
          onClick={async () => {
            router.push("/forgotPassword");
          }}
        >
          Forgot Password
        </IonButton>
      </div>

      {!!error && (
        <div>
          <IonLabel color="danger">{error}</IonLabel>
        </div>
      )}
    </Card>
  );
};
