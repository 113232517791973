import { useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";

export function useWillEnterWithLoading(
  callback: any,
  extraTimeout = 0,
  dependencies: any[] = []
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [loading, setLoading] = useState(true);

  useIonViewWillEnter(() => {
    (async function () {
      setLoading(true);

      await callback();

      setTimeout(() => {
        setLoading(false);
      }, extraTimeout);
    })();
  }, dependencies);

  return [loading, setLoading];
}
