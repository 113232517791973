import { IonInput, IonLabel } from "@ionic/react";
import { useState } from "react";
import { createEmptyEntityContainer } from "../../lib/createEmptyEntityContainer";
import { entityService } from "../../lib/entityService";
import { modalService } from "../../lib/modalService";
import { entities } from "../../store/entities";
import { EditEntityModal } from "./EditEntityModal";
import { BaseEditModalProps } from "./util";

type Props = {} & BaseEditModalProps;

const entityType = "speaker";

export function EditSpeakerModal(props: Props) {
  const [entityContainer, setEntityContainer] = useState<any>(
    entities.get(props.entityId) || createEmptyEntityContainer(entityType)
  );

  return (
    <EditEntityModal
      {...props}
      entityType={"Speaker"}
      targetEntityContainer={entityContainer}
      onSelect={(entityContainer: any) => {
        props.onSelect(entityContainer);
        modalService.closeModal(props.id);
      }}
      onSearch={(searchString: string) => {
        return entityService.findEntitiesOfType(searchString, entityType);
      }}
      isValid={() => {
        return !!entityContainer.entity.entityData.name;
      }}
      onStartNew={(initialValue: string) => {
        entityContainer.entity.entityName = initialValue;
        entityContainer.entity.entityData.name = initialValue;
      }}
      setEntityContainer={setEntityContainer}
    >
      {({ setChanged }: any) => {
        return (
          <>
            <IonLabel>Name</IonLabel>
            <IonInput
              value={entityContainer.entity.entityData.name}
              onIonChange={(event) => {
                entityContainer.entity.entityName = event.target.value;
                entityContainer.entity.entityData.name = event.target.value;

                setEntityContainer({ ...entityContainer });
                setChanged(true);
              }}
            />

            <IonLabel>Church</IonLabel>
            <IonInput
              value={entityContainer.entity.entityData.churchName}
              onIonChange={(event) => {
                entityContainer.entity.entityData.churchName =
                  event.target.value;

                setEntityContainer({ ...entityContainer });

                setChanged(true);
              }}
            />
          </>
        );
      }}
    </EditEntityModal>
  );
}
