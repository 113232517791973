import { updateEntityContainers } from "../store/entities";
import { makeRequest } from "./api";
import LRU from "lru-cache";

const entityRequestCache = new LRU<string, any>({
  ttl: 3600 * 1000, // 1 hour
  max: 1000,
});

export const entityService = {
  async getVerse(specialId: string) {
    const { token } = localStorage;
    const result = (await makeRequest("getVerse", { specialId, token })).data;

    updateEntityContainers(result.containersToUpdate);

    return result;
  },

  async getVerseOfTheDay() {
    // let date = new Date();
    // const hourOffset = date.getTimezoneOffset() / 60;
    // date = new Date(date.getTime() - hourOffset * 60 * 60 * 1000);

    const result = (await makeRequest("getVerseOfTheDay", {})).data;

    updateEntityContainers(result.containersToUpdate);

    return result;
  },

  async adminGetVerse(verseRange: string) {
    const result = (await makeRequest("admin.getVerse", { verseRange })).data;

    updateEntityContainers([result.entityContainer]);
    updateEntityContainers(result.containersToUpdate);

    return result;
  },

  async saveEntity(entityContainer: any) {
    let { entity, associationDeletions, associationAdditions } =
      entityContainer;

    // Filter out anything that was deleted
    // associatedEntities = associatedEntities.filter((entityId: string) => {
    //   return !associationDeletions.includes(entityId);
    // });

    // Note: don't need to worry about additions, they've already been added to the array

    const result = (
      await makeRequest("admin.saveEntity", {
        token: localStorage.token,
        entity,
        associationDeletions,
        associationAdditions,
      })
    ).data;

    updateEntityContainers([result.entityContainer]);
    updateEntityContainers(result.containersToUpdate);

    return result.entityContainer;
  },

  async findEntitiesOfType(searchString: string, entityType: string) {
    const result = (
      await makeRequest("getAllEntities", { searchString, entityType })
    ).data;

    // Don't update the entities from this request
    // updateEntities(result);

    return result;
  },

  async getEntity(
    entityId: string,
    options: {
      skipUpdates?: string[];
      includePending?: boolean;
      useCache?: boolean;
    } = {}
  ) {
    const { skipUpdates = [], includePending, useCache = false } = options;
    const { token } = localStorage;

    // Use the cache when appropriate
    if (useCache && entityRequestCache.has(entityId)) {
      return entityRequestCache.get(entityId);
    }

    const result = (
      await makeRequest("getEntity", { entityId, includePending, token })
    ).data;

    updateEntityContainers([result.container]);
    updateEntityContainers(
      result.containersToUpdate.filter((container: any) => {
        // Only update entities not in the skipUpdates list
        return !skipUpdates?.includes(container.entity.entityId);
      })
    );

    // Save to cache regardless
    entityRequestCache.set(entityId, result);

    return result;
  },

  async getEntitiesByType(
    entityType: string = "",
    page: number,
    pageSize = 20
  ) {
    const result = (
      await makeRequest("getEntities", { entityType, page, pageSize })
    ).data;

    // Do update the entities here
    updateEntityContainers(result.containers);

    return result.containers.map((container: any) => container.entity.entityId);
  },

  async getChapterVerses(book: number, chapter: number) {
    const result = (
      await makeRequest("getChapterEntities", {
        book,
        chapter,
      })
    ).data;

    // No entities, just specialized verse data

    return result.verses;
  },

  async search(searchString: string, page: number) {
    const result = (await makeRequest("search", { searchString, page })).data;

    // Do update the entities here
    updateEntityContainers(result.containers);

    return result.containers.map((container: any) => container.entity.entityId);
  },

  async getTopEntities() {
    const { token } = localStorage;
    const result = (await makeRequest("getTopEntities", { token })).data;

    return result;
  },
};
