import { Device } from "@capacitor/device";
import { entities, updateEntityContainers } from "../store/entities";
import { userStore } from "../store/userStore";
import { makeRequest } from "./api";
import { pushNotificationService } from "./pushNotificationsService";
import { rerender } from "./rerender";
import { presentAlert } from "./toast";

export const userService = {
  isLoggedIn() {
    return userStore.user.loggedIn;
  },

  hasRoles(requiredRoles: string[] | string[][]) {
    if (!userStore?.user?.userData?.roles) {
      return false;
    }

    const { roles } = userStore.user.userData;
    let foundMatch = false;

    if (typeof requiredRoles[0] === "string") {
      requiredRoles = [requiredRoles as string[]];
    }

    (requiredRoles as string[][]).forEach((roleSet) => {
      if (foundMatch) {
        return;
      }

      foundMatch = true;

      roleSet.forEach((role) => {
        foundMatch = foundMatch && roles.includes(role);
      });
    });

    return foundMatch;
  },

  async confirmLogin(options: { refreshToken?: boolean } = {}) {
    const { token } = localStorage;

    if (token) {
      const deviceId = (await Device.getId()).uuid;

      const result = (await makeRequest("decodeUser", { token, deviceId }))
        .data;

      if (!result.error) {
        userStore.user = {
          ...result.user,
          loggedIn: true,
        };

        rerender();

        return true;
      }
    }

    userStore.user = {
      loggedIn: false,
      userId: "",
      email: "",
      userData: {
        roles: [],
        settings: {},
        emailVerified: false,
        publicData: {
          name: "",
        },
      },
    };

    rerender();

    return false;
  },

  async register(email: string, password: string, name: string) {
    const deviceId = (await Device.getId()).uuid;
    const result = (
      await makeRequest("register", { email, password, name, deviceId })
    ).data;

    if (!result.error) {
      localStorage.token = result.token;

      await this.confirmLogin();
    }

    return result;
  },

  async registerDevice() {
    const deviceId = (await Device.getId()).uuid;

    const result = (await makeRequest("registerDevice", { deviceId })).data;

    return result;
  },

  async login(email: string, password: string) {
    const result = (await makeRequest("login", { email, password })).data;

    if (!result.error) {
      localStorage.token = result.token;

      await this.confirmLogin();
    }

    return result;
  },

  async logout() {
    delete localStorage.token;
    window.location.href = "/";
  },

  async deleteAccount() {
    const { token } = localStorage;
    await makeRequest("user.deleteAccount", { token });

    userService.logout();
  },

  async getUser() {
    const { token } = localStorage;

    const result = await makeRequest("user.getUser", { token });

    if (result.data) {
      const user = {
        ...result.data.user,
        loggedIn: true,
      };

      userStore.user = user;

      return user;
    }

    userStore.user = {
      loggedIn: false,
      userId: "",
      email: "",
      userData: {
        roles: [],
        settings: {},
        emailVerified: false,
        publicData: {
          name: "",
        },
      },
    };

    return userStore.user;
  },

  async setSetting(settingId: string, value: string | number | boolean) {
    const { token } = localStorage;
    const result = (
      await makeRequest("setUserSetting", { settingId, value, token })
    ).data;

    if (!result.error) {
      userStore.user.userData.settings = result;
      rerender();
    }
  },

  async setNotificationSetting(settingId: string, value: number) {
    const { token } = localStorage;

    await makeRequest("user.setNotificationSetting", {
      settingId,
      value,
      token,
    });
  },

  async getNotificationSettings() {
    const { token } = localStorage;

    return (
      await makeRequest("user.getNotificationSettings", {
        token,
      })
    ).data;
  },

  saveLastBibleChapter(book: number, chapter: number) {
    localStorage.lastBibleChapter = JSON.stringify({ book, chapter });
  },

  getLastBibleChapter() {
    try {
      return JSON.parse(localStorage.lastBibleChapter);
    } catch (err) {
      return {
        book: 0,
        chapter: 0,
      };
    }
  },

  async subscribeToEntity(entityId: string) {
    // So that the code can be called from different scenarioscenarios
    const sendRequest = async () => {
      const { token } = localStorage;
      const deviceId = (await Device.getId()).uuid;

      const result = (
        await makeRequest("user.subscribeToEntity", {
          entityId,
          token,
          deviceId,
        })
      ).data;

      if (!result.error) {
        const entity = entities.get(entityId);

        if (entity) {
          entity.subscribed = true;
          rerender();
        }
      }
    };

    if (!userService.isLoggedIn()) {
      userService.promptNotifications(sendRequest);
      return;
    }

    if (
      (await pushNotificationService.isAvailable()) &&
      !pushNotificationService.isRegistered()
    ) {
      userService.promptNotifications(sendRequest);
      return;
    }

    await sendRequest();
  },

  async promptNotifications(onSuccess: any, forcePrompt = false) {
    const lastPushNotificationPrompt = Number(
      localStorage.lastPushNotificationPrompt || 0
    );
    const now = Date.now();
    let shouldPrompt = true;

    if (!forcePrompt && lastPushNotificationPrompt + 86400 * 1000 > now) {
      shouldPrompt = false;
    }

    if (shouldPrompt && !(await pushNotificationService.isRegistered())) {
      localStorage.lastPushNotificationPrompt = now;

      presentAlert({
        header:
          "Bible Media can send you a notification when content that matches your subscriptions has been added. Would you like to enable notifications?",
        buttons: [
          {
            text: "No",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Yes",
            role: "confirm",
            handler: async () => {
              await pushNotificationService.registerNotifications();
              // Once registration is successful the following function will be called
              // to send the push notification token to the backend
              // await PushNotifications.addListener("registration", ...)

              onSuccess();
            },
          },
        ],
      });
    }
  },

  promptRegister(router: any) {
    // If not logged in, prompt registration
    if (!userService.isLoggedIn()) {
      presentAlert({
        header: "Create an account to access more features.",
        message: "Register and save as much content as you’d like.",
        buttons: [
          {
            text: "Not Now",
            role: "cancel",
            handler: () => {},
          },
          {
            text: "Register",
            role: "confirm",
            handler: async () => {
              router.push("/register");
            },
          },
        ],
      });

      return;
    }
  },

  async unsubscribeFromEntity(entityId: string) {
    const { token } = localStorage;
    const result = (
      await makeRequest("user.unsubscribeFromEntity", { entityId, token })
    ).data;

    if (!result.error) {
      const entity = entities.get(entityId);

      if (entity) {
        entity.subscribed = false;
        rerender();
      }
    }
  },

  async subscribeToVerse() {},

  async unsubscribeFromVerse() {},

  async getSubscriptions() {
    const { token } = localStorage;
    const result = (await makeRequest("user.getSubscriptions", { token })).data;

    if (!result.error) {
      result.forEach((subscription: any) => {
        // Mutate the entity to have the subscribed property as the backend won't return it
        subscription.container.subscribed = true;
      });
      return result;
    }

    return [];
  },

  async getNotificationDevices() {
    const { token } = localStorage;
    const result = (await makeRequest("user.getNotificationDevices", { token }))
      .data;

    if (!result.error) {
      return result;
    }

    return [];
  },

  // async deleteNotificationDevice(id: number) {
  //   const { token } = localStorage;
  //   await makeRequest("user.deleteNotificationDevice", { token, id });
  // },

  // async subscribeToPushNotifications(subscription: any) {
  //   const { token } = localStorage;
  //   const result = (
  //     await makeRequest("user.subscribeToPushNotifications", {
  //       token,
  //       subscription,
  //     })
  //   ).data;
  //   return result;
  // },

  // async toggleNotificationDevice(id: number) {
  //   const { token } = localStorage;
  //   await makeRequest("user.toggleNotificationDevice", { token, id });
  // },

  routeToRegister(router: any) {
    router.push("/register");
  },

  async bookmarkEntity(entityId: string) {
    const { token } = localStorage;
    const result = (
      await makeRequest("user.bookmarkEntity", { entityId, token })
    ).data;

    if (!result.error) {
      const entity = entities.get(entityId);

      if (entity) {
        entity.bookmarked = true;
        rerender();
      }
    }
  },

  async unbookmarkEntity(entityId: string) {
    const { token } = localStorage;
    const result = (
      await makeRequest("user.unbookmarkEntity", { entityId, token })
    ).data;

    if (!result.error) {
      const entity = entities.get(entityId);

      if (entity) {
        entity.bookmarked = false;
        rerender();
      }
    }
  },

  async bookmarkVerse(specialId: string) {
    const { token } = localStorage;
    return (await makeRequest("user.bookmarkVerse", { specialId, token })).data;
  },

  async unbookmarkVerse(specialId: string) {
    const { token } = localStorage;
    await makeRequest("user.unbookmarkVerse", { specialId, token });
  },

  async getBookmarks() {
    const { token } = localStorage;
    const result = (await makeRequest("user.getBookmarks", { token })).data;

    if (!result.error) {
      return result;
    }

    return [];
  },

  async saveProfileData(profileData: { name: string }) {
    const { token } = localStorage;
    await makeRequest("user.saveProfileData", { token, profileData });
  },

  async sendEmailVerification() {
    const { token } = localStorage;
    await makeRequest("user.sendEmailVerification", { token });
  },

  async forgotPassword(emailAddress: string) {
    return (await makeRequest("forgotPassword", { emailAddress })).data;
  },

  async resetPassword(
    email: string,
    forgotPasswordToken: string,
    newPassword: string
  ) {
    return (
      await makeRequest("resetPassword", {
        email,
        forgotPasswordToken,
        newPassword,
      })
    ).data;
  },

  async verifyEmail(email: string, token: string) {
    return (
      await makeRequest("user.verifyEmail", {
        email,
        token,
      })
    ).data;
  },

  async getDiscoverData(type: string, page: number) {
    const { token } = localStorage;
    const result = (
      await makeRequest("user.getDiscover", { type, page, token })
    ).data;

    updateEntityContainers(result.containersToUpdate);

    return result;
  },

  async getNotifications(page: number) {
    const { token } = localStorage;
    const result = (await makeRequest("user.getNotifications", { page, token }))
      .data;

    updateEntityContainers(result);

    return result;
  },

  async submitFeedback(
    feedbackType: string,
    feedbackText: string,
    feedbackExperience: string
  ) {
    const { token } = localStorage;
    const deviceInfo = await Device.getInfo();

    await makeRequest("user.submitFeedback", {
      feedbackType,
      feedbackText,
      feedbackExperience,
      deviceInfo,
      token,
    });
  },

  async endIntro() {
    if (!userService.isLoggedIn()) {
      // Don't await, this can just happen in the background
      await userService.registerDevice();
    }

    localStorage.completedIntro = 1;
    localStorage.completedIntroTimestamp = Date.now();

    // Full location change. The app doesn't behave correctly when using the router
    window.location.href = "/";
  },
};
