import {
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import { Card } from "../components/Card";
import { EntityWrapper } from "../components/entities/display/EntityWrapper";
import { Loading } from "../components/Loading";
import { entityService } from "../lib/entityService";
import { formatDate } from "../lib/formatDate";
import { entities } from "../store/entities";

const Search: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [entityIds, setEntityIds] = useState<any[]>([]);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const timer = useRef<any>();

  async function search(newSearchString: string, newPage: number, event?: any) {
    let preSearchEntityIds = entityIds;

    if (newPage === 0) {
      preSearchEntityIds = [];
      setLoading(true);
    }

    setSearchString(newSearchString);

    clearTimeout(timer.current);

    timer.current = setTimeout(async () => {
      setPage(newPage);

      const newEntityIds = await entityService.search(newSearchString, newPage);

      if (newEntityIds.lengh < 10) {
        setInfiniteDisabled(true);
      }

      setEntityIds([...preSearchEntityIds, ...newEntityIds]);

      if (newPage === 0) {
        setLoading(false);
      }

      if (event) {
        event.target.complete();
      }
    }, 400);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Search</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div style={{ position: "fixed", zIndex: 3, width: "100%" }}>
          <Card className="filled">
            <IonLabel>Search</IonLabel>
            <IonInput
              value={searchString}
              onIonChange={(event) => {
                search(event.target.value as string, 0);
              }}
            />
          </Card>
        </div>

        <div style={{ marginTop: "7em" }}>
          <Loading loading={loading}>
            {entityIds.map((entityId) => {
              const entityContainer = entities.get(entityId);

              return (
                <div key={entityContainer.entity.entityId}>
                  <EntityWrapper
                    entityContainer={entityContainer}
                    prominence={"primary"}
                  />
                  <div
                    style={{
                      textAlign: "right",
                      paddingRight: "1em",
                      marginTop: "-0.5em",
                      opacity: 0.5,
                      fontSize: "0.75em",
                    }}
                  >
                    {formatDate(entityContainer.entity.addDatetime)}
                  </div>
                </div>
              );
            })}
            {!!searchString && !entityIds.length && !loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1em",
                }}
              >
                Sorry we don't have any results for "{searchString}"
              </div>
            )}
          </Loading>
        </div>

        <IonInfiniteScroll
          onIonInfinite={(event) => {
            search(searchString, page + 1, event);
          }}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="dots"
            loadingText="Loading more"
          />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Search;
