import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { ButtonSetting } from "../components/settings/ButtonSetting";
import { NextPageSetting } from "../components/settings/NextPageSetting";
import { TextSetting } from "../components/settings/TextSetting";
import { userService } from "../lib/userService";
import { reviewService } from "../lib/reviewService";
import { userStore } from "../store/userStore";
import { presentAlert, presentToast } from "../lib/toast";
import { APIBackendSetting } from "../components/settings/APIBackendSetting";

const Settings: React.FC = () => {
  const { email, userData } = userStore.user;
  const { publicData } = userData;
  const [changed, setChanged] = useState(false);
  const [userName, setUserName] = useState(publicData.name);

  useEffect(() => {
    if (publicData.name !== userName) {
      setChanged(true);
    }
  }, [userName, publicData.name]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Settings</IonTitle>
          {changed && (
            <IonButtons slot="end">
              <IonButton
                color="primary"
                fill="solid"
                onClick={() => {
                  userService.saveProfileData({
                    name: userName,
                  });

                  setChanged(false);

                  presentToast({
                    message: `Settings saved!`,
                    duration: 1500,
                    position: "bottom",
                  });
                }}
              >
                Save
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList style={{ padding: "1em" }}>
          {/* {userService.hasRoles(["approve-associations"]) && (
            <ToggleSetting
              label="Auto approve entity changes"
              settingId="autoApproveEntityChanges"
              settings={settings}
            />
          )}
          {userService.hasRoles(["edit-associations"]) && (
            <ToggleSetting
              label="Auto save an entity when making an association"
              settingId="autoSaveEntityChanges"
              settings={settings}
            />
          )} */}
          {userService.isLoggedIn() && (
            <TextSetting
              label="Your name"
              value={userName}
              setValue={setUserName}
            />
          )}
          {userService.isLoggedIn() && (
            <NextPageSetting
              label={<div>Notification Settings</div>}
              navigateTo="/settings-notifications"
            />
          )}
          {userService.isLoggedIn() && (
            <NextPageSetting label={<div>Support</div>} navigateTo="/support" />
          )}
          {userService.isLoggedIn() && (
            <NextPageSetting
              label="Privacy policy"
              navigateTo="/privacy-policy"
            />
          )}
          {userService.isLoggedIn() && (
            <NextPageSetting
              label="Terms of service"
              navigateTo="/terms-of-service"
            />
          )}
          {userService.isLoggedIn() && (
            <ButtonSetting
              label="App store rating"
              buttonText="Rate"
              onClick={() => {
                reviewService.promptForReview();
              }}
            />
          )}
          {userService.hasRoles(["admin"]) && <APIBackendSetting />}
          {userService.hasRoles(["admin"]) && (
            <NextPageSetting label="Debug (admin)" navigateTo="/admin-debug" />
          )}
          {userService.hasRoles(["admin"]) && (
            <ButtonSetting
              label="Reset Intro"
              buttonText="Reset"
              onClick={() => {
                delete localStorage.completedIntro;
                window.location.href = "/";
              }}
            />
          )}

          {userService.isLoggedIn() && (
            /** Always keep this at the bottom */
            <>
              <IonItem style={{ paddingTop: "1em" }}></IonItem>
              <ButtonSetting
                label="Logout of Bible Media"
                buttonText="Logout"
                onClick={() => userService.logout()}
              />
            </>
          )}

          {userService.isLoggedIn() && (
            /** Always keep this at the bottom */
            <>
              <IonItem style={{ paddingTop: "1em" }}></IonItem>
              <ButtonSetting
                color="danger"
                label="Delete your Bible Media account (All data)"
                subText={
                  <div>
                    If you subscribe to Bible Media Premium, billing will
                    continue through Apple or Google. Please cancel your
                    subscription before continuing.
                    <br />
                    <a
                      href="https://apps.apple.com/account/subscriptions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://apps.apple.com/account/subscriptions
                    </a>
                  </div>
                }
                buttonText="Delete"
                onClick={() => {
                  deleteAccountStep1(email);
                }}
              />
            </>
          )}

          {/* {userService.hasRoles(["admin"]) && (
            <ButtonSetting
              label="Register Push"
              buttonText="Register"
              onClick={() => {
                pushNotificationService.registerNotifications();
              }}
            />
          )} */}

          {/* TODO: remove */}
          {/* {userService.isLoggedIn() && (
            <ButtonSetting
              label="Get Notifications"
              buttonText="Get Notifications"
              onClick={() => {
                pushNotificationService.getDeliveredNotifications();
              }}
            />
          )} */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;

/**
 * Delete account flow
 */

function deleteAccountStep1(email: string) {
  presentAlert({
    header: "Delete your account? This action cannot be undone.",
    message: `Deleting your account (${email}) will also delete all associated data (saves, subscriptions, etc.). Nothing will be retained or recoverable.`,
    buttons: [
      {
        text: "Delete",
        role: "confirm",
        handler: async () => {
          userService.deleteAccount();
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {},
      },
    ],
  });
}
