import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { useState } from "react";
import { Content } from "../components/Content";
import { EntityGrouping } from "../components/EntityGrouping";
import { Loading } from "../components/Loading";
import { SearchBar } from "../components/search/SearchBar";
import { entityService } from "../lib/entityService";
import { getEntities } from "../lib/getEntities";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";
import { userStore } from "../store/userStore";
import { searchStore, startSearching } from "../store/searchStore";
import "../styles/pages/home.scss";
import { SearchResults } from "../components/search/SearchResults";

const Home: React.FC = () => {
  const [verseData, setVerseData] = useState<any>(null);
  const { user } = userStore;
  const router = useIonRouter();

  async function getVerseData() {
    const result = await entityService.getVerseOfTheDay();
    setVerseData(result);
  }

  // Loading handler
  const [loading] = useWillEnterWithLoading(async () => {
    getVerseData();
  }, 400);

  let verseDisplay = null;

  if (verseData) {
    const verses = getEntities(verseData.associatedEntities, "verse", true);
    const themes = getEntities(verseData.associatedEntities, "theme", true);
    const questions = getEntities(
      verseData.associatedEntities,
      "question",
      true
    );
    const songs = getEntities(verseData.associatedEntities, "song", true);
    const sermons = getEntities(verseData.associatedEntities, "sermon", true);
    const genres = getEntities(verseData.associatedEntities, "genre", true);

    verseDisplay = (
      <>
        <EntityGrouping title="Genres" entityIds={genres} />
        <EntityGrouping title="Verses" entityIds={verses} />
        <EntityGrouping title="Themes" entityIds={themes} />
        <EntityGrouping title="Questions" entityIds={questions} />
        <EntityGrouping title="Sermons" entityIds={sermons} />
        <EntityGrouping title="Songs" entityIds={songs} />
      </>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          className="toolbar-shade"
          style={{ "--background": "var(--light)" }}
        >
          {!searchStore.searching && (
            <>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle className="toolbar-center">Home</IonTitle>

              <IonButtons slot="end">
                <IonButton onClick={startSearching}>
                  <IonIcon slot="icon-only" icon={search} />
                </IonButton>
              </IonButtons>
            </>
          )}
          {searchStore.searching && <SearchBar />}
        </IonToolbar>
      </IonHeader>

      {!searchStore.searching && (
        <Loading loading={loading} ContentComponent={Content}>
          <Content>
            <div className="welcome">
              {user.userId && (
                <div className="name">Hi, {user.userData.publicData.name}!</div>
              )}
              {!user.userId && <div className="name">Welcome!</div>}
            </div>
            {verseData && (
              <div
                className="verse-of-the-day"
                onClick={() => {
                  if (verseData.specialId.includes("-")) {
                    const verseIds = verseData.specialId.split("-");
                    router.push(`/verse/${verseIds[0]}`);
                  } else {
                    router.push(`/verse/${verseData.specialId}`);
                  }
                }}
              >
                <div style={{ fontSize: "1.25em", marginBottom: "0.5em" }}>
                  Verse of the day
                </div>
                <div className="verse-name">{verseData?.verseName}</div>
                {verseData.text.map((line: string) => {
                  return (
                    <div
                      className="verse-text"
                      style={{ marginBottom: "0.5em" }}
                    >
                      {line}
                    </div>
                  );
                })}
              </div>
            )}

            {verseDisplay}
          </Content>
        </Loading>
      )}

      {searchStore.searching && <SearchResults />}
    </IonPage>
  );
};

export default Home;
