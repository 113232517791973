import { IonInput, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useState } from "react";
import { TextFieldTypes } from "@ionic/core/dist/types/interface";

type ValidValue = string;

interface Props {
  type?: TextFieldTypes;
  label: string;
  value: ValidValue;
  helperText: string;
  errorText: string;
  validationMode?: "onBlur" | "onChange";
  setValue: (value: ValidValue) => void;
  validate: (value: ValidValue) => boolean | undefined;
}

export function ValidatedInput({
  type,
  label,
  value,
  helperText,
  errorText,
  validationMode = "onBlur",
  setValue,
  validate,
}: Props) {
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [isTouched, setIsTouched] = useState(false);

  return (
    <IonItem
      className={`${isValid ? "ion-valid" : ""} ${
        isValid === false ? "ion-invalid" : ""
      } ${isTouched ? "ion-touched" : ""}`}
    >
      <IonLabel position="floating">{label}</IonLabel>
      <IonInput
        type={type}
        value={value}
        onIonInput={(event) => {
          if (validationMode === "onChange") {
            setIsValid(validate(event.target.value as string));
          }

          if (validationMode === "onBlur") {
            setIsValid(undefined);
          }
        }}
        onIonChange={(event) => {
          setValue(event.target.value as string);
        }}
        onIonBlur={() => {
          setIsTouched(true);

          if (validationMode === "onBlur") {
            setIsValid(validate(value as string));
          }
        }}
      />
      <IonNote slot="helper">{helperText}</IonNote>
      <IonNote slot="error">{errorText}</IonNote>
    </IonItem>
  );
}
