import { EditQuestionModal } from "../../components/modals/EditQuestionModal";
import BrowseEntityType from "../BrowseEntityType";

const Questions: React.FC = () => {
  return (
    <BrowseEntityType
      title="Questions"
      entityType="question"
      EditModalComponent={EditQuestionModal}
    />
  );
};

export default Questions;
