import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { Card } from "../../components/Card";
import { userService } from "../../lib/userService";
import { experienceValues, typeValues } from "../../types/feedback";

export const Feedback: React.FC = () => {
  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackExperience, setFeedbackExperience] = useState("");
  const [submitted, setSubmitted] = useState(false);

  let content = null;

  if (submitted) {
    content = (
      <Card>
        <h1 style={{ textAlign: "center" }}>Thank you for your feedback</h1>
        <br />
        <IonButton
          style={{ width: "100%" }}
          onClick={() => {
            setSubmitted(false);
            setFeedbackType("");
            setFeedbackText("");
          }}
        >
          Submit more feedback
        </IonButton>
      </Card>
    );
  } else {
    content = (
      <Card>
        <div>
          <IonLabel style={{ fontWeight: "bold" }}>
            What kind of feedback do you have?
          </IonLabel>

          <IonSelect
            style={{ paddingLeft: "0em" }}
            value={feedbackType}
            placeholder="Select feedback type"
            onIonChange={(event) => {
              setFeedbackType(event.target.value);
            }}
          >
            {typeValues.map(([value, label]) => {
              return (
                <IonSelectOption key={value} value={value}>
                  {label}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </div>
        <br />
        <div>
          <IonLabel style={{ fontWeight: "bold" }}>
            Please describe your feedback.
          </IonLabel>
          <IonTextarea
            value={feedbackText}
            autoGrow={true}
            onIonChange={(event) => {
              setFeedbackText(event.target.value as string);
            }}
            placeholder="Please do not include any personal information in your comment"
          />
        </div>
        <br />
        <div>
          <IonLabel style={{ fontWeight: "bold" }}>
            How would you rate your experience using Bible Media.
          </IonLabel>
          <IonRadioGroup
            value={feedbackExperience}
            onIonChange={(event) => {
              setFeedbackExperience(event.target.value);
            }}
          >
            {experienceValues.map(([value, label]) => {
              return (
                <IonItem key={value}>
                  <IonLabel>{label}</IonLabel>
                  <IonRadio slot="end" value={value}></IonRadio>
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </div>
        <br />
        <IonButton
          onClick={() => {
            setSubmitted(true);

            userService.submitFeedback(
              feedbackType,
              feedbackText,
              feedbackExperience
            );
          }}
        >
          Submit
        </IonButton>
      </Card>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Feedback</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>{content}</IonContent>
    </IonPage>
  );
};
