import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

export const Support: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Support</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div style={{ padding: "1em" }}>
          <h1>Bible Media</h1>

          <ol>
            <li>Overview of the app and features</li>
            <li>Frequently asked questions (FAQs)</li>
            <li>Contact information for technical support</li>
            <li>Privacy policy and terms of service</li>
          </ol>
          <h1>Overview of Bible Media</h1>
          <span>Tabs and Features of the app</span>

          <span>
            The Bible Media iOS app (v1.0) contains 8 tabs in the menu. They
            are:
          </span>

          <p>
            <strong>Version - </strong>
            <span>
              This tab allows the user to change between available versions of
              the Bible
            </span>
          </p>
          <p>
            <strong>Bible - </strong>
            <span>
              This tab allows the user to read the Bible. Things to note:
            </span>
          </p>
          <ul>
            <li>
              <span>
                The Book | Chapter navigation is located on the top of the
                screen.
              </span>
            </li>
            <li>
              <span>
                The left and right arrows allow the user to navigate to the
                previous and next chapter, respectively.
              </span>
            </li>
            <li>
              <span>
                The dots underneath the verse (if applicable) represent types of
                content that are available to be viewed with that verse by
                tapping it.
              </span>
            </li>
          </ul>
          <p>
            <strong>Home - </strong>
            <span>This tab shows the following:</span>
          </p>
          <ul>
            <li>
              Verse of the Day - The verse and associated media content our
              staff has chosen for the day. Feel free to read, reflect, and dive
              into the content that the verse has inspired.
            </li>
            <li>
              Search - On the upper right hand corner, there is a search bar
              where the user can search for all types of content available in
              Bible Media.
            </li>
          </ul>
          <p>
            <strong>Discover - </strong>
            <span>This tab shows featured content including:</span>
          </p>
          <ul>
            <li>
              <span>Featured verses</span>
            </li>
            <li>
              <span>Questions the Bible answers</span>
            </li>
            <li>
              <span>Sermons inspired by the Bible</span>
            </li>
            <li>
              <span>Songs inspired by the Bible</span>
            </li>
          </ul>
          <p>
            <strong>Notifications - </strong>
            <span>This tab shows all notifications</span>
          </p>
          <ul>
            <li>
              <span>
                Notifications result from content being added or released that
                matches your subscriptions to artists, speakers, themes, and
                genres.
              </span>
            </li>
          </ul>
          <p>
            <strong>Settings - </strong>
            <span>
              This tab allows you to change your settings and preferences.
            </span>
          </p>
          <p>
            <strong>Saved -</strong>
            <span>All media that you have saved is stored in this tab.</span>
          </p>
          <p>
            <strong>Subscriptions -</strong>
            <span>
              All speakers, artists, themes, and genres that you have subscribed
              to.
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <span></span>
          </p>
          <h1>Frequently Asked Questions (FAQs)</h1>
          <p>
            <span>Common questions from our users</span>
          </p>
          <ol>
            <li>
              <strong>
                Q: What does Bible Media offer that&#39;s different from other
                Bible apps out there?
              </strong>
              <br />
              <br />
              A: Bible Media is more than a Bible app. While a user may use it
              for the purpose of reading the Word of God, it also affords the
              user many other features, including:
              <ul className="c11 lst-kix_cnyesbycbesi-0 start">
                <li>
                  <span>
                    Tapping into a Bible verse to see songs and sermons that
                    verse has inspired
                  </span>
                </li>
                <li>
                  <span>
                    Searching the Bible by a theme such as &quot;Faith&quot; or
                    &quot;Love&quot; to find Bible verses related to that theme
                  </span>
                </li>
                <li>
                  <span>
                    A way to subscribe to your favorite artists and speakers to
                    be notified of new content they release
                  </span>
                </li>
              </ul>
            </li>
          </ol>

          <ol start={2}>
            <li>
              <strong>Q: What are themes?</strong>
              <br />
              <br />
              A: Themes are like tags; they group Bible verses and content
              together by a specific topic that allows the user to explore the
              Bible and its media based on a specific need or interest.
            </li>
          </ol>

          <ol start={3}>
            <li>
              <strong>Q: How do I get my content into Bible Media?</strong>
              <br />
              <br />
              A: Email our staff with any suggested content for review at
              support@biblemedia.app
            </li>
          </ol>

          <h1>Contact information for technical support</h1>
          <p>
            <span>
              For any questions or issues to report, please contact
              support@biblemedia.app
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <span></span>
          </p>
          <h1>Privacy policy and terms of service</h1>
          <p>
            <span>
              Links to the Privacy Policy and Terms of Service can be found
              here:
            </span>
          </p>
          <p>
            <span></span>
          </p>
          <p>
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
          <p>
            <a href="/terms-of-service">Terms of Service</a>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};
