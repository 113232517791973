import { IonButton, IonContent, IonPage } from "@ionic/react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useEffect } from "react";
import { userStore } from "../../store/userStore";
import { userService } from "../../lib/userService";

export const Intro2: React.FC = () => {
  const slides: any = [];
  const { user } = userStore;

  useEffect(() => {
    if (user.userData && user.userData.emailVerified) {
      // No user data yet or already verified, don't do anything
      return;
    }

    let timer: any = null;

    async function check() {
      const user = await userService.getUser();

      if (user?.userData?.emailVerified) {
        // The email is verified, so just rerender to pickup the change
        window.location.href = "/intro/3";
        return;
      }

      timer = setTimeout(check, 5000);
    }

    check();

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  slides.push(
    <SwiperSlide>
      <div className="swiper-slide-content">
        Awesome! Check out the email we just sent you to verify your email
        address: {user.email}
        <div style={{ marginTop: "2em" }}>
          <IonButton
            onClick={() => {
              userService.sendEmailVerification();
            }}
          >
            Resend verification email
          </IonButton>
          <div style={{ padding: "1em 0em" }}>Or</div>
          <IonButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </IonButton>
          <div style={{ padding: "1em 0em" }}>Or</div>
          <IonButton
            onClick={() => {
              userService.logout();
              window.location.href = "/intro/1";
            }}
          >
            Logout
          </IonButton>
        </div>
      </div>
    </SwiperSlide>
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        <Swiper pagination={true} modules={[Pagination]}>
          {slides}
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
