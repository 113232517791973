import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { removeEntityOnClick } from "./util";

export function AdminSongEntity({
  entityContainer,
  parent,
  openModal,
  canEdit,
  onChange,
}: any) {
  return (
    <IonChip
      onClick={() => {
        if (canEdit) {
          openModal(entityContainer.entity.entityId);
        }
      }}
    >
      <IonLabel>{entityContainer.entity.entityData.name}</IonLabel>
      <IonIcon
        icon={closeOutline}
        className="clickable"
        onClick={(event) => {
          removeEntityOnClick(event, parent, entityContainer);
          onChange(true);
        }}
      ></IonIcon>
    </IonChip>
  );
}
