import {
  bookOutline,
  pricetagOutline,
  hammerOutline,
  peopleOutline,
  fileTrayFullOutline,
  personOutline,
  bulbOutline,
  shieldCheckmarkOutline,
  funnelOutline,
} from "ionicons/icons";
import { entityTypeConfigs } from "../../lib/entityTypeConfigs";
import { userService } from "../../lib/userService";
import { MenuOption } from "../../types/menu";

export const adminOptions: MenuOption[] = [
  {
    title: "ADMIN",
    url: "",
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Workshop",
    url: "/workshop",
    icon: hammerOutline,
    isAvailable: () => {
      return (
        userService.hasRoles(["edit-associations"]) ||
        userService.hasRoles(["view-workshop"])
      );
    },
  },
  {
    title: "Verse Of The Day",
    url: "/verseoftheday",
    icon: bookOutline,
    isAvailable: () => {
      return userService.hasRoles(["edit-associations"]);
    },
  },
  {
    title: "Feedback",
    url: "/feedback-admin",
    icon: funnelOutline,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  // {
  //   title: "Invite Contributors",
  //   url: "/invite-contributors",
  //   isAvailable: () => {
  //     return userService.hasRoles(["invite-users"]);
  //   },
  // },
  {
    title: "Edit Users",
    url: "/edit-users",
    icon: peopleOutline,
    isAvailable: () => {
      return userService.hasRoles(["edit-users"]);
    },
  },
  {
    title: "Approve Assoiciations",
    url: "/approve-entity-changes",
    icon: shieldCheckmarkOutline,
    isAvailable: () => {
      return userService.hasRoles(["approve-associations"]);
    },
  },
  {
    title: "Reports",
    url: "/reports",
    icon: bulbOutline,
    isAvailable: () => {
      return userService.hasRoles(["delete-entities"]);
    },
  },
  {
    title: "Feed",
    url: "/feed",
    icon: fileTrayFullOutline,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Intro",
    url: "",
    onClick: () => {
      window.location.href = "/intro/1";
    },
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Intro 3",
    url: "",
    onClick: () => {
      window.location.href = "/intro/3";
    },
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "ENTITIES",
    url: "",
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Artists",
    url: "/artists",
    icon: personOutline,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Genres",
    url: "/genres",
    icon: pricetagOutline,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Questions",
    url: "/questions",
    icon: entityTypeConfigs.question.icon,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Sermons",
    url: "/sermons",
    icon: entityTypeConfigs.sermon.icon,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Songs",
    url: "/songs",
    icon: entityTypeConfigs.song.icon,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Speakers",
    url: "/speakers",
    icon: personOutline,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
  {
    title: "Themes",
    url: "/themes",
    icon: entityTypeConfigs.theme.icon,
    isAvailable: () => {
      return userService.hasRoles(["admin"]);
    },
  },
];
