export const typeValues = [
  ["problem", "Report a problem"],
  ["suggestion", "Have a suggestion"],
  ["compliment", "Give a compliment"],
  ["other", "Something else"],
] as const;
export const typeValuesMap = new Map(typeValues);

export const experienceValues = [
  ["verySatisfied", "Very Satisfied"],
  ["satisfied", "Satisfied"],
  ["neutral", "Neutral"],
  ["dissatisfied", "Dissatisfied"],
  ["veryDissatisfied", "Very Dissatisfied"],
] as const;
export const experienceValuesMap = new Map(experienceValues);
