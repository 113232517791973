import { IonButton, IonCard, IonCardSubtitle } from "@ionic/react";
import { useState } from "react";

function VerseTextComponent({
  verseText,
  initialShowAll = false,
}: {
  verseText: string[];
  initialShowAll: boolean;
}) {
  const [showAll, setShowAll] = useState(initialShowAll);

  if (showAll) {
    return (
      <div style={{ color: "var(--dark)" }}>
        {verseText.map((text, index) => {
          return (
            <div key={index}>
              {index > 0 && (
                <>
                  <br />
                </>
              )}
              {text}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div style={{ color: "var(--dark)" }}>
      {verseText.slice(0, 3).map((text, index) => {
        return (
          <div key={index}>
            {index > 0 && (
              <>
                <br />
              </>
            )}
            {text}
          </div>
        );
      })}
      {verseText.length > 6 && (
        <>
          <br />
          <IonButton
            onClick={(event) => {
              event.stopPropagation();
              setShowAll(true);
            }}
          >
            {verseText.length - 6} more
          </IonButton>
        </>
      )}
      {verseText.length > 3 &&
        verseText
          .slice(verseText.length - Math.min(3, verseText.length - 3))
          .map((text, index) => {
            return (
              <div key={index}>
                <br />
                {text}
              </div>
            );
          })}
    </div>
  );
}

export function DisplayVerseEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;
  const verseText: string[] = entityData.text || ["There should be text here"];

  if (prominence === "hero") {
    return (
      <IonCard style={{ padding: "1em" }}>
        <IonCardSubtitle style={{ marginBottom: "0.5em" }}>
          {entity.entityName}
        </IonCardSubtitle>

        <div style={{ fontSize: "1.75em", color: "var(--dark)" }}>
          <VerseTextComponent verseText={verseText} initialShowAll={true} />
        </div>
      </IonCard>
    );
  }

  if (prominence === "primary") {
    return (
      <IonCard style={{ padding: "1em" }}>
        <IonCardSubtitle style={{ marginBottom: "0.5em" }}>
          {entity.entityName}
        </IonCardSubtitle>

        <VerseTextComponent verseText={verseText} initialShowAll={false} />
      </IonCard>
    );
  }

  if (prominence === "secondary") {
    return (
      <div
        style={{
          position: "relative",
          width: "20em",
          marginRight: "2em",
          height: "10em",
          overflowY: "scroll",
        }}
      >
        <IonCardSubtitle style={{ marginBottom: "0.5em" }}>
          {entity.entityName}
        </IonCardSubtitle>

        <VerseTextComponent verseText={verseText} initialShowAll={false} />
      </div>
    );
  }

  return null;
}
