import { IonButton, IonButtons, IonSearchbar } from "@ionic/react";
import { useEffect, useRef } from "react";
import {
  endSearching,
  searchStore,
  setSearchString,
} from "../../store/searchStore";

export const SearchBar: React.FC = () => {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.setFocus();
      }
    }, 100);
  }, [inputRef]);

  return (
    <>
      <IonSearchbar
        ref={inputRef}
        value={searchStore.searchString}
        style={{ padding: "0em", margin: "-1em 0em" }}
        onInput={(event: any) => {
          setSearchString(event.target.value as string, 0);
        }}
      />
      <IonButtons slot="end">
        <IonButton onClick={endSearching}>Cancel</IonButton>
      </IonButtons>
    </>
  );
};
