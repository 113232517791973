import {
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { adminService } from "../../lib/adminService";
import { formatDate } from "../../lib/formatDate";
import { experienceValuesMap, typeValuesMap } from "../../types/feedback";

export const FeedbackAdmin: React.FC = () => {
  const [feedbackItems, setFeedbackItems] = useState<any[]>([]);

  async function getFeedback() {
    const result = await adminService.getFeedback();
    setFeedbackItems(result);
  }

  useEffect(() => {
    getFeedback();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Feedback</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {feedbackItems.map((feedback) => {
          return (
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div style={{ fontSize: "0.75em" }}>Type</div>
                  {typeValuesMap.get(feedback.feedbackType) ||
                    feedback.feedbackType ||
                    "-"}
                </div>
                <div>
                  <div style={{ fontSize: "0.75em" }}>Experience</div>
                  {experienceValuesMap.get(feedback.feedbackExperience) ||
                    feedback.feedbackExperience ||
                    "-"}
                </div>
              </div>
              <br />
              <div style={{ fontSize: "0.75em" }}>Comment</div>
              <IonTextarea readonly={true} autoGrow={true} rows={1}>
                {feedback.feedbackText || "-"}
              </IonTextarea>
              <div style={{ fontSize: "0.75em" }}>Device Info</div>

              {feedback.deviceInfo &&
                Object.keys(feedback.deviceInfo).map((key) => {
                  const value = feedback.deviceInfo[key] || "";

                  if (!value) {
                    return null;
                  }

                  return (
                    <IonChip
                      key={key}
                      style={{
                        fontSize: "0.75em",
                        padding: "0em 0.5em",
                        height: "1.75em",
                      }}
                    >
                      {value}
                    </IonChip>
                  );
                })}

              <div style={{ textAlign: "right", fontSize: "0.75em" }}>
                <strong>{feedback.email}</strong> - {feedback.name} -{" "}
                {formatDate(feedback.addDatetime)}
              </div>
            </Card>
          );
        })}
      </IonContent>
    </IonPage>
  );
};
