import { LabeledInput } from "../LabeledInput";

interface Props {
  label: string;
  value: string;
  setValue: any;
}

export function TextSetting({ label, value, setValue }: Props) {
  return <LabeledInput value={value} label={label} setValue={setValue} />;
}
