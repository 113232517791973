import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { Card } from "../../components/Card";
import { ValidatedInput } from "../../components/ValidatedInput";
import { userService } from "../../lib/userService";

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [sendSuccessful, setSendSuccessful] = useState(false);
  const [error, setError] = useState("");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Forgot Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Card>
          {!sendSuccessful && (
            <>
              <ValidatedInput
                type="email"
                label="Email"
                value={email}
                setValue={setEmail}
                validate={(value) => (value ? isEmail(value) : undefined)}
                helperText="Enter your email"
                errorText="Invalid email"
              />

              <IonButton
                onClick={async () => {
                  const result = await userService.forgotPassword(email);

                  if (result.error) {
                    setError(result.error);
                  } else {
                    setSendSuccessful(true);
                  }
                }}
              >
                Send Password Reset
              </IonButton>
            </>
          )}

          {sendSuccessful && (
            <div>
              You should receive an email shorty at <strong>{email}</strong>{" "}
              with instructions for resetting your password.
            </div>
          )}

          {!!error && (
            <div>
              <IonLabel color="danger">{error}</IonLabel>
            </div>
          )}
        </Card>
      </IonContent>
    </IonPage>
  );
};
