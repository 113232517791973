import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { useState } from "react";
import { createEmptyEntityContainer } from "../../lib/createEmptyEntityContainer";
import { entityService } from "../../lib/entityService";
import { getEntities } from "../../lib/getEntities";
import { modalService } from "../../lib/modalService";
import { entities } from "../../store/entities";
import { EditEntitySection } from "../admin/EditEntitySection";
import { AdminArtistEntity } from "../admin/AdminArtistEntity";
import { AdminGenreEntity } from "../admin/AdminGenreEntity";
import { AdminThemeEntity } from "../admin/AdminThemeEntity";
import { EditArtistModal } from "./EditArtistModal";
import { EditEntityModal } from "./EditEntityModal";
import { EditGenreModal } from "./EditGenreModal";
import { EditThemeModal } from "./EditThemeModal";
import { EntityLinkField } from "./fields/EntityLinkField";
import { BaseEditModalProps } from "./util";

type Props = {} & BaseEditModalProps;

const entityType = "song";

export function EditSongModal(props: Props) {
  const [entityContainer, setEntityContainer] = useState<any>(
    entities.get(props.entityId) || createEmptyEntityContainer(entityType)
  );

  const themes = getEntities(entityContainer.associatedEntities, "theme");
  const genres = getEntities(entityContainer.associatedEntities, "genre");
  const artists = getEntities(entityContainer.associatedEntities, "artist");

  return (
    <EditEntityModal
      {...props}
      entityType={"Song"}
      targetEntityContainer={entityContainer}
      onSelect={(entityContainer: any) => {
        props.onSelect(entityContainer);
        modalService.closeModal(props.id);
      }}
      onSearch={(searchString: string) => {
        return entityService.findEntitiesOfType(searchString, entityType);
      }}
      isValid={() => {
        return !!entityContainer.entity.entityData.name;
      }}
      onStartNew={(initialValue: string) => {
        entityContainer.entity.entityName = initialValue;
        entityContainer.entity.entityData.name = initialValue;
      }}
      setEntityContainer={setEntityContainer}
    >
      {({ setChanged }: any) => {
        return (
          <>
            <IonLabel>Song Name</IonLabel>
            <IonInput
              value={entityContainer.entity.entityData.name}
              onIonChange={(event) => {
                const newContainer = JSON.parse(
                  JSON.stringify(entityContainer)
                );

                newContainer.entity.entityName = event.target.value;
                newContainer.entity.entityData.name = event.target.value;

                setEntityContainer(newContainer);
                setChanged(true);
              }}
            />
            <EditEntitySection
              id="artists"
              title="Artists"
              entityIds={artists}
              EntityComponent={AdminArtistEntity}
              EditModalComponent={EditArtistModal}
              onChange={setChanged}
              parent={entityContainer}
            />
            <EditEntitySection
              id="genres"
              title="Genre"
              type="checkbox"
              entityType="genre"
              entityIds={genres}
              EntityComponent={AdminGenreEntity}
              EditModalComponent={EditGenreModal}
              onChange={setChanged}
              parent={entityContainer}
            />
            <EntityLinkField
              entityContainer={entityContainer}
              setChanged={setChanged}
              setEntityContainer={setEntityContainer}
            />
            <EditEntitySection
              id="themes"
              title="Themes"
              entityIds={themes}
              EntityComponent={AdminThemeEntity}
              EditModalComponent={EditThemeModal}
              onChange={setChanged}
              parent={entityContainer}
            />

            <IonCard>
              <IonCardContent>
                <IonItem style={{ height: "2.5em" }}>
                  <IonCheckbox
                    slot="start"
                    checked={entityContainer.entity.entityData.newRelease}
                    onIonChange={() => {
                      entityContainer.entity.entityData.newRelease =
                        !entityContainer.entity.entityData.newRelease;
                      setEntityContainer({ ...entityContainer });
                    }}
                  />
                  <IonLabel>New Release</IonLabel>
                </IonItem>
                This only applies to the notification that is sent from this
                song being added. Once the song has been newly added, this
                toggle has no effect.
              </IonCardContent>
            </IonCard>
          </>
        );
      }}
    </EditEntityModal>
  );
}
