export function YouTubeEmbed({ link, height }: any) {
  const videoId = getVideoId(link);

  if (!videoId) {
    return null;
  }

  return (
    <div>
      {/* <iframe
        width="100%"
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        style={{ border: "0em" }}
      /> */}
      <iframe
        width="100%"
        height={height}
        src={`https://biblemedia.app/youtube_embed.html?height=${height}&embed_url=https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        style={{ border: "0em" }}
      />
    </div>
  );
}

function getVideoId(link: string) {
  if (!link) {
    return null;
  }

  if (link.includes("youtu.be")) {
    return link.split("/")[3];
  }

  const urlParams = new URLSearchParams(link.split("?")[1]);
  return urlParams.get("v");
}
