import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useCallback, useState } from "react";
import { Calendar } from "../../components/Calendar/Calendar";
import { SelectVerseModal } from "../../components/modals/SelectVerseModal";
import { adminService } from "../../lib/adminService";
import { modalService } from "../../lib/modalService";

const VerseOfTheDay: React.FC = () => {
  const [monthVerseOfTheDay, setMonthVerseOfTheDay] = useState(new Map());

  async function getMonthVerses(month: number, year: number) {
    const result = await adminService.getMonthVerseOfTheDay(month, year);
    setMonthVerseOfTheDay(new Map(result));
  }

  const onMonthChange = useCallback(
    ({ month, year }: { month: number; year: number }) => {
      setMonthVerseOfTheDay(new Map());
      getMonthVerses(month, year);
    },
    []
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Verse Of The Day</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Calendar
          renderCell={({ month, year, monthDate }) => {
            const dateId = `${month}-${monthDate}`;
            const dateData = monthVerseOfTheDay.get(dateId);

            return (
              <div
                onClick={async () => {
                  const scheduleDate = new Date(
                    year,
                    month,
                    monthDate,
                    1,
                    1,
                    1
                  );

                  modalService.openModalByComponent(SelectVerseModal, {
                    date: scheduleDate,
                    onSelect: async (verse: string) => {
                      await adminService.setVerseOfTheDay(scheduleDate, verse);
                      await getMonthVerses(month, year);
                    },
                    onClear: async (verse: string) => {
                      await adminService.clearVerseOfTheDay(scheduleDate);
                    },
                  });
                }}
                style={{ fontSize: "0.75em", padding: "2em 1em" }}
              >
                {dateData ? dateData.specialId : ""}
              </div>
            );
          }}
          onMonthChange={onMonthChange}
        />
      </IonContent>
    </IonPage>
  );
};

export default VerseOfTheDay;
