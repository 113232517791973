import { DeviceInfo } from "@capacitor/device";

export const systemStore: {
  ready: boolean;
  delayedLoading: boolean;
  roles: string[];
  canShare: boolean;
  pushNotificationsAvailable: boolean; // Non-async way to check for availability
  pushNotificationsRegistered: boolean; // Non-async way to check for availability
  subscriptionDebug: string[][];
  deviceInfo: DeviceInfo;
  deviceId: string;
} = {
  ready: false,
  delayedLoading: false,
  roles: [],
  canShare: false,
  pushNotificationsAvailable: false,
  pushNotificationsRegistered: false,
  subscriptionDebug: [],
  deviceInfo: null as any,
  deviceId: null as any,
};

(window as any).systemStore = systemStore;
