import { IonButton, IonItem } from "@ionic/react";
import { useState } from "react";
import { EntityWrapper } from "../../../components/entities/display/EntityWrapper";
import { formatDate } from "../../../lib/formatDate";

interface Props {
  change: any;
  onApprove: any;
  onDeny: any;
}

const EntityChangeItem: React.FC<Props> = ({ change, onApprove, onDeny }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <IonItem key={change.id}>
      <div>
        <span>
          [{change.secondaryEntity.entityType}
          {change.appliedState === 1 ? ", applied" : ""}]{" "}
          <b>"{change.secondaryEntity.entityName}"</b>
        </span>
        {change.action === "add" && <span>added to </span>}
        {change.action === "delete" && <span>removed from </span>}
        <span>
          <b>"{change.primaryEntity.entityName}"</b> by <b>{change.email}</b> on{" "}
          {formatDate(change.addDatetime)}
        </span>

        {!expanded && (
          <div>
            <IonButton onClick={() => setExpanded(true)}>
              Show More...
            </IonButton>
          </div>
        )}

        {expanded && (
          <>
            <EntityWrapper
              entityContainer={{
                entity: change.secondaryEntity,
                associatedEntities: [],
              }}
              prominence="primary"
            />
            <EntityWrapper
              entityContainer={{
                entity: change.primaryEntity,
                associatedEntities: [],
              }}
              prominence="primary"
            />
          </>
        )}
      </div>

      <span slot="end">
        <IonButton onClick={onApprove}>Approve</IonButton>
        <IonButton onClick={onDeny}>Deny</IonButton>
      </span>
    </IonItem>
  );
};

export default EntityChangeItem;
