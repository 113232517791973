export const entityTypes = [
  "artist",
  "genre",
  "question",
  "song",
  "sermon",
  "speaker",
  "theme",
  "verse",
] as const;

export type EntityType = typeof entityTypes[number];

export const subscribableEntityTypes: EntityType[] = ["artist", "speaker"];
export const bookmarkableEntityTypes: EntityType[] = ["song", "sermon"];
