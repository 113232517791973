import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import "./Menu.css";
import { userStore } from "../../store/userStore";
import { getApiUrl } from "../../lib/api";
import { adminOptions } from "./adminOptions";
import { userOptions } from "./userOptions";

const noMenuViews = [/\/intro\//];

const Menu: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();

  const noMenu = noMenuViews.find((regex) => {
    if (router.routeInfo.pathname.match(regex)) {
      return true;
    }
    return false;
  });

  const availableAdminOptions = adminOptions.map((appPage, index) => {
    if (appPage.isAvailable && !appPage.isAvailable()) {
      return null;
    }

    let title = appPage.title;
    if (typeof title === "function") {
      title = title();
    }

    return (
      <IonMenuToggle key={index} autoHide={false}>
        <IonItem
          className={location.pathname === appPage.url ? "selected" : ""}
          routerLink={appPage.url || undefined}
          routerDirection="none"
          lines="none"
          detail={false}
          onClick={appPage.onClick}
        >
          <IonIcon slot="start" ios={appPage.icon} md={appPage.icon} />
          <IonLabel>{title}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    );
  });

  return (
    <>
      <IonMenu
        contentId="main"
        type="overlay"
        style={{ display: noMenu ? "none" : undefined }}
      >
        <IonToolbar style={{ "--background": "white" }}></IonToolbar>

        <IonContent>
          <IonList id="inbox-list" style={{ paddingTop: "0em" }}>
            <img
              src="biblemedia-logo.svg"
              style={{ padding: "1em", marginTop: "1em" }}
              alt="logo"
            />
            <div
              style={{
                textAlign: "center",
                padding: "0.5em",
                margin: "0em 1em",
                fontSize: "2em",
              }}
            >
              Bible Media
            </div>
          </IonList>
          <IonList id="inbox-list" style={{ paddingTop: "0em" }}>
            {userOptions.map((appPage, index) => {
              if (appPage.isAvailable && !appPage.isAvailable()) {
                return null;
              }

              let title = appPage.title;
              if (typeof title === "function") {
                title = title();
              }

              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                    onClick={appPage.onClick}
                  >
                    <IonIcon
                      slot="start"
                      ios={appPage.icon}
                      md={appPage.icon}
                    />
                    <IonLabel>{title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
          {availableAdminOptions.filter((option) => option !== null).length >
            0 && <IonList id="inbox-list">{availableAdminOptions}</IonList>}
          <div style={{ padding: "1em", marginTop: "20em" }}>
            <br />
            <div
              onClick={() => {
                delete localStorage.apiUrl;
                window.location.reload();
              }}
            >
              API: {getApiUrl()}
            </div>
            <br />
            <br />
            User: {userStore.user.email} [
            {userStore.user?.userData?.roles?.join(", ")}]
            <br />
            <br />
            {userStore.user.userId}
          </div>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default Menu;
