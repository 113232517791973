import { entities } from "../store/entities";

export function getEntities(
  associatedEntities: any[],
  entityType: string,
  deduplicate = false
) {
  const foundEntityIds = associatedEntities.filter((entityId: any) => {
    const entityContainer = entities.get(entityId);

    return entityContainer?.entity?.entityType === entityType;
  });

  if (deduplicate) {
    return deduplicateEntities(foundEntityIds);
  }

  return foundEntityIds;
}

export function deduplicateEntities(entityIds: any[]) {
  const finalEntityIds: any[] = [];

  entityIds.forEach((entityId) => {
    const seen = finalEntityIds.includes(entityId);

    if (!seen) {
      finalEntityIds.push(entityId);
    }
  });

  return finalEntityIds;
}
