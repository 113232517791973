import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

export const PrivacyPolicy: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="privacy-policy" style={{ padding: "2em" }}>
          <p className="c10">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Effective Date</span>
            <span className="c0">: December 21 2022 </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">DISCLAIMER FOR CANADIAN USERS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              As defined under Canadian law, Personal Information means
              information about an identifiable individual (&ldquo;Personal
              Information&rdquo;). The disclosures mentioned herein are meant to
              transparently convey the methods of collecting, managing, storing,
              using, protecting, and sharing Personal Information by users
              (&ldquo;Privacy Policy&rdquo;). Users grant their consent to this
              Privacy Policy through it being readily available for viewing in
              accordance with the Personal Information Protection and Electronic
              Documents Act (&ldquo;PIPEDA&rdquo;).
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">DISCLAIMER FOR EUROPEAN USERS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">The right to access</span>
            <span className="c0">
              &nbsp;- You have the right to request our company for copies of
              your personal data. We may charge you a small fee for this
              service.
            </span>
          </p>
          <p className="c2 c8">
            <span className="c1 c0"></span>
          </p>
          <p className="c2">
            <span className="c3">The right to rectification</span>
            <span className="c0">
              &nbsp;- You have the right to request that our company correct any
              information you believe is inaccurate. You also have the right to
              request our company to complete the information you believe is
              incomplete.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">The right to erasure</span>
            <span className="c0">
              &nbsp;- You have the right to request that our company erase your
              personal data under certain conditions.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">The right to restrict processing</span>
            <span className="c0">
              &nbsp;- You have the right to request that our company restrict
              the processing of your personal data under certain conditions.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">The right to object to processing</span>
            <span className="c0">
              &nbsp;- You have the right to object to our company&rsquo;s
              processing of your personal data under certain conditions.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">The right to data portability</span>
            <span className="c0">
              &nbsp;- You have the right to request that our company transfer
              the data we have collected to another organization or directly to
              you under certain conditions.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">DISCLAIMER FOR CALIFORNIA USERS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Your privacy and rights under the California Consumer Privacy Act
              (CCPA) and the California Online Privacy Protection Act (CalOPPA)
              are important to us. We offer this document as a resource to view
              how we collect, manage, store, and use your Personal Information
              in the day-to-day running of our website and mobile app. This
              Privacy Policy, intended for California residents, can be applied
              to all website and mobile app users to disclose how we collect,
              manage, store, and use your Personal Information as defined under
              CIV 1798.140(v) of the California Consumer Privacy Act (CCPA).
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">THE BUSINESS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              This Privacy Policy is between you and the following organization:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Company Name</span>
            <span className="c0">: Bible Media LLC </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Website</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Website URL</span>
            <span className="c0">: biblemedia.app </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Website Name</span>
            <span className="c0">: BibleMedia </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Mobile App</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c7 c0">Mobile App Name</span>
            <span className="c0">: BibleMedia </span>
          </p>
          <p className="c2">
            <span className="c0 c7">Available on</span>
            <span className="c0">
              : Apple App Store (iOS) + Google Play Store (Android) &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">PERSONAL INFORMATION COLLECTED</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">
              In the past 12 months, we have or had the intention of collecting
              the following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Identifiers</span>
            <span className="c0">
              . A real name or alias, postal address, signature, home phone
              number or mobile phone number, bank account number, credit card
              number, debit card number or other financial information, physical
              characteristics or description, e-mail address; account name,
              Social Security Number (SSN), driver&#39;s license number or state
              identification card number, passport number, or other similar
              identifiers.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Protected Classification Characteristics</span>
            <span className="c0">
              . Age (40 years or older), race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, sex (including gender, gender
              identity, gender expression, pregnancy or childbirth, and related
              medical conditions), sexual orientation, veteran or military
              status, genetic information (including familial genetic
              information).
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Commercial Information</span>
            <span className="c0">
              . Records of personal property, products or services purchased,
              obtained, considered, or other purchasing or consuming histories
              or tendencies.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">
              Inferences Drawn From Other Personal Information
            </span>
            <span className="c0">
              . Profile reflecting a person&rsquo;s preference, characteristics,
              psychological trends, predispositions, behavior, attitudes,
              intelligence, abilities, and aptitudes.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">
              Internet or Other Similar Network Activity
            </span>
            <span className="c0">
              . Browsing history, search history, and information on a
              consumer&#39;s interaction with a website, application, or
              advertisement.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Geolocation Data</span>
            <span className="c0">
              . Physical location or movements. For example, city, state,
              country, and ZIP code associated with your IP address or derived
              through Wi-Fi triangulation; and, with permission in on your
              mobile device settings, and precise geolocation information from
              GPS-based functionality on your mobile devices. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Hereinafter known as &ldquo;Personal Information.&rdquo;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">SOURCES OF INFORMATION WE COLLECT</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">Using the website and mobile app</span>
            <span className="c0">
              . We collect certain information from your activity on our website
              and mobile app, starting when you first arrive and accessing it on
              an electronic device. We may collect your IP address, device ID,
              advertising identifiers, browser type, operating system, internet
              service provider, pages visited (including clicks and duration),
              and other related log information. For mobile phones, we may
              collect your device&rsquo;s GPS signal or other information about
              nearby Wi-Fi access points and cell towers.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">Creating a User Profile or Account</span>
            <span className="c0">
              . We may collect information directly from you or an agent
              authorized to act on your behalf. For example, if you, or someone
              acting on your behalf, provides your name and e-mail to create a
              profile or an account. We also collect information indirectly from
              you or your authorized agent. This can be done through information
              we collect from you while providing content, products, or
              services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">COOKIES POLICY</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Currently, our website and mobile app uses cookies to provide you
              with the best experience possible. We, in addition to our service
              providers, affiliates, agents, advertisers, or other parties in
              connection with the website and mobile app, may deploy cookies,
              web beacons, local shared objects, and other tracking technologies
              for various purposes. Such shall be for business use, marketing
              purposes, fraud prevention, and to assist in the day-to-day
              operations of the website and mobile app.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">a.)</span>
            <span className="c3">&nbsp;&ldquo;Cookies&rdquo; Defined</span>
            <span className="c0">
              . Cookies act as data that is communicated between a user&rsquo;s
              web browser and a website or application. They are stored on your
              device to help track their areas of interest, provide the best
              experience possible, and customize the content, products,
              services, offerings, and advertisements served on the website and
              mobile app. Most web browsers adjust to your browser&rsquo;s
              settings to decline or delete cookies, but doing so may degrade
              the experience with our online services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">b.)</span>
            <span className="c3">&nbsp;1-Pixel Images</span>
            <span className="c0">
              . Clear GIFs, pixel tags, or web beacons, which are generally
              1-pixel, are transparent images located on a webpage or in an
              e-mail or other trackable source and may be used on our website
              and mobile app in addition to any other communication offered by
              us. They are often used in connection with advertisements served
              to you that are interacted with, whether on our website and mobile
              app or another online service and shared with us. This type of
              tracking is specifically meant to recognize users, assess traffic
              patterns, and measure site or campaign engagement.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">c.)</span>
            <span className="c3">&nbsp;Flash Cookies</span>
            <span className="c0">
              . Local Shared Objects, sometimes known as &ldquo;flash
              cookies,&rdquo; may be stored on your device using a media player
              or other software. Flash cookies are similar to cookies in terms
              of their operation but may be managed in your browser in the same
              manner.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">d.)</span>
            <span className="c3">&nbsp;First (1</span>
            <span className="c3 c5">st</span>
            <span className="c3">) Party &amp; Third (3</span>
            <span className="c3 c5">rd</span>
            <span className="c3">) Cookies</span>
            <span className="c0">. First (1</span>
            <span className="c0 c5">st</span>
            <span className="c0">
              ) party cookies are stored by a domain (website and mobile app)
              you are visiting directly. They allow us to collect analytics
              data, remember preferred settings (e.g., language, currency,
              etc.), and perform related functions. Third (3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              ) party cookies are created by domains other than those you are
              visiting directly, hence its name &ldquo;third (3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              ) party.&rdquo; They may be used for cross-tracking, retargeting,
              and ad-serving.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">e.)</span>
            <span className="c3">&nbsp;Essential Cookies</span>
            <span className="c0">
              . Such cookies are technically necessary to provide website and
              mobile app functionality. They act as a basic form of memory, used
              to store the preferences selected by a user on a given website or
              application. They are essential to browsing functionality and
              cannot be disabled by users. As an example, an essential cookie
              may be used to recognize a past user from having to log in each
              time they visit a new page in the same session.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">f.)</span>
            <span className="c3">&nbsp;Performance and Function Cookies</span>
            <span className="c0">
              . Such cookies are used to enhance the performance and
              functionality of a website and mobile app but are not essential to
              its use. However, without these cookies, certain functions (like
              videos) may become unavailable.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">g.)</span>
            <span className="c3">&nbsp;Advertising Cookies</span>
            <span className="c0">
              . Such cookies are used to customize a user&#39;s ad experience on
              a website and mobile app. When using data collected from cookies,
              it can prevent the same ad from appearing multiple times in the
              same session or that does not offer a pleasant experience.
              Advertising cookies may be used to serve a user with related
              services, products, or offerings that they may have shown a level
              of related interest in their past user history.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you would like to know more about cookies and how they are
              used, please visit www.allaboutcookies.org. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">
              You can set your browser not to accept cookies, and the above
              website tells you how to remove cookies from your browser.
              However, in a few cases, some of our website features may not
              function as a result. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">ADVERTISEMENTS</span>
            <span className="c0">. &nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Our website and mobile app does not show advertisements to users.
              This includes affiliate ads or any products and services offered
              by 3rd parties. &nbsp;
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c3">HOW WE USE PERSONAL INFORMATION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We may use or disclose your Personal Information for the following
              purpose:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Alerts</span>
            <span className="c0">
              . To provide e-mail alerts and other communications regarding our
              products, services, and offerings that may be of interest or that
              you registered to receive.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Obligations</span>
            <span className="c0">
              . To carry out any obligations for paid products, services,
              offerings, or any obligations under our terms and conditions or
              terms of use.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Feedback</span>
            <span className="c0">
              . To get feedback on website and mobile app improvements and
              generally provide an overall better experience.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Testing</span>
            <span className="c0">
              . For testing, research, and analysis, of user behavior on the
              website and mobile app.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Protection</span>
            <span className="c0">
              . To protect against fraud, safeguard data, and the general
              security of the website and mobile app.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Security</span>
            <span className="c0">
              . To detect security incidents, verify human users, and avoid
              being subject to malicious, deceptive, fraudulent, or illegal
              activity.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Law Enforcement</span>
            <span className="c0">
              . To respond to law enforcement requests as required by applicable
              law, court order, or governmental regulations.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Intended Purpose</span>
            <span className="c0">
              . As described for the intended purpose when collecting your
              personal information.
            </span>
          </p>
          <p className="c2">
            <span className="c0">-</span>
            <span className="c3">Assessment</span>
            <span className="c0">
              . To evaluate or conduct a merger, divestiture, restricting,
              reorganizing, dissolution, or outright sale, either wholly or
              partially, of our assets in which your Personal Information
              becomes a part of such sale.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              Our usage of your Personal Information may change over time, and
              when such changes occur, we will update this Privacy Policy
              accordingly.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">SELLING PERSONAL INFORMATION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">Our policy is that we </span>
            <span className="c3">DO NOT</span>
            <span className="c0">
              &nbsp;sell your personal information. If this should change, you
              will be notified and this Privacy Policy will be updated.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">SHARING PERSONAL INFORMATION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We disclose your Personal Information to 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;parties for business purposes. The general categories of 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;parties that we share with are as follows:{" "}
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">-Our 3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party service providers that, without their services, our
              website and mobile app would not be able to function in its
              current manner;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Affiliated websites and businesses in an effort to bring you and
              our users improved services, products, and offerings;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Other companies, affiliate partners, and 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;parties that help us advertise products, services, and
              offerings to you, other users, and potential new customers;
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Third (3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              ) parties to whom you, or an authorized agent on your behalf,
              authorized us to disclose your Personal Information;
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Third (3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              ) parties or affiliates in connection with a corporate
              transaction, such as a sale, consolidation, or merger of our
              financial institution or affiliated business; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Other third (3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              ) parties to comply with legal requirements or to disclose
              Personal Information to government authorities per the rule of
              law.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              In the last 12 months, it is recognized that we have disclosed the
              aforementioned categories of Personal Information for business
              purposes.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">RIGHT AND CHOICES</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              This Section describes your rights and choices regarding how we
              collect, share, use, and protect your Personal Information, how to
              exercise those rights, and limits and exceptions to your rights
              and choices.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">a.)</span>
            <span className="c3">&nbsp;Exceptions</span>
            <span className="c0">
              . The rights and choices in this Section do not apply to you if
              the information being collected is:
            </span>
          </p>
          <p className="c2">
            <span className="c0">-Aggregate consumer information;</span>
          </p>
          <p className="c2">
            <span className="c0">-Deidentified Personal Information; and</span>
          </p>
          <p className="c2">
            <span className="c0">-Publicly available information. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">b.)</span>
            <span className="c3">&nbsp;Access to Information</span>
            <span className="c0">
              . If the above exceptions do not apply, and you have not made this
              request more than twice in a 12-month period, you have the right
              to request that we disclose certain information to you about our
              collection and use of your Personal Information over the past 12
              months from the date we receive your request. Once we receive and
              confirm your request on your behalf, we will disclose it to you or
              your representative:
            </span>
          </p>
          <p className="c2">
            <span className="c3">&nbsp;</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">
              -The categories of Personal Information we collect;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The categories of sources for the Personal Information we
              collect;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Our business or commercial purpose for collecting or selling such
              Personal Information;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The categories of third parties to whom we sold or disclosed the
              category of Personal Information for a business or commercial
              purpose;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The business or commercial purpose for which we sold or disclosed
              the category of Personal Information; and
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -The specific pieces of Personal Information we collected about
              you in a form that you can take with you (also called a
              &ldquo;Data Portability Request&rdquo;).
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">c.)</span>
            <span className="c3">&nbsp;Deletion (Erasure) Request Rights</span>
            <span className="c0">
              . You have the right to request that we delete any of your
              Personal Information that we collect from you and retain, subject
              to certain exceptions. Once we receive and verify your request, we
              will delete and direct our service providers to delete your
              Personal Information from our records unless an exception applies.
              We may deny your deletion request if retaining the Personal
              Information is necessary for us or our service providers to:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Complete the transaction for which we collected the Personal
              Information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform our contract
              with you;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those for such
              activity;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Debug to identify and repair errors that impair existing intended
              functionality;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Exercise free speech, or exercise another right provided by law;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws when the businesses&#39;
              deletion of the Personal Information is likely to render
              impossible or seriously impair the achievement of such research if
              you previously provided informed consent.
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Enable solely internal and lawful uses of such Personal
              Information that are compatible with the context in which you
              provided it.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">d.)</span>
            <span className="c3">
              &nbsp;Exercising Access, Data Portability, and Deletion Rights
            </span>
            <span className="c0">
              . To exercise the access, data portability, deletion rights, or
              any other rights mentioned herein, a consumer or a consumer&#39;s
              authorized agent may submit a verifiable request to us by using
              the contact details mentioned herein.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">e.)</span>
            <span className="c3">&nbsp;Requests</span>
            <span className="c0">
              . You may only make a verifiable consumer request for access or
              data portability in relation to this Section. We cannot respond to
              your request or provide you with Personal Information if we cannot
              verify your identity or authority to make the request and confirm
              the Personal Information is related to you.
            </span>
          </p>
          <p className="c2">
            <span className="c3">&nbsp;</span>
            <span className="c0">&nbsp;</span>
          </p>
          <p className="c2">
            <span className="c0">
              Making a verifiable consumer request does not require you to
              create an account with us. We will only use Personal Information
              provided via a verifiable consumer request to verify the
              requestor&#39;s identity or authority to make the request.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">f.)</span>
            <span className="c3">&nbsp;Authorized Agent</span>
            <span className="c0">
              . An authorized agent is any person or legal entity registered
              with the Secretary of State or similar office that you have
              authorized to act on your behalf. If a request is made by an
              authorized agent acting on your behalf, we may require the
              following:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Submission of evidence by you with your permission for the
              authorized agent to submit a verifiable request on your behalf;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -For you to directly acknowledge, via electronic communication,
              that the authorized agent is allowed to act on your behalf;
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Require the authorized agent to verify their identity; or
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -For a power of attorney document to be submitted that is signed
              in accordance with state law.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We reserve the right to deny a request from an agent that does not
              submit proof that they have been authorized by you to act on your
              behalf and cannot verify their own identity to us.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">g.)</span>
            <span className="c3">&nbsp;Response Timing and Format</span>
            <span className="c0">
              . As required under privacy laws, we agree to respond to any
              verifiable consumer request within 45 days of its receipt. If we
              require more time, with a maximum of 90 days, we will inform you,
              in writing, of the reason. Such notification will be by e-mail
              unless there is another preferred communication method provided.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If applicable, the response we provide will also explain the
              reasons we cannot comply with a request. For data portability
              requests, we will select a format to provide your Personal
              Information that is readily useable and should allow you to
              transmit the Personal Information from one entity to another
              entity without hindrance.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              No fee will be charged to process or respond to your verifiable
              consumer request.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">h.)</span>
            <span className="c3">&nbsp;Right of Non-Discrimination</span>
            <span className="c0">
              . We do not discriminate against you for exercising any of your
              rights in this Privacy Policy and under applicable laws. Unless
              permitted by law, we will not:
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">-Deny you goods or services;</span>
          </p>
          <p className="c2">
            <span className="c0">
              -Charge you different prices or rates for goods, services, and
              offerings, including through granting discounts or other benefits,
              imposing penalties; or
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              -Provide you with a different level of quality of goods or
              services.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">CHANGES AND AMENDMENTS</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We reserve the right to amend this Privacy Policy at our
              discretion and at any time. When we make changes to this Privacy
              Policy, we agree to notify you by e-mail or other preferred
              communication methods.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">LINKING TO 3</span>
            <span className="c3 c5">RD</span>
            <span className="c3">&nbsp;PARTIES</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">We may provide links to 3</span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;party sources such as websites, applications, content, or
              software (&ldquo;3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;Parties&rdquo;). When you use a link online to visit 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">
              &nbsp;Parties, you will be subject to their privacy policy and the
              jurisdiction of governing law. It is recommended to familiarize
              yourself with its terms and disclosures regarding your Personal
              Information. We are not responsible for the handling of your
              Personal Information when using, accessing, or visiting 3
            </span>
            <span className="c0 c5">rd</span>
            <span className="c0">&nbsp;Parties. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">SECURITY &amp; PROTECTION</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              We use reasonable physical, electronic, and procedural safeguards
              that comply with federal standards to protect and limit access to
              Personal Information. This includes device safeguards used in
              accordance with industry standards.
            </span>
          </p>
          <p className="c2">
            <span className="c0">
              It is understood by you that the Personal Information you submit
              to us electronically may not be secure when it is transmitted to
              us. Specifically, we recommend that you do not use unsecured or
              public channels to communicate sensitive or confidential
              information.
            </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c3">CONTACT</span>
            <span className="c0">. </span>
          </p>
          <p className="c2">
            <span className="c0">&nbsp; </span>
          </p>
          <p className="c2">
            <span className="c0">
              If you have any questions or comments about this Privacy Policy,
              the ways in which we collect and use your Personal Information,
              your choices, or your rights regarding such use, or wish to
              exercise your rights, please do not hesitate to contact us by
              using the details mentioned in this Privacy Policy.
            </span>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};
