import { AppRate, AppRatePromptType } from "@ionic-native/app-rate";

export const reviewService = {
  shouldPromptForReview: () => {
    const completedIntroTimestamp = reviewService.getCompletedIntroTimestamp();

    // console.log(
    //   "Check review prompt",
    //   Date.now() - (60_000 * 3 + completedIntroTimestamp)
    // );

    if (
      !completedIntroTimestamp ||
      completedIntroTimestamp + 60_000 * 3 > Date.now()
    ) {
      return false;
    }

    if (reviewService.getHasTakenAction()) {
      return false;
    }

    return true;
  },

  getHasTakenAction() {
    const { reviewGiven, reviewDeclined } = localStorage;
    return reviewDeclined || reviewGiven;
  },

  getCompletedIntroTimestamp() {
    const completedIntroTimestamp = Number(
      localStorage.completedIntroTimestamp
    );

    if (isNaN(completedIntroTimestamp)) {
      return 0;
    }

    return completedIntroTimestamp;
  },

  promptForReview: () => {
    localStorage.reviewGiven = "";
    localStorage.reviewDeclined = "";

    AppRate.setPreferences({
      usesUntilPrompt: 9999999, // We will manually handle the prompt
      storeAppURL: {
        ios: "1668009167",
        android: "market://details?id=app.biblemedia.biblemedia>",
        // windows: 'ms-windows-store://review/?ProductId=<store_id>'
      },
      customLocale: {
        appRatePromptTitle: "Do you like using Bible Media?",
      },
      callbacks: {
        onButtonClicked: (
          buttonIndex: number,
          buttonLabel: string,
          promptType: AppRatePromptType
        ) => {
          let completedIntroTimestamp =
            reviewService.getCompletedIntroTimestamp();

          switch (buttonLabel) {
            case "Remind Me Later":
              completedIntroTimestamp += 86400 * 1000; // Check tomorrow
              localStorage.completedIntroTimestamp = completedIntroTimestamp;
              console.log("Review postponed");
              break;
            case "Rate It Now":
              localStorage.reviewGiven = 1;
              console.log("Review given");
              break;
            case "Not really":
            case "No, Thanks":
              localStorage.reviewDeclined = 1;
              console.log("Review declined");
              break;
          }
        },
        done: () => {
          console.log("REVIEW DONE");
        },
        handleNegativeFeedback: () => {
          console.log("REVIEW CANCELLED");
        },
      },
    });

    AppRate.promptForRating();
  },
};
