import { EditGenreModal } from "../../components/modals/EditGenreModal";
import BrowseEntityType from "../BrowseEntityType";

const Genres: React.FC = () => {
  return (
    <BrowseEntityType
      title="Genres"
      entityType="genre"
      EditModalComponent={EditGenreModal}
    />
  );
};

export default Genres;
