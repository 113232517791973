import { MenuOption } from "../../types/menu";
import {
  bookOutline,
  settingsOutline,
  logInOutline,
  mailOutline,
  bookmarkOutline,
  homeOutline,
  add,
  newspaperOutline,
  notificationsOutline,
  chevronForwardCircleOutline,
} from "ionicons/icons";
import { userService } from "../../lib/userService";

export const userOptions: MenuOption[] = [
  {
    title: "Bible",
    url: "/bible",
    icon: bookOutline,
  },
  {
    title: "Home",
    url: "/home",
    icon: homeOutline,
  },
  {
    title: "Discover",
    url: "/discover",
    icon: newspaperOutline,
    isAvailable: () => {
      return userService.isLoggedIn();
    },
  },
  {
    title: "Notifications",
    url: "/notifications",
    icon: notificationsOutline,
    isAvailable: () => {
      return userService.isLoggedIn();
    },
  },
  // {
  //   title: "Search",
  //   url: "/search",
  //   //   icon: searchOutline,
  // },
  {
    title: "Settings",
    url: "/settings",
    icon: settingsOutline,
    isAvailable: () => {
      return userService.isLoggedIn();
    },
  },
  {
    title: "Saved",
    url: "/saved",
    icon: bookmarkOutline,
    isAvailable: () => {
      return userService.isLoggedIn();
    },
  },
  {
    title: "Subscriptions",
    url: "/subscriptions",
    icon: mailOutline,
    isAvailable: () => {
      return userService.isLoggedIn();
    },
  },
  {
    title: "Login",
    url: "/login",
    icon: logInOutline,
    isAvailable: () => {
      return !userService.isLoggedIn();
    },
  },
  {
    title: "Register",
    url: "/register",
    icon: add,
    isAvailable: () => {
      return !userService.isLoggedIn();
    },
  },
  {
    title: "Terms of Service",
    url: "/terms-of-service",
    isAvailable: () => {
      return !userService.isLoggedIn();
    },
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
    isAvailable: () => {
      return !userService.isLoggedIn();
    },
  },
  {
    title: "Feedback",
    url: "/feedback",
    icon: chevronForwardCircleOutline,
  },
];
