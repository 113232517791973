import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./styles/custom.css";
import "./styles/swiper.css";
import "./styles/privacyPolicy.css";
import "./styles/termsOfService.css";

import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "@ionic/react/css/ionic-swiper.css";
import "swiper/css/pagination";

/* Theme variables */
import "./theme/variables.css";
import Settings from "./pages/Settings";
import { Bible } from "./pages/Bible";
import Songs from "./pages/content/Songs";
import Sermons from "./pages/content/Sermons";
import Themes from "./pages/content/Themes";
import Questions from "./pages/content/Questions";
import { useState } from "react";
import { rerender, setRenderFunction } from "./lib/rerender";
import Entity from "./pages/Entity";
import { modalStore } from "./store/modalStore";
import Login from "./pages/public/Login";
import { userService } from "./lib/userService";
import Register from "./pages/public/Register";
import EditUsers from "./pages/admin/EditUsers";
import ApproveEntityChanges from "./pages/admin/ApproveEntityChanges";
import { setPresentAlertFunction, setPresentToastFunction } from "./lib/toast";
import { systemService } from "./lib/systemService";
import { systemStore } from "./store/systemStore";
import { RequireRoles } from "./components/RequireRoles";
import Workshop from "./pages/admin/Workshop";
import Feed from "./pages/Feed";
import Verse from "./pages/Verse";
import Verses from "./pages/content/Verses";
import Genres from "./pages/content/Genres";
import Artists from "./pages/content/Artists";
import Search from "./pages/Search";
import Speakers from "./pages/content/Speakers";
import Reports from "./pages/admin/Reports";
import { Subscriptions } from "./pages/Subscriptions";
import Saved from "./pages/Saved";
import Home from "./pages/Home";
import { Discover } from "./pages/Discover";
import VerseOfTheDay from "./pages/admin/VerseOfTheDay";
import { Notifications } from "./pages/Notifications";
import { ResetPassword } from "./pages/ResetPassword";
import { VerifyEmail } from "./pages/VerifyEmail";
import { ForgotPassword } from "./pages/public/ForgotPassword";
import { Intro1 } from "./pages/public/Intro1-Register";
import { Intro2 } from "./pages/public/Intro2-VerifyEmail";
import { Intro3 } from "./pages/public/Intro3-Finish";
import { Share } from "@capacitor/share";
import { PrivacyPolicy } from "./pages/public/PrivacyPolicy";
import { TermsOfService } from "./pages/public/TermsOfService";
import { Support } from "./pages/public/Support";
import { Feedback } from "./pages/public/Feedback";
import { FeedbackAdmin } from "./pages/admin/FeedbackAdmin";
import { pushNotificationService } from "./lib/pushNotificationsService";
import { AdminDebug } from "./pages/admin/AdminDebug";
import { SettingsNotifications } from "./pages/SettingsNotifications";
import { ConnectionTrouble } from "./components/ConnectionTrouble";
import { Device } from "@capacitor/device";

import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import { reviewService } from "./lib/reviewService";

CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
  const url = new URL(event.url);

  const route = `${url.pathname}${url.search}`;
  console.log(route);

  window.location.href = route;
});

setupIonicReact();

(async () => {
  console.log("Starting up");

  // Things to skip even when the intro hasn't been viewed
  if (
    !localStorage.completedIntro &&
    !window.location.href.includes("/intro/") &&
    !window.location.href.includes("/verifyEmail") &&
    !window.location.href.includes("/entity") &&
    !window.location.href.includes("/verse")
  ) {
    // Start the intro
    window.location.href = "/intro/1";
    return;
  }

  // However the user got to this point, we want the intro to be marked as completed
  localStorage.completedIntro = true;

  // To detect a delayed loading
  const delayedLoadingTimer = setTimeout(() => {
    systemService.setDelayedLoading();
  }, 3000);

  await userService.confirmLogin({ refreshToken: true });
  await systemService.getRoles();

  // Simulating delayed loading
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 100);
  });

  clearTimeout(delayedLoadingTimer);

  systemStore.deviceInfo = await Device.getInfo();
  systemStore.deviceId = (await Device.getId()).uuid;
  systemStore.canShare = (await Share.canShare()).value;

  systemService.setReady();

  if (!systemStore.canShare) {
    // Do an extra check to determine if the share button should be shown
    if (window.location.href.indexOf("localhost") >= 0) {
      systemStore.canShare = true;
    }
  }

  (async function () {
    console.log("Adding listeners for push notifications");
    // Get push notification listeners setup
    await pushNotificationService.addListeners();

    // Try to associate the current token with the device
    console.log("PN check");
    if (await pushNotificationService.isAvailable()) {
      console.log("PN available");
      if (await pushNotificationService.isRegistered()) {
        console.log("LOGIN registering push notifications");
        await pushNotificationService.registerNotifications();
      }
    }
  })();

  rerender();
})();

/**
 * Check for if we should show the review prompt
 */
let hasPromptedThisSession = false;
function checkTimeForReviewPrompt() {
  if (hasPromptedThisSession) {
    // Don't want to keep prompting
    return;
  }

  if (reviewService.shouldPromptForReview()) {
    // Only prompt once per app session
    hasPromptedThisSession = true;

    reviewService.promptForReview();
  }

  setTimeout(() => {
    checkTimeForReviewPrompt();
  }, 60_000);
}

if (!reviewService.getHasTakenAction()) {
  checkTimeForReviewPrompt();
}

const App: React.FC = () => {
  const [_renderValue, rerender] = useState({});
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const { ready } = systemStore;
  // const { user } = userStore;

  // Setup some functions for global stuff
  setRenderFunction(rerender);
  setPresentToastFunction(presentToast);
  setPresentAlertFunction(presentAlert);

  if (!ready) {
    return <ConnectionTrouble _renderValue={_renderValue} />;
  }

  return (
    <IonApp>
      <IonReactRouter>
        {/* {user && user.loggedIn && !user.userData.emailVerified && (
          <EmailValidationRequired />
        )} */}
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            {/*
            ========================
            PUBLIC
            ========================
            */}
            <Route path="/" exact={true}>
              <Home />
            </Route>
            <Route path="/login" exact={true}>
              <Login />
            </Route>
            <Route path="/forgotPassword" exact={true}>
              <ForgotPassword />
            </Route>
            <Route path="/resetPassword" exact={true}>
              <ResetPassword />
            </Route>
            <Route path="/register" exact={true}>
              <Register />
            </Route>
            <Route path="/entity/:entityId/:slug" exact={true}>
              <Entity />
            </Route>
            <Route path="/home" exact={true}>
              <Home />
            </Route>
            <Route path="/search" exact={true}>
              <Search />
            </Route>
            <Route path="/bible" exact={true}>
              <Bible />
            </Route>
            <Route path="/discover" exact={true}>
              <Discover />
            </Route>
            <Route path="/privacy-policy" exact={true}>
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-of-service" exact={true}>
              <TermsOfService />
            </Route>
            <Route path="/support" exact={true}>
              <Support />
            </Route>

            {/*
            ========================
            USER
            ========================
            */}
            <Route path="/verifyEmail" exact={true}>
              <VerifyEmail />
            </Route>
            <Route path="/feed" exact={true}>
              <Feed />
            </Route>
            <Route path="/settings" exact={true}>
              <Settings />
            </Route>
            <Route path="/settings-notifications" exact={true}>
              <SettingsNotifications />
            </Route>
            <Route path="/saved" exact={true}>
              <Saved />
            </Route>
            <Route path="/notifications" exact={true}>
              <Notifications />
            </Route>
            <Route path="/subscriptions" exact={true}>
              <Subscriptions />
            </Route>
            <Route path="/feedback" exact={true}>
              <Feedback />
            </Route>

            <Route path="/verse/:verseId" exact={true}>
              <Verse />
            </Route>

            {/* 
            ========================
            INTRO
            ========================
            */}
            <Route path="/intro/1" exact={true}>
              <Intro1 />
            </Route>
            <Route path="/intro/2" exact={true}>
              <Intro2 />
            </Route>
            <Route path="/intro/3" exact={true}>
              <Intro3 />
            </Route>

            {/*
            ========================
            ADMIN
            ========================
            */}
            <Route path="/feedback-admin" exact={true}>
              <FeedbackAdmin />
            </Route>
            <Route path="/admin-debug" exact={true}>
              <RequireRoles requiredRoles={["admin"]}>
                <AdminDebug />
              </RequireRoles>
            </Route>
            <Route path="/workshop" exact={true}>
              <RequireRoles
                requiredRoles={[["contributor"], ["view-workshop"]]}
              >
                <Workshop />
              </RequireRoles>
            </Route>
            <Route path="/edit-users" exact={true}>
              <RequireRoles requiredRoles={["edit-users"]}>
                <EditUsers />
              </RequireRoles>
            </Route>
            <Route path="/verseoftheday" exact={true}>
              <RequireRoles requiredRoles={["edit-associations"]}>
                <VerseOfTheDay />
              </RequireRoles>
            </Route>
            <Route path="/approve-entity-changes" exact={true}>
              <RequireRoles requiredRoles={["approve-associations"]}>
                <ApproveEntityChanges />
              </RequireRoles>
            </Route>
            <Route path="/reports" exact={true}>
              <Reports />
            </Route>
            <Route path="/songs" exact={true}>
              <Songs />
            </Route>
            <Route path="/questions" exact={true}>
              <Questions />
            </Route>
            <Route path="/sermons" exact={true}>
              <Sermons />
            </Route>
            <Route path="/themes" exact={true}>
              <Themes />
            </Route>
            <Route path="/verses" exact={true}>
              <Verses />
            </Route>
            <Route path="/genres" exact={true}>
              <Genres />
            </Route>
            <Route path="/artists" exact={true}>
              <Artists />
            </Route>
            <Route path="/speakers" exact={true}>
              <Speakers />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>

      {modalStore.modals.map((modalInstance) => {
        return (
          <modalInstance.Component
            key={modalInstance.props.id}
            {...modalInstance.props}
          />
        );
      })}
    </IonApp>
  );
};

export default App;

// function EmailValidationRequired() {
//   const router = useIonRouter();
//   const { user } = userStore;

//   useEffect(() => {
//     if (user.userData && user.userData.emailVerified) {
//       // No user data yet or already verified, don't do anything
//       return;
//     }

//     let timer: any = null;

//     async function check() {
//       const user = await userService.getUser();

//       if (user?.userData?.emailVerified) {
//         // The email is verified, so just rerender to pickup the change
//         router.push("/intro/3");
//         return;
//       }

//       timer = setTimeout(check, 5000);
//     }

//     check();

//     return () => {
//       clearTimeout(timer);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   if (router.routeInfo.pathname.includes("intro")) {
//     // Don't show the email validation when in the intro
//     return null;
//   }

//   if (!userService.isLoggedIn()) {
//     return null;
//   }

//   if (user?.userData?.emailVerified) {
//     return null;
//   }

//   return (
//     <div
//       style={{
//         position: "absolute",
//         height: "100%",
//         width: "100%",
//         background: "rgba(0,0,0,0.85)",
//         zIndex: 10000000,
//         color: "white",
//         padding: "1em",
//         paddingTop: "4em",
//       }}
//     >
//       Please verify your email before using the app
//       <br />
//       <br />
//       <IonButton
//         onClick={() => {
//           userService.sendEmailVerification();
//         }}
//       >
//         Resend verification email
//       </IonButton>
//       <div style={{ padding: "1em 0em" }}>Or</div>
//       <IonButton
//         onClick={() => {
//           window.location.reload();
//         }}
//       >
//         Reload
//       </IonButton>
//       <div style={{ padding: "1em 0em" }}>Or</div>
//       <IonButton
//         onClick={() => {
//           userService.logout();
//         }}
//       >
//         Logout
//       </IonButton>
//     </div>
//   );
// }
