import { EditArtistModal } from "../../components/modals/EditArtistModal";
import BrowseEntityType from "../BrowseEntityType";

const Artists: React.FC = () => {
  return (
    <BrowseEntityType
      title="Artists"
      entityType="artist"
      EditModalComponent={EditArtistModal}
    />
  );
};

export default Artists;
