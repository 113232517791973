import { IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { entityTypeConfigs } from "../../../lib/entityTypeConfigs";
import { YouTubeImage } from "../../YoutubeImage";
import { VideoMediaHero } from "./common/VideoMediaHero";

export function DisplaySongEntity({ entityContainer, prominence }: any) {
  const { entity } = entityContainer;
  const { entityData } = entity;

  if (prominence === "hero") {
    return (
      <VideoMediaHero
        entityContainer={entityContainer}
        featuredEntityType="artist"
      />
    );
  }

  if (prominence === "primary") {
    return (
      <div style={{ display: "flex", margin: "0em 0.75em", marginTop: "1em" }}>
        <YouTubeImage
          link={entityData.link}
          alt={entity.entityName}
          style={{
            width: "120px",
            height: "100px",
          }}
        />

        <div style={{ marginLeft: "1em" }}>
          <IonCardTitle>{entityData.name}</IonCardTitle>
          <IonCardSubtitle
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
              marginTop: "0.5em",
            }}
          >
            <IonIcon icon={entityTypeConfigs.artist.icon} />
            {entityData.artistName}
          </IonCardSubtitle>

          {entityData.description}
        </div>
      </div>
    );
  }

  if (prominence === "secondary") {
    return (
      <div
        style={{
          width: "15em",
          marginRight: "2em",
        }}
      >
        <YouTubeImage
          link={entityData.link}
          alt={entity.entityName}
          style={{ width: "15em", height: "7.5em" }}
        />
        <IonCardTitle style={{ marginTop: "0.35em", marginBottom: "0.35em" }}>
          {entityData.name}
        </IonCardTitle>
        <IonCardSubtitle
          style={{ display: "flex", gap: "0.5em", alignItems: "center" }}
        >
          <IonIcon icon={personCircle} style={{ fontSize: "1.5em" }} />
          {entityData.artistName}
        </IonCardSubtitle>
      </div>
    );
  }

  return null;
}
