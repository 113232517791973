import { IonIcon, IonItem, IonLabel, useIonRouter } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { ReactElement } from "react";

interface Props {
  label: string | ReactElement;
  navigateTo: string;
}

export function NextPageSetting({ label, navigateTo }: Props) {
  const router = useIonRouter();

  return (
    <IonItem
      onClick={() => {
        router.push(navigateTo);
      }}
    >
      <IonLabel>{label}</IonLabel>
      <IonIcon slot="end" icon={chevronForward} />
    </IonItem>
  );
}
