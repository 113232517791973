import { useIonRouter } from "@ionic/react";
import { DisplayArtistEntity } from "./DisplayArtistEntity";
import { DisplayGenreEntity } from "./DisplayGenreEntity";
import { DisplayQuestionEntity } from "./DisplayQuestionEntity";
import { DisplaySermonEntity } from "./DisplaySermonEntity";
import { DisplaySongEntity } from "./DisplaySongEntity";
import { DisplaySpeakerEntity } from "./DisplaySpeakerEntity";
import { DisplayThemeEntity } from "./DisplayThemeEntity";
import { DisplayVerseEntity } from "./DisplayVerseEntity";

interface Props {
  entityContainer: any;
  prominence: "hero" | "primary" | "secondary";
}

export function EntityWrapper(props: Props) {
  const router = useIonRouter();

  const { entityContainer } = props;
  let Component = null;

  const { entityId, entityType } = entityContainer.entity;

  switch (entityType) {
    case "verse":
      Component = DisplayVerseEntity;
      break;
    case "theme":
      Component = DisplayThemeEntity;
      break;
    case "question":
      Component = DisplayQuestionEntity;
      break;
    case "song":
      Component = DisplaySongEntity;
      break;
    case "sermon":
      Component = DisplaySermonEntity;
      break;
    case "genre":
      Component = DisplayGenreEntity;
      break;
    case "artist":
      Component = DisplayArtistEntity;
      break;
    case "speaker":
      Component = DisplaySpeakerEntity;
      break;
  }

  if (!Component) {
    return null;
  }

  return (
    <span
      onClick={(event) => {
        const name = encodeURIComponent(entityContainer.entity.entityName);

        if (event.ctrlKey || event.metaKey) {
          window.open(`/entity/${entityId}/${name || ""}`);
        } else {
          router.push(`/entity/${entityId}/${name || ""}`);
        }
      }}
    >
      <Component {...props} />
    </span>
  );
}
