import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { EntityWrapper } from "../components/entities/display/EntityWrapper";
import { Loading } from "../components/Loading";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";

export const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<any>([]);
  const [page, setPage] = useState(0);

  async function getNotifications() {
    const bookmarks = await userService.getNotifications(page);
    setNotifications(bookmarks);
  }

  const [loading] = useWillEnterWithLoading(async () => {
    getNotifications();
    setPage(0);
  }, 400);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="toolbar-center">Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading}>
        <IonContent fullscreen>
          <IonList style={{ padding: "1em" }}>
            {notifications.map((notificationEntityContainer: any) => {
              return (
                <div
                  key={notificationEntityContainer.entity.entityId}
                  style={{ marginBottom: "2em" }}
                >
                  {notificationEntityContainer.notificationData.text}
                  <EntityWrapper
                    entityContainer={notificationEntityContainer}
                    prominence={"primary"}
                  />
                </div>
              );
            })}
          </IonList>
        </IonContent>
      </Loading>
    </IonPage>
  );
};
