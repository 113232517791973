import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useRef, useState } from "react";
import { BookChapterVerseSelector } from "../components/BookChapterVerseSelector";
import { Loading } from "../components/Loading";
import { bookChapterCounts, books } from "../data/books";
import { entityService } from "../lib/entityService";
import { userService } from "../lib/userService";
import { useWillEnterWithLoading } from "../lib/useWillEnterWithLoading";

export const Bible: React.FC = () => {
  const lastBibleChapter = userService.getLastBibleChapter();

  const [book, setBook] = useState(lastBibleChapter.book);
  const [chapter, setChapter] = useState(lastBibleChapter.chapter);
  const [chapterVerses, setChapterVerses] = useState([]);
  const router = useIonRouter();
  const changing = useRef(false);

  async function getChapter(newBook: number, newChapter: number) {
    if (newChapter < 0) {
      newBook--;
      newChapter = bookChapterCounts[newBook] - 1;
    } else if (newChapter > bookChapterCounts[newBook] - 1) {
      newBook++;
      newChapter = 0;
    }

    changing.current = true;

    const verses = await entityService.getChapterVerses(
      newBook + 1,
      newChapter + 1
    );

    setChapterVerses(verses);
    setBook(newBook);
    setChapter(newChapter);
    userService.saveLastBibleChapter(newBook, newChapter);

    setTimeout(() => {
      changing.current = false;
    });
  }

  // Loading handler
  const [loading] = useWillEnterWithLoading(async () => {
    // Have to get the most recent values here. This callback
    // holds on to the state values if those are used
    const lastBibleChapter = userService.getLastBibleChapter();
    getChapter(lastBibleChapter.book, lastBibleChapter.chapter);
  }, 400);

  let canPrevious = true;
  let canNext = true;

  if (book === 0 && chapter < 1) {
    canPrevious = false;
  }

  if (book === books.length - 1 && chapter >= bookChapterCounts[book] - 1) {
    canNext = false;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <div className="toolbar-center">
            {/* <div>{localSettingsStore.bibleVersion.toUpperCase()}</div> */}

            <BookChapterVerseSelector
              mode="chapter"
              canPrevious={canPrevious}
              canNext={canNext}
              book={book}
              chapter={chapter}
              onBookChange={(newBook) => {
                setBook(newBook);
                getChapter(newBook, 0);
              }}
              onChapterChange={(newChapter) => {
                setChapter(newChapter);
                getChapter(book, newChapter);
              }}
              onVersionChange={() => {
                getChapter(book, chapter);
              }}
            />
          </div>
        </IonToolbar>
      </IonHeader>

      <Loading loading={loading}>
        <IonContent fullscreen>
          <table className="full-bible-chapter">
            <tbody>
              {chapterVerses.map((verse: any) => {
                return (
                  <tr
                    key={verse.verse}
                    className="verse-container"
                    onClick={() => {
                      router.push(`/verse/${verse.verseId}`);
                    }}
                  >
                    <td className="chapter-verse">
                      {verse.chapter}:{verse.verse}
                    </td>
                    <td className="verse-text">
                      {verse.text}
                      <div>
                        <EntityTypeIndicators verse={verse} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </IonContent>
      </Loading>
    </IonPage>
  );
};

function EntityTypeIndicators({ verse }: any) {
  return (
    <>
      {Object.keys(verse.entityCounts).map((type) => {
        const count = verse.entityCounts[type];
        if (count === 0) {
          return null;
        }

        return (
          <span
            key={type}
            style={{
              display: "inline-block",
              width: "0.5em",
              height: "0.5em",
              borderRadius: "0.25em",
              marginRight: "0.25em",
              background: `var(--${type})`,
            }}
          ></span>
        );
      })}
    </>
  );
}
