import { PushNotifications } from "@capacitor/push-notifications";
import { systemStore } from "../store/systemStore";
import { makeRequest } from "./api";
import { rerender } from "./rerender";
import { userService } from "./userService";

export const pushNotificationService = {
  addPushNotificationToken: async (token: string) => {
    const deviceInfo = systemStore.deviceInfo;
    const deviceId = systemStore.deviceId;

    // Make sure the user has a basic account for the user's device
    if (!userService.isLoggedIn()) {
      await userService.registerDevice();
    }

    const payload = {
      pushNotificationToken: token,
      deviceInfo,
      token: localStorage.token,
      deviceId,
    };

    console.log("REGISTERING PN FOR USER", payload);

    await makeRequest("user.addPushNotificationToken", payload);
  },

  adminAddPushNotificationToken: async (token: string, userId: string) => {
    await makeRequest("admin.adminAddPushNotificationToken", {
      pushNotificationToken: token,
      token: localStorage.token,
      userId,
    });
  },

  addListeners: async () => {
    if (!pushNotificationService.isAvailable()) {
      console.log("PN not available");
      return;
    }

    console.log("Add PN registration listener");
    /**
     * Called upon successful push notification registration
     */
    await PushNotifications.addListener(
      "registration",
      (pushNotificationToken) => {
        console.log("Registration token: ", pushNotificationToken.value);

        pushNotificationService.addPushNotificationToken(
          pushNotificationToken.value
        );
      }
    );

    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        if (notification.notification.data["gcm.notification.path"]) {
          window.location.pathname =
            notification.notification.data["gcm.notification.path"];
        }
      }
    );
  },

  isAvailable: async () => {
    try {
      await PushNotifications.checkPermissions();

      // No error thrown
      return true;
    } catch (err) {
      // Error thrown, so not available
      return false;
    }
  },

  isRegistered: async () => {
    return (await PushNotifications.checkPermissions()).receive === "granted";
  },

  registerNotifications: async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  },

  getDeliveredNotifications: async () => {
    // const notificationList =
    //   await PushNotifications.getDeliveredNotifications();
    // console.log("delivered notifications", notificationList);
  },
};

(async function () {
  const available = await pushNotificationService.isAvailable();
  const registered = await pushNotificationService.isRegistered();
  systemStore.pushNotificationsAvailable = available;
  systemStore.pushNotificationsRegistered = registered;
  rerender();
})();
