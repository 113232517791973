import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { notifications, notificationsOutline } from "ionicons/icons";
import { userService } from "../lib/userService";
import { entities } from "../store/entities";

export function SubscribeButton({
  entityId,
  container,
  slot,
  onChange = () => {},
  iconOnly = false,
}: any) {
  if (!container) {
    container = entities.get(entityId);
  }

  const { subscribed, entity } = container;
  const router = useIonRouter();

  let text = "";
  let iconStyle: any = {};
  if (!iconOnly) {
    text = subscribed ? "Subscribed" : "Subscribe";
    iconStyle.marginLeft = "0.5em";
  }

  return (
    <IonButton
      slot={slot}
      onClick={async (event) => {
        event.stopPropagation();

        if (!userService.isLoggedIn()) {
          userService.promptRegister(router);
          return;
        }

        if (subscribed) {
          userService.unsubscribeFromEntity(entity.entityId);
          onChange(false);
        } else {
          userService.subscribeToEntity(entity.entityId);
          onChange(true);
        }
      }}
    >
      {text}

      {subscribed ? (
        <IonIcon icon={notifications} style={iconStyle} />
      ) : (
        <IonIcon icon={notificationsOutline} style={iconStyle} />
      )}
    </IonButton>
  );
}
