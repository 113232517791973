import { IonContent, IonPage } from "@ionic/react";
import { userService } from "../lib/userService";
import { systemStore } from "../store/systemStore";

interface Props {
  requiredRoles: string[] | string[][];
  children: any;
}

export function RequireRoles({ requiredRoles, children }: Props) {
  let unknownRoles: string[] = [];

  if (typeof requiredRoles[0] === "string") {
    requiredRoles = [requiredRoles as string[]];
  }

  // Make sure the roles that are required are known to the system
  (requiredRoles as string[][]).forEach((roleSet) => {
    roleSet.forEach((role) => {
      if (!systemStore.roles.includes(role)) {
        unknownRoles.push(role);
      }
    });
  });

  if (unknownRoles.length > 0) {
    return (
      <IonPage>
        <IonContent>Unknown roles "{unknownRoles.join(", ")}"</IonContent>
      </IonPage>
    );
  }

  if (!userService.hasRoles(requiredRoles)) {
    return (
      <IonPage>
        <IonContent>Missing the required role</IonContent>
      </IonPage>
    );
  }

  return children;
}
