import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { chevronForward } from "ionicons/icons";

export function IconText({ children, icon }: any) {
  return (
    <IonCard button>
      <IonCardContent style={{ display: "flex", alignItems: "center" }}>
        <IonIcon
          icon={icon}
          style={{ fontSize: "1.75em", marginRight: "0.5em" }}
        />
        <div style={{ flexGrow: 1 }}>{children}</div>
        <IonIcon icon={chevronForward} style={{ fontSize: "1em" }} />{" "}
      </IonCardContent>
    </IonCard>
  );
}
