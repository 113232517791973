import { IonContent } from "@ionic/react";
// import { useInView } from "react-intersection-observer";

export function Content({ children, hideShade = false, ...props }: any) {
  // const { ref, inView, entry } = useInView();

  return (
    <IonContent fullscreen {...props}>
      {/* Element for tracking scroll */}
      {/* <div ref={ref} /> */}
      {hideShade ? null : <div className="content-shade" />}
      {children}
    </IonContent>
  );
}
