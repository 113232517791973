import { IonBadge, IonButton } from "@ionic/react";
import { useEffect, useState } from "react";
import { Card } from "../../../components/Card";
import { adminService } from "../../../lib/adminService";

export const IssuesReport: React.FC = () => {
  const [issueData, setIssueData] = useState<any>(null);

  async function getIssues() {
    const result = await adminService.runReport("IssueCheck");
    setIssueData(result);
  }

  useEffect(() => {
    getIssues();
  }, []);

  return (
    <>
      <h1>Duplicate Entity Names</h1>
      {issueData &&
        issueData.duplicateEntityNames.map((record: any) => {
          const [name, { topEntity, allEntities }] = record;

          return (
            <Card key={name}>
              <h1>{name}</h1>
              <IonBadge>{topEntity.entityType}</IonBadge>
              <table border={1}>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>Added</td>
                    <td>Associations</td>
                  </tr>
                  {allEntities.map((dupRecord: any) => {
                    const { entityId, addDatetime, otherEntities } = dupRecord;

                    return (
                      <tr>
                        <td style={{ paddingRight: "1em" }}>
                          <IonButton
                            size="small"
                            onClick={() => {
                              window.open(`/entity/${entityId}`);
                            }}
                          >
                            {entityId}
                          </IonButton>
                        </td>
                        <td style={{ paddingRight: "1em" }}>{addDatetime}</td>
                        <td>
                          {otherEntities.map((ent: any) => {
                            return (
                              <div>
                                {ent.entityType}: {ent.entityName}
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          );
        })}
    </>
  );
};
