import { Share } from "@capacitor/share";

function convertToShareableUrl(currentUrl: string) {
  const url = new URL(currentUrl);
  const newUrl = `https://biblemedia.app${url.pathname}`;
  return newUrl;
}

export const shareService = {
  async shareEntity(entityContainer: any) {
    const { entity } = entityContainer;

    const shareData = {
      title: entity.entityName,
      text: entity.entityName,
      url: convertToShareableUrl(window.location.href),
      dialogTitle: `Share this ${entity.entityType}`,
    };

    await Share.share(shareData);
  },

  async shareVerse(verseData: any) {
    const shareData = {
      title: verseData.verseName,
      text: `${verseData.verseName} - ${verseData.text[0]}`,
      url: convertToShareableUrl(window.location.href),
      dialogTitle: `Share the verse ${verseData.verseName}`,
    };

    await Share.share(shareData);
  },
};
