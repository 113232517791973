import { IonChip } from "@ionic/react";

interface Props {
  deviceInfo: any;
}

export function DeviceInfo({ deviceInfo }: Props) {
  return (
    <>
      {Object.keys(deviceInfo || {}).map((key) => {
        const value = deviceInfo[key] || "";

        if (!value) {
          return null;
        }

        return (
          <IonChip
            key={key}
            style={{
              fontSize: "0.75em",
              padding: "0em 0.5em",
              height: "1.75em",
            }}
          >
            {value}
          </IonChip>
        );
      })}
    </>
  );
}
