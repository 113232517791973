import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRef, useState } from "react";
import { verseRangeRegex } from "../../data/books";
import { adminService } from "../../lib/adminService";
import { modalService } from "../../lib/modalService";
import { format } from "date-fns";
import { formatDate } from "../../lib/formatDate";
import { LabeledInput } from "../LabeledInput";

export function SelectVerseModal({ id, date, onSelect, onClear }: any) {
  const [isOpen, setIsOpen] = useState(true);
  const [verse, setVerse] = useState("");
  const timer = useRef<any>(null);
  const [lastTimeUsed, setLastTimeUsed] = useState<any>(null);

  // Allow the animation to occur
  function onClose() {
    setTimeout(() => {
      modalService.closeModal(id);
    }, 1000);

    setIsOpen(false);
  }

  const verseLocatorValid = verse.match(verseRangeRegex);

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => {
        onClose();
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle className="toolbar-center">Enter Verse</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>Back</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <LabeledInput
          value={verse}
          label={`For date: ${format(date, "MM/dd/yyyy")}`}
          helperText="Select a verse"
          onIonChange={(event) => {
            let name = event.target.value as string;
            setVerse(name);
            setLastTimeUsed(null);

            if (timer.current) {
              clearTimeout(timer.current);
            }

            timer.current = setTimeout(async () => {
              const result = await adminService.getVerseOfTheDayLastUsed(
                date,
                name
              );

              if (result) {
                setLastTimeUsed(result.scheduledDate);
              }
            }, 500);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onSelect(verse);
              onClose();
            }
          }}
        />

        {!verseLocatorValid && verse && <div>Not a valid verse locator</div>}
        {false && lastTimeUsed && (
          <div>Last featured {formatDate(lastTimeUsed)}</div>
        )}
        <IonButton
          onClick={() => {
            onSelect(verse);
            onClose();
          }}
        >
          Select
        </IonButton>
        <IonButton
          onClick={() => {
            onClear(verse);
          }}
        >
          clear
        </IonButton>
      </IonContent>
    </IonModal>
  );
}
