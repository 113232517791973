import { v4 as uuid } from "uuid";
import { modalStore } from "../store/modalStore";
import { rerender } from "./rerender";

class ModalService {
  // async openModalByName(componentName: string, props = {}) {
  //   return this.openModal({ componentName }, props);
  // }

  openModalByComponent(Component: any, props = {}) {
    return this.openModal({ Component }, props);
  }

  private openModal({ componentName, Component }: any, props = {}) {
    const id = uuid();

    modalStore.modals.push({
      Component,
      props: { id, ...props },
    });

    rerender();

    return id;
  }

  async closeModal(id: string) {
    modalStore.modals = modalStore.modals.filter((modalDetails) => {
      return modalDetails.props.id !== id;
    });

    rerender();
  }

  getTopModal() {
    const { modals } = modalStore;

    return modals[modals.length - 1];
  }
}

export const modalService = new ModalService();

window.addEventListener("keydown", (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    const modal = modalService.getTopModal();

    if (!modal) {
      return;
    }

    modalService.closeModal(modal.props.id);
  }
});
