import { IonApp, IonContent, IonSpinner } from "@ionic/react";
import { systemStore } from "../store/systemStore";
import { getApiUrl } from "../lib/api";

export function ConnectionTrouble({ _renderValue }: any) {
  const { delayedLoading } = systemStore;

  return (
    <IonApp>
      <IonContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1>Bible Media</h1>

            <IonSpinner name="dots" style={{ marginTop: "2em" }} />

            <div
              style={{
                textAlign: "center",
                padding: "2em 3em",
              }}
            >
              {delayedLoading ? (
                <span>
                  We're having trouble connecting.
                  <br />
                  Please check your internet connection and restart the app.
                  <br />
                  <br />
                  Or contact support at support@biblemedia.app
                  <br />
                  <br />
                  {getApiUrl()}
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          </div>
        </div>
      </IonContent>
      {true || _renderValue /*So that it is used*/}
    </IonApp>
  );
}
