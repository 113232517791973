import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonInput,
  IonLabel,
} from "@ionic/react";

interface Props {
  entityContainer: any;
  setEntityContainer: any;
  setChanged: any;
}

export function EntityLinkField({
  entityContainer,
  setEntityContainer,
  setChanged,
}: Props) {
  return (
    <IonCard>
      <IonCardContent>
        <IonLabel>Link</IonLabel>

        <div style={{ display: "flex" }}>
          <IonCol style={{ flexGrow: 1 }}>
            <IonInput
              value={entityContainer.entity.entityData.link}
              onIonChange={(event) => {
                const newContainer = JSON.parse(
                  JSON.stringify(entityContainer)
                );

                newContainer.entity.entityData.link = event.target.value;

                setEntityContainer(newContainer);
                setChanged(true);
              }}
            />
          </IonCol>

          {entityContainer.entity.entityData.link && (
            <IonButton
              onClick={() => {
                window.open(entityContainer.entity.entityData.link, "_blank");
              }}
            >
              Visit
            </IonButton>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
}
